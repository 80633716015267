<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <!-- <img src="../assets/img/personal.jpg" class="img" /> -->
        <img :src="bannerImg" class="img" />
      </div>
    </div>
    <div class="page-personal">
      <div class="wrap flex f-jb">
        <div class="personal-left" style="padding-top: 0.2rem">
          <div class="personal-nav">
            <a class="item active flex f-ac f-jc">
              <span class="s1"></span>{{ $t("person.event") }}
            </a>
            <a class="item flex f-ac f-jc" @click="account()">
              <span class="s2"></span>{{ $t("person.account") }}
            </a>
          </div>
        </div>
        <div class="personal-right personalRight">
          <div class="right-title">
            <span style="font-size: 0.18rem">{{ $t("person.event") }}</span>
          </div>
          <div class="right-nav flex">
            <div class="item flex f-ac f-jc">
              <!-- 线上赛
              <img src="../assets/img/idon-down2.png" class="down" /> -->
              <!-- 线上赛/线下下拉框 -->
              <el-select v-model="value" placeholder="请选择" @change="xss">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item flex f-ac f-jc">
              <!-- <div class="bag">5</div> -->
              <!-- 待审核 -->
              <!-- 线上 -->
              <el-select v-if="value == 1" v-model="value1" placeholder="请选择" @change="shzt">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 线下 -->
              <el-select v-if="value == 2" v-model="value2" placeholder="请选择" @change="shzt2">
                <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 全选 -->
              <el-select v-if="value == ''" v-model="value3" placeholder="请选择" @change="shzt3">
                <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <table border="0" cellspacing="0" cellpadding="0" class="personal-list-1">
            <tr>
              <th class="wide-col">{{ $t("person.name") }}</th>
              <th class="regular-col">{{ $t("person.team") }}</th>
              <th class="regular-col">{{ $t("person.type") }}</th>
              <th class="regular-col">{{ $t("person.activity") }}</th>
              <th class="regular-col">{{ $t("person.status") }}</th>
              <th class="wide-col extend-padding">{{ $t("person.operation") }}</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
              <td align="center" class="hide01 hide02 hide-hover" @click="detailsBtn(item.competitionId)">
                {{ item.eventName }}
              </td>
              <td align="center" class="hide01">{{ item.teamName }}</td>
              <td align="center" v-if="item.competitionType == 1">
                {{ $t("person.online") }}
              </td>

              <td align="center" v-else-if="item.competitionType == 2">
                {{ $t("person.offline") }}
              </td>
              <td align="center">
                {{ item.gameStartime }}<br>
                -<br>
                {{ item.gameEndtime }}
              </td>
              <td align="center" v-if="item.auditStatus == 0">
                {{ $t("person.approved") }}
              </td>
              <td align="center" v-else-if="(item.auditStatus == 1) & (value == 1)">
                {{ $t("person.work") }}
              </td>
              <td align="center" v-else-if="(item.auditStatus == 5) & (value == 2)">
                {{ $t("person.pproved") }}
              </td>
              <td align="center" v-else-if="(item.auditStatus == 2) & (value == 1)">
                {{ $t("person.review") }}
              </td>
              <td align="center" v-else-if="(item.auditStatus == 1) & (value == '')">
                {{ $t("person.work") }}
              </td>
              <td align="center" v-else-if="(item.auditStatus == 5) & (value == '')">
                {{ $t("person.pproved") }}
              </td>
              <td align="center" v-else-if="(item.auditStatus == 2) & (value == '')">
                {{ $t("person.review") }}
              </td>
              <td align="center" v-else-if="item.auditStatus == 3" style="color: red; position: relative"
                @mouseover="mouseOver" @mouseleave="mouseLeave">
                {{ $t("person.failed") }}
                <span class="audit-cause" v-show="auditCause">{{
          item.auditCause
        }}</span>
              </td>
              <td align="center" v-else-if="item.auditStatus == 4">
                {{ $t("person.ended") }}
              </td>
              <!-- 全选时 -->

              <td align="center" v-else>{{ $t("person.no_status") }}</td>

              <td class="extend-padding">
                <a v-if="item.auditStatus == 0" class="orange btn-personal-detail personal-margin"
                  @click="detailsEdit(item.id, item.competitionId)">{{ $t("person.details") }}</a>
                <a v-if="item.auditStatus != 0" class="orange btn-personal-detail personal-margin"
                  @click="details(item.id, item.teamName, item.eventName)">{{ $t("person.details") }}</a>
                <br />
                <a v-if="item.auditStatus == 0" class="orange personal-margin" @click="cancelRegistration(item.id)">{{
          $t("person.cancel") }}</a>
                <a v-if="item.auditStatus == 1 && value == 1" class="orange personal-margin" @click="
          worksBtn(
            item.id,
            item.eventName,
            item.submitStartime,
            item.submitEndtime
          )
          ">{{ $t("person.work") }}</a>
                <!-- <a v-if="item.auditStatus == 2 && value == 1" class="orange personal-margin"
                  @click="vote(item.id, item.shareStartime, item.shareEndtime)">{{ $t("person.share_voting") }}</a> -->
                <a v-if="item.auditStatus == 1 && value == ''" class="orange personal-margin" @click="
          worksBtn(
            item.id,
            item.eventName,
            item.submitStartime,
            item.submitEndtime
          )
          ">{{ $t("person.work") }}</a>
                <!-- <a v-if="item.auditStatus == 2 && value == ''" class="orange personal-margin"
                  @click="vote(item.id, item.shareStartime, item.shareEndtime)">{{ $t("person.share_voting") }}</a> -->
                <a v-if="item.auditStatus == 3" class="orange personal-margin"
                  @click="detailsEdit(item.id, item.competitionId)">{{
          $t("person.enroll") }}</a>
                <a v-if="item.auditStatus == 4" class="orange personal-margin" @click="reviewResults(item, item.id)">{{
          $t("person.results") }}</a>
              </td>
            </tr>
          </table>

          <!-- 分页 -->
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <tipsSuccess :successText="successText01" v-show="successShow01"></tipsSuccess>
    <tipsError :errorText="errorText01" v-show="errorShow01"></tipsError>
    <footers />
    <div class="code" v-show="detail">
      <img :src="imgPath" alt="" />
      <span class="close" @click="close()"></span>
      <div>{{ $t("person.scan") }}</div>
    </div>
    <!-- dialog 查看参赛详情--不可编辑状态-->
    <el-dialog class="dialogsignInfo" title="" :visible.sync="dialogsignInfo" width="80%">
      <div>
        <div class="page-sign" style="padding-top: 0; padding-bottom: 0">
          <div class="main">
            <div class="wrap" v-if="signInfoData">
              <div class="signInput">
                <div class="title" style="margin-bottom: 0.5rem">
                  {{ $t("signoffline.welcome") }}
                </div>
                <div class="sginForm sginFormInfo">
                  <div class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("signoffline.event_name") }}</div>
                    <div class="flex-item" v-html="signInfoData.event_name">
                      {{ $t("person.even") }}
                    </div>
                  </div>
                  <div class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("signoffline.type") }}</div>
                    <div v-if="signInfoData.participate_type == 0" class="flex-item">
                      {{ $t("person.home") }}
                    </div>
                    <div v-if="signInfoData.participate_type == 1" class="flex-item">
                      {{ $t("person.institutional") }}
                    </div>
                    <div v-if="signInfoData.participate_type == 2" class="flex-item">
                      {{ $t("person.kndergarten") }}
                    </div>
                    <div v-if="signInfoData.participate_type == 3" class="flex-item">
                      {{ $t("person.primary") }}
                    </div>
                  </div>
                  <div v-if="signInfoData.participate_type != 0" class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.code") }}</div>
                    <div class="flex-item" v-html="signInfoData.entry_code">
                      555555
                    </div>
                  </div>
                  <div class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.team") }}</div>
                    <div class="flex-item" v-html="signInfoData.team_name">
                      玛塔队1
                    </div>
                  </div>
                  <div class="flex input-item">
                    <div class="tit cursorDefault">{{ $t("signoffline.teammembers") }}</div>
                    <div class="flex-item team">
                      <div class="mb30" v-for="(item, index) in teamMembers" :key="index">
                        <div>
                          <span style="margin-right: 0.9rem">{{ $t("person.name_f") }}</span>
                          <span v-html="item.name"></span>
                        </div>
                        <div>
                          <span style="margin-right: 0.9rem">{{ $t("person.gender") }}</span>
                          <span v-html="item.sex"></span>
                        </div>
                        <div>
                          <span style="margin-right: 0.3rem">{{ $t("person.id_number") }}
                          </span>
                          <span v-html="item.number"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="signInfoData.participate_type != 0" class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.organization") }}</div>
                    <div class="flex-item" v-html="signInfoData.organization_name">
                      玛塔赛事
                    </div>
                  </div>
                  <!-- 家庭用户 -->
                  <div v-if="signInfoData.participate_type == 0" class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.parent_name") }}</div>
                    <div class="flex-item" v-html="signInfoData.mentor_name">
                      张女士
                    </div>
                  </div>
                  <div v-if="signInfoData.participate_type == 0" class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.phone") }}</div>
                    <div class="flex-item" v-html="signInfoData.mentor_phone">
                      12345678912
                    </div>
                  </div>
                  <!-- 家庭外其他用户类型 -->
                  <div v-if="signInfoData.participate_type != 0" class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.tel") }}</div>
                    <div class="flex-item" v-html="signInfoData.mentor_name">
                      张女士
                    </div>
                  </div>
                  <div v-if="signInfoData.participate_type != 0" class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.instructor_tel") }}</div>
                    <div class="flex-item" v-html="signInfoData.mentor_phone">
                      12345678912
                    </div>
                  </div>
                  <div class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.address") }}</div>
                    <div class="flex-item" v-html="signInfoData.mailing_address">
                      深圳市南山科技园105
                    </div>
                  </div>
                  <div class="flex input-item f-ac">
                    <div class="tit cursorDefault">{{ $t("person.detailed_add") }}</div>
                    <div class="flex-item" v-html="signInfoData.mailing_address_copy">
                      深圳市南山科技园105
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="
            text-align: center;
            height: 70px;
            padding-top: 21px;
            border-top: 1px solid #dcdfe6;
          ">
          <el-button class="qdBtn" @click="resetForm()" type="primary" style="font-size: 0.18rem">{{
          $t("person.OK") }}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- dialo 可编辑 -->
    <el-dialog title="" :visible.sync="dialogsignInfoEdit" v-if="dialogsignInfoEdit" width="80%">
      <SignInfo @hidePop="hidePop" @data-update="dataSubmit" :dialogsignInfoID="dialogsignInfoID"></SignInfo>
    </el-dialog>
  </div>
</template>
<script>
import "../assets/js/action";
import "../assets/css/index.css";
import "../assets/css/common.css";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import tipsSuccess from "../components/tips-success.vue";
import tipsError from "../components/tips-error.vue";
import SignInfo from "./sign-info.vue"; //参赛详情 可编辑
import {
  getApply,
  // findProduction,
  // like,
  // unlike,
  qrcode,
  getApplyMessage,
  competitionDelete,
  getBanner,
} from "../api/personal";
import { getUserInfo } from "../api/account";
export default {
  data() {
    return {
      successText01: "",
      successShow01: false,
      errorText01: "",
      errorShow01: false,
      currentPage: 1, //当前页
      pageSize: 10, //多少页
      total: 10, //多少条
      list: [],
      img: "",
      lists: "",
      detail: false,
      listDetails: [{ likenum: 0 }],
      name: "",
      event: "",
      //  options: [
      //   {
      //     value: "",
      //     label: this.$t("person.select_all")
      //   },
      //   {
      //     value: "1",
      //     label: this.$t("person.online")
      //   },
      //   {
      //     value: "2",
      //      label: this.$t("person.offline")
      //   },
      // ],
      // options1: [
      //   {
      //     value: "",
      //     label: this.$t("person.select_all")
      //   },
      //   {
      //     value: "0",
      //     label: this.$t("person.approved")
      //   },
      //   {
      //     value: "1",
      //     label: this.$t("person.work")
      //   },
      //   {
      //     value: "2",
      //     label: this.$t("person.review")
      //   },
      //   {
      //     value: "3",
      //     label: this.$t("person.failed")
      //   },
      //   {
      //     value: "4",
      //     // label: "评审结果",
      //     label: this.$t("person.ended")
      //   },
      // ],
      // options2: [
      //   {
      //     value: "0",
      //     label: this.$t("person.review")
      //   },
      //   {
      //     value: "5",
      //     label:this.$t("person.pproved")
      //   },
      //   {
      //     value: "3",
      //     label: this.$t("person.failed")
      //   },
      //   {
      //     value: "4",
      //     // label: "评审结果",
      //     label: this.$t("person.ended")
      //   },
      // ],
      // options3: [
      //   {
      //     value: "",
      //      label: this.$t("person.select_all")
      //   },
      //   {
      //     value: "0",
      //     label: this.$t("person.approved")
      //   },
      //   {
      //     value: "1",
      //     label: this.$t("person.work")
      //   },
      //   {
      //     value: "2",
      //     label: this.$t("person.review")
      //   },
      //   {
      //     value: "3",
      //     label: this.$t("person.failed")
      //   },
      //   {
      //     value: "4",
      //     // label: "评审结果",
      //     label: this.$t("person.ended")
      //   },
      //   {
      //     value: "5",
      //     label: this.$t("person.pproved")
      //   },
      // ],
      value: "",
      value1: "",
      value2: "",
      value3: "",
      signInfoData: null, //参赛详情数据
      teamMembers: [], //队伍成员
      dialogsignInfo: false,
      dialogsignInfoEdit: false,
      dialogsignInfoID: null,
      imgPath: "",
      auditCause: false, //审核失败原因
      gettime: null,
      newDate: null, //当前时间 年月日
      bannerImg: null,
      lang: "zh-CN",
    };
  },
  components: {
    navigation,
    footers,
    SignInfo,
    tipsSuccess,
    tipsError,
  },
  // 引入语言
  watch: {
    getLang(lang) {
      localStorage.getItem("lang", lang);
      this.lang = lang;
    },
  },
  // 将this.$t() 写到了data属性里，切换语言不起作用 将表达式写在computed属性中
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
    options() {
      return [
        {
          value: "",
          label: this.$t("person.select_all"),
        },
        {
          value: "1",
          label: this.$t("person.online"),
        },
        {
          value: "2",
          label: this.$t("person.offline"),
        },
      ];
    },
    options1() {
      return [
        {
          value: "",
          label: this.$t("person.select_all"),
        },
        {
          value: "0",
          label: this.$t("person.approved"),
        },
        {
          value: "1",
          label: this.$t("person.work"),
        },
        {
          value: "2",
          label: this.$t("person.review"),
        },
        {
          value: "3",
          label: this.$t("person.failed"),
        },
        {
          value: "4",
          // label: "评审结果",
          label: this.$t("person.ended"),
        },
      ];
    },
    options2() {
      return [
        {
          value: "0",
          label: this.$t("person.review"),
        },
        {
          value: "5",
          label: this.$t("person.pproved"),
        },
        {
          value: "3",
          label: this.$t("person.failed"),
        },
        {
          value: "4",
          // label: "评审结果",
          label: this.$t("person.ended"),
        },
      ];
    },
    options3() {
      return [
        {
          value: "",
          label: this.$t("person.select_all"),
        },
        {
          value: "0",
          label: this.$t("person.approved"),
        },
        {
          value: "1",
          label: this.$t("person.work"),
        },
        {
          value: "2",
          label: this.$t("person.review"),
        },
        {
          value: "3",
          label: this.$t("person.failed"),
        },
        {
          value: "4",
          // label: "评审结果",
          label: this.$t("person.ended"),
        },
        {
          value: "5",
          label: this.$t("person.pproved"),
        },
      ];
    },
  },
  created() {
    this.lang = this.$store.state.lang;
    this.getList(this.value, this.value1, this.value2, this.value3);
    this.getLists();
    this.newDateFn(); //时间戳
    this.getBannerImg();
  },
  methods: {
    // banner图
    getBannerImg() {
      let data = { type: 5 };
      getBanner(data).then((res) => {
        if (res.code == 200) {
          this.bannerImg = res.data;
        }
      });
    },
    // 获取时间戳
    newDateFn() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      if (mm >= 1 && mm <= 9) {
        mm = "0" + mm;
      }
      if (dd >= 1 && dd <= 9) {
        dd = "0" + dd;
      }
      this.newDate = yy + "-" + mm + "-" + dd;
      this.newDate = +this.newDate.split("-").join(""); //数字类型
    },
    account() {
      //不是登陆状态就跳回登录页面
      if (
        localStorage.getItem("token") == null ||
        !localStorage.getItem("token")
      ) {
        this.$router.push("/login");
        return;
      }
      this.$router.push("/account");
    },
    // 账户信息
    getLists() {
      getUserInfo()
        .then((res) => {
          this.lists = res.data;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 参赛列表
    getList(value, value1, value2, value3) {
      //不是登陆状态就跳回登录页面
      if (
        localStorage.getItem("token") == null ||
        !localStorage.getItem("token")
      ) {
        this.$router.push("/login");
        return;
      }
      let data = {
        auditStatus: value1, //审核类型
        competitionType: value, //线上/线下
        page: this.currentPage,
        size: this.pageSize,
      };
      if (value == 1) {
        data.auditStatus = value1;
      } else if (value == 2) {
        data.auditStatus = value2;
      } else if (value == "") {
        data.auditStatus = value3;
      }
      getApply(data).then((res) => {
        if (res.code == 200) {
          this.total = res.total;
          this.list = res.data;
          this.imgPath = "/api/code/qrcode?id=" + res.data[0].id;
        } else {
        }
        // this.imgPath='http://192.168.0.105:8087/code/qrcode?id='+res.data[0].id
      });
    },
    // 选择 线上/线下
    xss() {
      this.currentPage = 1;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },
    shzt() {
      this.currentPage = 1;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },
    shzt2() {
      this.currentPage = 1;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },
    shzt3() {
      this.currentPage = 1;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },
    // 点击赛事名称跳回详情页
    detailsBtn(id) {
      localStorage.setItem("getCompetitionId", id);
      this.$router.push("/matchSignDetail");
    },
    // 选择审核状态
    // 分享投票
    vote(id, share_startime, share_endtime) {
      // 先判断当前时间是否符合提交作品的时间
      let shareStartime = +share_startime.split("-").join(""); //开始时间
      let shareEndtime = +share_endtime.split("-").join(""); //结束时间
      if (this.newDate < shareStartime) {
        this.errorText01 = this.$t("person.wait");
        this.errorShow01 = true;
        setTimeout(() => {
          this.errorShow01 = false;
        }, 2000);
        return;
      } else if (this.newDate > shareEndtime) {
        this.errorText01 = this.$t("person.time_end");
        this.errorShow01 = true;
        setTimeout(() => {
          this.errorShow01 = false;
        }, 2000);
        return;
      }

      this.imgPath = "/api/v1/code/qrcode?id=" + id;
      // 为了携带token给后端，再次发送常规请求
      // var id = { id: id };
      qrcode({ id: id }).then((res) => { });
      this.detail = true;
    },
    close() {
      this.detail = false;
    },
    // 查看详情（不可编辑）
    details(id) {
      this.dialogsignInfo = true;
      let data = { id: id };
      getApplyMessage(data).then((res) => {
        if (res.code == 200) {
          this.signInfoData = res.data;
          this.teamMembers = this.signInfoData.team_members; //队伍成员
        }
      });
    },
    // 可编辑
    detailsEdit(id, competition_id) {
      // this.dialogsignInfoID = id;
      this.dialogsignInfoID = {
        id: id,
        competition_id: competition_id,
      };
      this.dialogsignInfoEdit = true;
    },
    hidePop(val) {
      this.dialogsignInfoEdit = val;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },

    close() {
      this.detail = false;
    },
    like(id) {
      let data = { worksId: id };
      like(data).then((res) => { });
    },
    // 提交作品
    worksBtn(e, eventName, submitStartime, submitEndtime) {
      // 先判断当前时间是否符合提交作品的时间
      let submit_startime = +submitStartime.split("-").join(""); //开始时间
      let submit_endtime = +submitEndtime.split("-").join(""); //结束时间
      if (this.newDate < submit_startime) {
        this.errorText01 = this.$t("person.wait_patiently");
        this.errorShow01 = true;
        setTimeout(() => {
          this.errorShow01 = false;
        }, 2000);
        return;
      } else if (this.newDate > submit_endtime) {
        this.errorText01 = this.$t("person.submit_end");
        this.errorShow01 = true;
        setTimeout(() => {
          this.errorShow01 = false;
        }, 2000);
        return;
      }
      let submitWorkInfo = {
        id: e,
        eventName: eventName,
      };
      localStorage.setItem("submitWorkInfo", JSON.stringify(submitWorkInfo));
      this.$router.push({
        name: "submitWork",
        params: {
          id: e,
          eventName: eventName,
        },
      });
    },
    resetForm() {
      this.dialogsignInfo = false;
    },
    // 取消报名
    cancelRegistration(id) {
      this.$confirm(this.$t("person.want_cancel"), this.$t("person.prompt"), {
        confirmButtonText: this.$t("person.OK"),
        cancelButtonText: this.$t("person.cancel1"),
        type: "warning",
      }).then(() => {
        let data = { id: id };
        competitionDelete(data).then((res) => {
          if (res.code == 200) {
            (this.successText01 = this.$t("person.cancel_succeed")),
              (this.successShow01 = true);
            setTimeout(() => {
              this.successShow01 = false;
            }, 2000);
            this.getList(this.value, this.value1, this.value2, this.value3);
          }
        });
      });
      // }
    },
    // 评审结果
    reviewResults(item, id) {
      console.log(item);
      this.$router.push({
        name: "reviewResults",
        query: {
          id: parseInt(item.id)
        },
        params: {
          eventName: item.eventName, //赛事名称
          teamName: item.teamName, //队伍名称
        },
      });
    },
    // 成功
    dataSubmit() {
      this.dialogsignInfoEdit = false;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },
    // 鼠标移入
    mouseOver() {
      this.auditCause = true;
    },
    mouseLeave() {
      this.auditCause = false;
    },
    // 分页事件
    // 每页多少条
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },
    // 当前页
    handleCurrentChange(val) {
      // 页面置顶
      document.documentElement.scrollTop = 0; //跳回顶部
      this.currentPage = val;
      this.getList(this.value, this.value1, this.value2, this.value3);
    },
  },
};
</script>

<style scoped>
.personal-right .right-nav .item {
  line-height: normal !important;
}

/*
/deep/ .el-select,
/deep/ .el-input {
  height: 100%;
}

/deep/ .el-input__inner {
  border: none !important;
  font-size: 0.18rem !important;
  height: 100%;
}
*/
.code {
  width: 4rem;
  height: 4rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.code img {
  z-index: 99;
}

.code div {
  margin: 0.2rem 0;
}

.close::before {
  cursor: pointer;
  position: absolute;
  top: 7%;
  right: 7%;
  content: "\2716";
}

/* 单行文本 溢出隐藏显示三点... */
.hide01 {
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 0.5rem;
}

.hide02 {
  text-overflow: clip;
  /* 如果需要换行，就不需要省略号 */
  word-wrap: break-word;
  /* 这将在边界内换行，并在需要时断开单词 */
  overflow-wrap: break-word;
  /* 同 word-wrap，提供相同的功能 */
  padding-right: 0.5rem;
}

.el-select-dropdown__item.selected {
  color: #fd6c0b !important;
}

/* 审核失败 样式 */
.audit-cause {
  font-size: 0.14rem;
  display: inline-block;
  width: 2.4rem;
  height: 1rem;
  padding: 0 0.1rem;
  background-color: #666;
  opacity: 0.6;
  color: #fff;
  position: absolute;
  top: -90%;
  left: 50%;
}

/* 分页定位 */
.personalRight {
  position: relative;
}

.personalRight .el-pagination {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
}

/* dialog样式 */
.dialogsignInfo .el-dialog__body {
  margin: 0.5rem 2.5rem;
  padding-bottom: 0.5rem !important;
  height: 100% !important;
}

.dialogsignInfo .wrap,
.dialogsignInfo .wrap .sginFormInfo {
  width: 100% !important;
}

.dialogsignInfo .qdBtn {
  width: 1.5rem;
}

.dialogsignInfo .tit {
  margin-right: -0.5rem !important;
}

/* 点击字体样式 */
.personalRight .hide-hover {
  cursor: pointer;
  color: #fd765b;
}

.personalRight .hide-hover:hover {
  color: #fd6c0b;
}

.wide-col {
  width: 20%;
}

.extend-padding {
  padding-left: 1rem;
}

.regular-col {
  width: 15%;
}

.personal-margin {
  display: inline-block;
  margin-bottom: 5px;
}

.personal-margin:last-child {
  margin-bottom: 0;
}

td,
th {
  padding: 0 0 0 0;
}
</style>
