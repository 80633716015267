// zh
export default {
 video: {  
    video: "优秀视频作品",
    happy:'Happy”牛"Year-赛事回顾',
    Highlights:'赛事精彩瞬间',
    
  },
};



