import request from '../utils/request';
// 用户信息
export const getUserInfo = query => {
    return request({
        url: 'v1/user/getUserInfo',//接口地址
        method: 'get',
    });
};
//修改用户信息
export const message = data => {
    return request({
        url: 'v1/user',//接口地址
        method: 'put',
        data:data
    });
};