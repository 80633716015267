<template>
  <div>
    <div class="item flex item-input">
      <input type="text" :placeholder="$t('loginPage.email')" class="flex-item" @input="emailInput"
        v-model.trim="emailValue" @blur="animate()" />
      <span style="
          color: red;
          line-height: 20px;
          margin: 3px;
          white-space: nowrap;
          font-size: 12px;
        " v-show="red">{{ $t("password.empty") }}</span>
    </div>
    <div class="item flex item-input" style="margin-bottom: 0.4rem">
      <input type="text" :placeholder="$t('loginPage.verification')" class="flex-item" v-model.trim="credentials"
        @input="credentiInput" />
      <div class="btn-again" v-show="show" @click="getCode">
        {{ $t("loginPage.code") }}
      </div>
      <div class="btn-again disabled" v-show="!show">
        {{ count }} {{ $t("loginPage.m") }}
      </div>
    </div>
    <div :class="{ 'item-input2': redPWD }" class="item flex item-input" style="margin-bottom: 0.4rem">
      <input :type="seen ? 'password' : 'text'" :placeholder="$t('loginPage.pwd')" class="flex-item" @input="pwdInput"
        autocomplete="new-password" v-model.trim="passwordsValue" @blur="passwordChexk()" />
      <!--      <span style="-->
      <!--          color: red;-->
      <!--          line-height: 20px;-->
      <!--          margin: 3px;-->
      <!--          white-space: nowrap;-->
      <!--          font-size: 12px;-->
      <!--        " v-show="redPWD">密码为6-16位英文加数字</span>-->
      <img :src="seen ? openeye : nopeneye" class="eye" alt="" @click="changeType()" />
    </div>
  </div>
</template>
<script>
import { emailCode } from "./../api/login";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailValue: "",
      passwordsValue: "",
      openeye: require("../assets/img/eye-open.png"), //图片地址
      nopeneye: require("../assets/img/eye-close.png"), //图片地址
      seen: true,
      count: "",
      redPWD: false,
      red: false,
      credentials: "",
    };
  },
  mounted() {
  },
  methods: {
    changeType() {
      this.seen = !this.seen; // 切换 seen 的布尔值
    },
    // 邮箱验证
    animate() {
      // 如果手机号为空，则不出现红字提示
      if (this.emailValue == "") {
        this.red = true;
      } else {
        this.red = false;
      }
    },
    passwordChexk() {
      const rePwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      const pwd = this.passwordsValue;
      if (rePwd.test(pwd)) {
        this.redPWD = false;
      } else {
        this.redPWD = true;
      }
    },
    emailInput() {
      this.$emit("updateEmail", this.emailValue);
    },
    pwdInput() {
      this.$emit("updatePasswords", this.passwordsValue);
    },
    credentiInput() {
      this.$emit("updatecredentials", this.credentials);
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      let data = {
        email: this.emailValue,
        type: "1",
      };
      emailCode(data).then((res) => {
      });
    },
  },
};
</script>

<style scoped>
.register-cont .item-input input.input-error {
  border: 1px solid red;
  /* 设定红色边框，确保边框的大小和样式符合您的设计 */
}
</style>
