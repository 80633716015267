import request from '../utils/request';
// 报名列表
export const all = query => {
    return request({
        url: 'v1/competition/all',//接口地址
        method: 'get',
        params: query
    });
};
// 报名详情页
export const getCompetition = query => {
    return request({
        url: 'v1/competition/getCompetition',//接口地址
        method: 'get',
        params: query
    });
};
// 报名详情页
export const apply = data => {
    return request({
        url: 'v1/competition/apply',//接口地址
        method: 'post',
        data: data
    });
};
// 获取赛事评委
export const getJudge = query => {
    return request({
        url: 'v1/competition/getJudge',//接口地址
        method: 'get',
        params: query
    });
};
// 竞赛奖项
export const getAward = query => {
    return request({
        url: 'v1/competition/getAward',//接口地址
        method: 'get',
        params: query
    });
};
// banner图/v1/material/getBanner
export const getBanner = query => {
    return request({
        url: 'v1/material/getBanner',//接口地址
        method: 'get',
        params: query
    });
};