<template>
  <div>
    <div class="item flex item-input" style="margin-bottom: 0.4rem">
      <div class="area">+86</div>
      <input type="text" :placeholder="$t('loginPage.registerphone')" class="flex-item" @input="phoneInput"
             v-model.trim="mobile" @blur="animate()"/>
      <span style="
          color: red;
          line-height: 20px;
          margin: 3px;
          white-space: nowrap;
          font-size: 12px;
        " v-show="red">请输入正确的手机号!</span>
    </div>
    <div class="item flex item-input" style="margin-bottom: 0.4rem">
      <input type="text" :placeholder="$t('loginPage.verification')" class="flex-item" v-model.trim="credentials" @input="credentiInput"/>
      <div class="btn-again" v-show="show" @click="getCode">
        {{ $t("loginPage.code") }}
      </div>
      <div class="btn-again disabled" v-show="!show">
        {{ count }} {{ $t("loginPage.m") }}
      </div>
    </div>
    <div class="item flex item-input" style="margin-bottom: 0.4rem">
      <input :type="seen ? 'password' : 'text'" :placeholder="$t('loginPage.pwd')" class="flex-item" @input="pwdInput" v-model.trim="passwordsValue" @blur="passwordChexk()"/>
      <span style="
          color: red;
          line-height: 20px;
          margin: 3px;
          white-space: nowrap;
          font-size: 12px;
        " v-show="redPWD">密码为6-16位英文加数字</span>
      <img :src="seen ? openeye : nopeneye" class="eye" alt="" @click="changeType()"/>
    </div>
  </div>
</template>
<script>
import {code} from "./../api/login";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      passwordsValue: "",
      openeye: require("../assets/img/eye-open.png"), //图片地址
      nopeneye: require("../assets/img/eye-close.png"), //图片地址
      seen: false,
      credentials: "",
      count: "",
      mobile: "",
      red: false,
      redPWD: false,
    };
  },
  mounted() {
  },
  methods: {
    //手机号验证
    animate() {
      var re = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let str = this.mobile;
      if (re.test(str)) {
        this.red = false;
      } else {
        this.red = true;
      }
      // 如果手机号为空，则不出现红字提示
      if (this.mobile == "") {
        this.red = false;
      }
    },
    // 密码校验  做限制6-16位英文加数字
    passwordChexk() {
      var rePwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      var pwd = this.passwordsValue;
      if (rePwd.test(pwd)) {
        this.redPWD = false;
      } else {
        this.redPWD = true;
      }
    },
    // 获取验证码
    getCode() {
      // 如果手机号为空 return
      if (this.mobile == "") {
        console.log("wanwna");
        this.$message.error("请输入手机号");
        return;
      }
      // 如果手机号不合法 return
      if (this.red) {
        return;
      }

      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      let data = {
        mobile: this.mobile,
        type: "0",
      };
      code(data).then((res) => {
      });
    },
    phoneInput() {
      this.$emit("updatePhone", this.mobile);
    },
    pwdInput() {
      this.$emit("updatePasswords", this.passwordsValue);
    },
    credentiInput() {
      this.$emit("updatecredentials", this.credentials);
    },
  },
};
</script>

<style lang="scss" scoped></style>
