export default {
  faq: {
    question: "frequently asked questions",
    registration: "About Competition",
    competite: "About Registration",
    score: "About Ranking",
    tech: "About Technology",
    other: "Other Information",
  },
};
