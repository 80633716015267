<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <div class="detail-info flex f-ac f-jc">
          <div class="wrap">
            <div class="tit">{{ $t("video.happy") }}</div>
          </div>
        </div>
        <img src="../assets/img/match-photos.jpg" class="img" />
      </div>
    </div>

    <div class="page-photos-list mt100">
      <div class="wrap clearfix pop-photos">
        <a
          href="javascript:;"
          class="item"
          data-url="img/news5.jpg"
          data-type="photo"
        >
          <div class="img">
            <img src="../assets/img/news5.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t("video.happy") }}</div>
        </a>
        <a
          href="javascript:;"
          class="item"
          data-url="img/news5.jpg"
          data-type="photo"
        >
          <div class="img">
            <img src="../assets/img/news6.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t("video.happy") }}</div>
        </a>
        <a
          href="javascript:;"
          class="item"
          data-url="img/news5.jpg"
          data-type="video"
        >
          <div class="img">
            <img src="../assets/img/news7.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t("video.happy") }}</div>
        </a>
        <a href="" class="item">
          <div class="img">
            <img src="../assets/img/news5.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t(" video.happy") }}</div>
        </a>
        <a href="" class="item">
          <div class="img">
            <img src="../assets/img/news6.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t(" video.happy") }}</div>
        </a>
        <a href="" class="item">
          <div class="img">
            <img src="../assets/img/news7.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t(" video.happy") }}</div>
        </a>
        <a href="" class="item">
          <div class="img">
            <img src="../assets/img/news5.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t(" video.happy") }}</div>
        </a>
        <a href="" class="item">
          <div class="img">
            <img src="../assets/img/news6.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t(" video.happy") }}</div>
        </a>
        <a href="" class="item">
          <div class="img">
            <img src="../assets/img/news7.jpg" alt="" class="img begin" />
          </div>
          <div class="tit ellipsis">{{ $t(" video.happy") }}</div>
        </a>
      </div>
    </div>
    <div class="page-trun flex f-ac f-jc begin">
      <a href="" class="item active"></a>
      <a href="" class="item"></a>
      <a href="" class="item"></a>
      <a href="" class="item"></a>
      <a href="" class="item"></a>
      <a href="" class="item"></a>
    </div>
    <div class="pop">
      <div class="main flex f-ac f-jc">
        <div class="info">
          <div class="btn-close"></div>
          <div class="item"></div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
export default {
  components: {
    navigation,
    footers,
  },
  created() {},
  methods: {},
};
</script>

<style></style>
