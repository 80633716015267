<template>
  <div class="sign_info">
    <!-- <navigation /> -->
    <!-- <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <img src="../assets/img/sign.jpg" class="img" />
      </div>
    </div> -->
    <div class="page-sign" style="padding-top: 0; padding-bottom: 0">
      <div class="main">
        <div class="wrap">
          <div class="signInput">
            <div class="title">{{ $t("siginfo.welcome") }}</div>
            <div class="signStep flex">
              <div class="item flex-item active">
                <div class="icon icon1"></div>
                <div class="tit">{{ $t("siginfo.initregistration") }}</div>
              </div>
              <div class="item flex-item active">
                <div class="icon icon2"></div>
                <div class="tit">{{ $t("siginfo.reviewapplication") }}</div>
              </div>
              <div class="item flex-item">
                <div class="icon icon3"></div>
                <div class="tit">{{ $t("siginfo.submitworks") }}</div>
              </div>
              <!-- <div class="item flex-item" v-if="this.list.competition_type == 1">
                <div class="icon icon3"></div>
                <div class="tit">上传作品</div>
              </div> -->
              <!-- <div class="item flex-item" v-if="this.list.competition_type == 2">
                <div class="icon icon2"></div>
                <div class="tit">审核通过</div>
              </div> -->
              <div class="item flex-item">
                <div class="icon icon4"></div>
                <div class="tit">{{ $t("siginfo.announcewinners") }}</div>
              </div>
            </div>

            <div class="sginForm" v-if="signInfoData">
              <div class="flex input-item f-ac">
                <div class="tit">{{ $t("siginfo.event_name") }}</div>
                <div class="flex-item" v-html="signInfoData.event_name">
                  赛事
                </div>
              </div>
              <!-- <span>Picked: {{ picked }}</span><br> -->
              <div class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'curentlangEn' : 'curentlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.type") }}</div>
                <div class="flex-item">
                  <div class="flex label-list f-jb">
                    <div class="label-item">
                      <input class="magic-radio" type="radio" name="radio1" id="r1-1" value="0" v-model="picked"
                        @change="family" />
                      <label for="r1-1">{{ $t("siginfo.family") }}</label>
                    </div>
                    <div class="label-item">
                      <input class="magic-radio" type="radio" name="radio1" id="r1-2" value="1" v-model="picked"
                        @change="mechanism" />
                      <label for="r1-2">{{ $t("siginfo.institution") }}</label>
                    </div>
                    <div class="label-item">
                      <input class="magic-radio" type="radio" name="radio1" id="r1-3" value="2" v-model="picked"
                        @change="kindergarten" />
                      <label for="r1-3">{{ $t("siginfo.kindergarten") }}</label>
                    </div>
                    <div class="label-item">
                      <input class="magic-radio" type="radio" name="radio1" id="r1-4" value="3" v-model="picked"
                        @change="primarySchool" />
                      <label for="r1-4">{{ $t("siginfo.primary") }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex input-item f-ac" v-show="invitationCode">
                <span :class="lang == 'en-CN' ? 'codelangEn' : 'codelangZh'">*</span>
                <div class="tit">{{ $t("siginfo.code") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.ent_code')" @blur="invites()"
                    v-model="entry_code" style="color: #888" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="invite">{{ $t("siginfo.correct_code") }}</span>
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="entryCode">{{ $t("siginfo.entrycode") }}</span>
                </div>
              </div>
              <div class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'teamlangEn' : 'teamlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.teamname") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.ent_teamname')" v-model="team_name"
                    @blur="teamNameBlur()" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="teamName">{{ $t("siginfo.ent_teamname") }}</span>
                </div>
              </div>
              <div class="flex input-item">
                <span :class="lang == 'en-CN' ? 'teamlangEn' : 'teamlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.teammembers") }}</div>
                <div class="flex-item">
                  <div class="team">
                    <div class="team-list" v-for="(item, index) in formArr" :key="index">
                      <div class="flex f-ac">
                        <span style="
                            color: red;
                            position: absolute;
                            left: 0.4rem;
                            top: 0.15rem;
                          ">*</span>
                        <div class="tit">{{ $t("siginfo.name") }}</div>
                        <div class="flex-item">
                          <input type="text" name="" :placeholder="$t('siginfo.ent_name')" v-model="item.name" />
                        </div>
                      </div>
                      <div class="flex f-ac">
                        <span style="
                            color: red;
                            position: absolute;
                            left: 0.4rem;
                            top: 0.15rem;
                          ">*</span>
                        <div class="tit">{{ $t("siginfo.sex") }}</div>
                        <div class="flex-item">
                          <input type="text" name="" :placeholder="$t('siginfo.ent_sex')" v-model="item.sex" />
                        </div>
                      </div>
                      <div class="flex f-ac">
                        <span style="
                            color: red;
                            position: absolute;
                            left: 0.1rem;
                            top: 0.15rem;
                          ">*</span>
                        <div class="tit">{{ $t("siginfo.id_number") }}</div>
                        <div class="flex-item">
                          <input type="text" name="" :placeholder="$t('siginfo.ent_idnumber')" @blur="animates(index)"
                            v-model="item.number" />
                          <span style="
                              color: red;
                              line-height: 20px;
                              margin: 3px;
                              white-space: nowrap;
                              font-size: 12px;
                            " v-show="item.reds">{{ $t("siginfo.corret_idnumber") }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex f-ac f-jc mt30 mb30">
                      <div class="btn-up" @click="add"></div>
                      <div class="btn-down" v-show="delte" @click="del()"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 当用户选择家庭时展示 -->
              <div v-show="familyInfo" class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'parentlangEn' : 'parentlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.parent_name") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.please_parent_name')" v-model="mentor_name"
                    @blur="mentorNameBlur(0)" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="mentorName">{{ mentorNameText }}</span>
                </div>
              </div>
              <div v-show="familyInfo" class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'parentlangEn' : 'parentlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.parent_phone") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.please_parent_phone')" @blur="animate()"
                    v-model="mentor_phone" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="red">{{ $t("siginfo.illege") }}</span>
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="mentorPhone">{{ mentorPhoneText }}</span>
                </div>
              </div>
              <!-- 当用户选择机构时展示 -->
              <div v-show="mechanismInfo" class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'curentlangEn' : 'curentlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.org") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.ent_org')" v-model="organization_name"
                    @blur="organizationNameBlur(1)" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="organizationName">{{ organizationNameText }}</span>
                </div>
              </div>
              <!-- 当用户选择幼儿园时展示 -->
              <div v-show="kindergartenInfo" class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'kidlangEn' : 'kidlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.kindergarten_name") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.please_kid_name')" v-model="organization_name"
                    @blur="organizationNameBlur(2)" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="organizationName">{{ organizationNameText }}</span>
                </div>
              </div>
              <!-- 当用户选择小学时展示 -->
              <div v-show="primarySchoolInfo" class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'primarylangEn' : 'primarylangZh'">*</span>
                <div class="tit">{{ $t("siginfo.primary_school") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.please_primary_name')"
                    v-model="organization_name" @blur="organizationNameBlur(3)" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="organizationName">{{ organizationNameText }}</span>
                </div>
              </div>
              <!-- 机构/幼儿园/小学 共用 -->
              <div v-show="teacher" class="flex input-item f-ac">
                <span style="color: red; position: absolute; left: 0.58rem">*</span>
                <div class="tit">{{ $t("siginfo.instructor_parent") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.ent_instr_name')" v-model="mentor_name"
                    @blur="mentorNameBlur(1)" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="mentorName">{{ mentorNameText }}</span>
                </div>
              </div>
              <div v-show="teacher" class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'curentlangEn' : 'curentlangZh'">*</span>
                <div class="tit">{{ $t("siginfo.instructor_phone") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.ent_instru_phone')" @blur="animate()"
                    v-model="mentor_phone" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="red">{{ $t("siginfo.illege") }}</span>
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="mentorPhone">{{ mentorPhoneText }}</span>
                </div>
              </div>

              <!--地址-->
              <div class="flex input-item f-ac">
                <span :class="lang == 'en-CN' ? 'detaillangEn' : 'detaillangZh'">*</span>
                <div class="tit">{{ $t("siginfo.prize_addr") }}</div>
                <div class="flex-item">
                  <input type="text" name="" :placeholder="$t('siginfo.address')" v-model="mailing_address"
                    @blur="mailingAddressBlur()" />
                  <span style="
                      color: red;
                      line-height: 20px;
                      margin: 3px;
                      white-space: nowrap;
                      font-size: 12px;
                    " v-show="mailingAddress">{{ $t("siginfo.ent_email") }}</span>
                </div>
              </div>
              <div class="flex input-item" v-if="lang == 'zh-CN'">
                <span :class="lang == 'en-CN' ? 'detaillangEn' : 'detaillangZh'">*</span>
                <div class="tit"></div>
                <div class="flex-item">
                  <textarea name="" rows="" cols="" v-model="address" :placeholder="$t('siginfo.detailed_addr')"
                    @blur="addressXxBlur()"></textarea>
                  <span :class="lang == 'en-CN' ? 'detaillangEn' : 'detaillangZh'" v-show="addressXx">{{
              $t("siginfo.ent_detail_addr") }}</span>
                </div>
              </div>
              <div class="flex input-item">
                <div class="tit"></div>
                <div class="flex-item flex">
                  <div class="btn-submit" @click="submit()">
                    {{ $t("siginfo.submit") }}
                  </div>
                  <div class="btn-cancel" @click="cancel()">
                    {{ $t("siginfo.cancel") }}
                  </div>
                </div>
              </div>
              <div class="flex input-item">
                <div class="tit"></div>
                <div style="color: red; text-align: center" v-show="required">
                  {{ $t("siginfo.information") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tipsSuccess :successText="successText01" v-show="successShow01"></tipsSuccess>
    <tipsError :errorText="errorText01" v-show="errorShow01"></tipsError>
    <!-- <footers /> -->
    <div class="alerts" id="vh" v-show="alerts">
      <div class="alert">
        <img src="../assets/img/rg1.png" alt="" />
        <div>{{ $t("siginfo.notice") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import tipsSuccess from "../components/tips-success.vue"; //成功提示弹框
import tipsError from "../components/tips-error.vue"; //失败提示弹框
import { apply } from "../api/matchSign";
import { getApplyMessage, updateApplyMessage } from "../api/personal";
export default {
  props: ["dialogsignInfoID"],
  data() {
    return {
      successText01: "已达到成员上限,不可再添加成员",
      successShow01: false,
      errorText01: "",
      errorShow01: false,
      invite: false, //邀请码
      alerts: false, //弹框成功
      required: false, //提交信息不全出现的红色提示
      formArr: [
        {
          name: "",
          sex: "",
          number: "", //身份证号
          reds: false, //身份证号验证
        },
      ],
      red: false, //手机号验证
      delte: false, //删除
      picked: "", //单选
      // title: this.$route.params.title,
      title: localStorage.getItem("signTitle"),
      entry_code: "",
      event_name: "",
      address: "",
      mailing_address: "",
      mentor_name: "",
      mentor_phone: "",
      organization_name: "",
      participate_type: "",
      team_name: "",
      // userInfoType: null,
      num: 5, //接收从父组件传过来的值
      familyInfo: false,
      mechanismInfo: false,
      kindergartenInfo: false,
      primarySchoolInfo: false,
      teacher: false,
      invitationCode: true, //参赛邀请码,家庭用户时不展示
      signInfoData: null, //参赛详情数据
      teamMembers: [], //队伍成员
      flagSFZ: true, //校验身份证用
      flagSFZ01: true, //校验身份证用
      indexSFZ: null,
      minnum: 1,
      maxnum: 5,
      entryCode: false, //字段为空时对应的提示 请输入邀请码
      teamName: false, //请输入队伍名称
      mentorName: false, //请输入家长、指导老师姓名
      mentorNameText: "",
      mentorPhone: false, //请输入家长、指导老师联系电话
      mentorPhoneText: "",
      organizationName: false, //请输入机构名称
      organizationNameText: "",
      mailingAddress: false, //请输入邮寄地址
      addressXx: false, //请输入详细地址
      lang: "zh-CN",
    };
  },
  components: {
    navigation,
    footers,
    tipsSuccess,
    tipsError,
  },
  watch: {
    getLang(lang) {
      localStorage.getItem("lang", lang);
      this.lang = lang;
    },
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
  },
  created() {
    this.lang = this.$store.state.lang;
    //不是登陆状态就跳回登录页面
    if (
      localStorage.getItem("token") == null ||
      !localStorage.getItem("token")
    ) {
      this.$router.push("/login");
      return;
    }

    this.dataList();
  },
  methods: {
    // 数据回显
    dataList() {
      let data = { id: this.dialogsignInfoID.id };
      getApplyMessage(data).then((res) => {
        if (res.code == 200) {
          this.signInfoData = res.data;
          this.teamMembers = this.signInfoData.team_members;
          this.picked = this.signInfoData.participate_type; //参赛类型
          this.entry_code = this.signInfoData.entry_code; //参赛邀请码
          this.team_name = this.signInfoData.team_name; //队伍名称
          this.formArr = this.signInfoData.team_members; //队伍成员
          this.organization_name = this.signInfoData.organization_name; //机构名称
          this.mentor_name = this.signInfoData.mentor_name; //指导老师
          this.mentor_phone = this.signInfoData.mentor_phone; //老师电话
          this.mailing_address = this.signInfoData.mailing_address; //邮寄地址
          this.address = this.signInfoData.mailing_address_copy; //邮寄地址
          this.minnum = this.signInfoData.minnum; //限制的成员数量
          this.maxnum = this.signInfoData.mavNum; //限制的成员数量
          this.ageStartime = this.signInfoData.age_startime;
          this.ageEndtime = this.signInfoData.age_endtime;
          if (this.picked == 0) {
            this.invitationCode = false;
          } else {
            this.invitationCode = true;
          }
          // 队伍成员最少数量限制
          if (this.formArr.length < this.minnum) {
            this.formArr.push({
              name: "",
              sex: "",
              number: "",
              reds: false,
            });
          }
          this.defaultType();
        }
      });
    },
    // 默认参赛类型对应的列表展示
    defaultType() {
      if (this.picked == 0) {
        this.defaultType0();
      } else if (this.picked == 1) {
        this.defaultType1();
      } else if (this.picked == 2) {
        this.defaultType2();
      } else if (this.picked == 3) {
        this.defaultType3();
      }
    },
    // 0 家庭用户展示列
    defaultType0() {
      this.familyInfo = true;
      this.mechanismInfo = false;
      this.teacher = false;

      this.kindergartenInfo = false;
      this.primarySchoolInfo = false;
      this.invitationCode = false;
    },
    // 1 机构用户展示列
    defaultType1() {
      this.mechanismInfo = true;
      this.teacher = true;
      this.familyInfo = false;

      this.kindergartenInfo = false;
      this.primarySchoolInfo = false;
      this.invitationCode = true;
    },
    // 2 幼儿园展示列
    defaultType2() {
      this.kindergartenInfo = true;
      this.teacher = true;
      this.familyInfo = false;
      this.mechanismInfo = false;
      this.primarySchoolInfo = false;
      this.invitationCode = true;
    },
    // 3 小学展示列
    defaultType3() {
      this.primarySchoolInfo = true;
      this.teacher = true;
      this.familyInfo = false;
      this.mechanismInfo = false;
      this.kindergartenInfo = false;
      this.invitationCode = true;
    },
    //邀请码验证
    invites() {
      var re = /^[A-Za-z0-9]{8}$/;
      let str = this.entry_code;
      if (str == "") {
        this.entryCode = true;
        this.invite = false;
      } else {
        this.entryCode = false;
        if (re.test(str)) {
          this.invite = false;
        } else {
          this.invite = true;
        }
      }
      // if (re.test(str)) {
      //   this.invite = false;
      // } else {
      //   this.invite = true;
      // }
    },
    //手机号验证
    animate() {
      if (this.lang == "zh-CN") {
        var re = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        let str = this.mentor_phone;
        if (str == "") {
          this.mentorPhoneText = this.$t("siginfo.ent_contacnt_phone");
          this.mentorPhone = true;
          this.red = false;
        } else {
          this.mentorPhone = false;
          if (re.test(str)) {
            this.red = false;
          } else {
            this.red = true;
          }
        }
      } else {
      }
      // if (re.test(str)) {
      //   this.red = false;
      // } else {
      //   this.red = true;
      // }
    },
    //身份证号验证
    animates(index) {
      if (this.lang == "zh-CN") {
        this.indexSFZ = index;
        var res = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        let strs = this.formArr[index].number;
        if (res.test(strs)) {
          this.formArr[index].reds = false;
        } else if (!res.test(strs)) {
          this.formArr[index].reds = true;
          return;
        } else {
        }
        // // 年龄限制起始时间
        let ageStartime = this.signInfoData.age_startime;
        // // 年龄限制结束时间
        let ageEndtime = this.signInfoData.age_endtime;
        let sfznumber = []; //判断身份证号码是否重复
        // 判断身份证年龄方法 2.1 （字符串转数组再转数字类型）
        let ageStartimeNum = +ageStartime.split("-").join("");
        let ageEndtimeNum = +ageEndtime.split("-").join("");
        if (strs.length === 18) {
          let ageSFZ = +strs.substring(6, 14);
          if (ageSFZ > ageEndtimeNum || ageSFZ < ageStartimeNum) {
            this.$message.error("身份证年龄不在限制范围内,请重新输入");
            this.formArr[index].reds = true;
            this.flagSFZ01 = false;
          }
        }
        // 遍历
        this.formArr.forEach((val, i) => {
          if (val.number.length === 18) {
            sfznumber.push(val.number); //身份证数组
          }
        });
        // 校验身份证是否重复
        let newSfznumber = [];
        for (var i = 0; i < sfznumber.length; i++) {
          for (var j = i + 1; j < sfznumber.length; j++) {
            if (sfznumber[i] === sfznumber[j]) {
              this.$message.closeAll(); //清除关闭所有弹框
              this.$message.error("身份证不能重复,请重新输入");
              this.formArr[j].reds = true;
              this.flagSFZ = false;
              newSfznumber.push(sfznumber[j]);
              j = ++i;
            } else if (sfznumber[i] != sfznumber[j] && this.flagSFZ01) {
              // this.formArr[j].reds = false;
              this.flagSFZ = true;
            }
          }
        }
      } else {
      }
    },
    // 队伍名触发事件
    teamNameBlur() {
      if (this.team_name == "" || this.team_name == null) {
        this.teamName = true;
      } else {
        this.teamName = false;
      }
    },
    // 家长姓名/指导老师姓名 触发事件 0家长 1机构等
    mentorNameBlur(type) {
      if (this.mentor_name == "" || this.mentor_name == null) {
        if (type == 0) {
          this.mentorNameText = this.$t("siginfo.ent_parent_name");
        } else if (type == 1) {
          this.mentorNameText = this.$t("siginfo.ent_structor_name");
        }
        this.mentorName = true;
      } else {
        this.mentorName = false;
      }
    },
    // 机构名称 触发事件 1 机构 2 幼儿园 3 小学
    organizationNameBlur(type) {
      console.log(type);
      if (this.organization_name == "" || this.organization_name == null) {
        if (type == 1) {
          this.organizationNameText = this.$t("siginfo.ent_org_name");
        } else if (type == 2) {
          this.organizationNameText = this.$t("siginfo.ent_kid_name");
        } else if (type == 3) {
          this.organizationNameText = this.$t("siginfo.ent_pri_school");
        }
        this.organizationName = true;
      } else {
        this.organizationName = false;
      }
    },
    // 奖品邮寄地址 触发事件
    mailingAddressBlur() {
      if (this.mailing_address == "" || this.mailing_address == null) {
        this.mailingAddress = true;
      } else {
        this.mailingAddress = false;
      }
    },
    // 详细地址 触发事件
    addressXxBlur() {
      if (this.address == "" || this.address == null) {
        this.addressXx = true;
      } else {
        this.addressXx = false;
      }
    },
    // 选择家庭用户
    family() {
      this.defaultType0();
      this.mentorNameText = this.$t("siginfo.ent_parent_name");
    },
    // 选择机构用户
    mechanism() {
      this.defaultType1();
      this.organizationNameText = this.$t("siginfo.ent_org_name");
      this.mentorNameText = this.$t("siginfo.ent_structor_name");
    },
    // 选择幼儿园
    kindergarten() {
      this.defaultType2();
      this.organizationNameText = this.$t("siginfo.ent_kid_name");
      this.mentorNameText = this.$t("siginfo.ent_structor_name");
    },
    // 选择小学
    primarySchool() {
      this.defaultType3();
      this.organizationNameText = this.$t("siginfo.ent_pri_school");
      this.mentorNameText = this.$t("siginfo.ent_structor_name");
    },
    //队伍成员添加方法
    add() {
      // this.num = 999;//现在的需求是不限制成员
      if (this.formArr.length < this.maxnum) {
        this.formArr.push({
          name: "",
          sex: "",
          number: "",
          reds: false,
        });
      } else {
        this.successShow01 = true;
        setTimeout(() => {
          this.successShow01 = false;
        }, 2000);
      }
      if (this.formArr.length > 1) {
        this.delte = true;
      } else {
        this.delte = false;
      }
    },
    //队伍成员删除方法
    del() {
      this.formArr.splice(this.formArr.length - 1, 1);
      if (this.formArr.length > 1) {
        this.delte = true;
      } else {
        this.delte = false;
      }
    },
    //提交
    submit() {
      // 校验字段为空时
      // 判断邀请码为空时
      if (this.invitationCode && this.entry_code == "") {
        this.entryCode = true;
      } else {
        this.entryCode = false;
      }
      // 判断邀请码null时
      if (this.invitationCode && this.entry_code == null) {
        this.entryCode = true;
      } else {
        this.entryCode = false;
      }
      // 判断队伍名为空时
      if (this.team_name == "" || this.team_name == null) {
        this.teamName = true;
      } else {
        this.teamName = false;
      }
      // 判断家长/指导老师名为空时
      if (this.mentor_name == "" || this.mentor_name == null) {
        if (this.picked == 0) {
          this.mentorNameText = this.$t("siginfo.ent_parent_name");
        } else if (this.picked == 1 || this.picked == 2 || this.picked == 3) {
          this.mentorNameText = this.$t("siginfo.ent_structor_name");
        } else {
          this.mentorNameText = this.$t("siginfo.ent_name");
        }
        this.mentorName = true;
      } else {
        this.mentorName = false;
      }
      // 判断家长/指导老师电话为空时
      if (this.mentor_phone == "" || this.mentor_phone == null) {
        if (this.picked == 0) {
          this.mentorPhoneText = this.$t("siginfo.ent_parent_phone");
        } else if (this.picked == 1 || this.picked == 2 || this.picked == 3) {
          this.mentorPhoneText = this.$t("siginfo.ent_structor_phone");
        } else {
          this.mentorPhoneText = this.$t("siginfo.ent_contacnt_phone");
        }
        this.mentorPhone = true;
      } else {
        this.mentorPhone = false;
      }
      // 判断机构...名为空时
      if (this.invitationCode && this.organization_name == "") {
        if (this.picked == 1) {
          this.organizationNameText = this.$t("siginfo.ent_org_name");
        } else if (this.picked == 2) {
          this.organizationNameText = this.$t("siginfo.ent_kid_name");
        } else if (this.picked == 3) {
          this.organizationNameText = this.$t("siginfo.ent_pri_school");
        } else {
          this.organizationNameText = this.$t("siginfo.ent_name");
        }
        this.organizationName = true;
      } else {
        this.organizationName = false;
      }
      // 判断奖品邮寄地址为空时
      if (this.mailing_address == "" || this.mailing_address == null) {
        this.mailingAddress = true;
      } else {
        this.mailingAddress = false;
      }
      // 判断详细地址为空时
      if (this.address == "" || this.address == null) {
        this.addressXx = true;
      } else {
        this.addressXx = false;
      }
      if (
        this.entryCode ||
        this.teamName ||
        this.mentorName ||
        this.mentorPhone ||
        this.organizationName ||
        this.mailingAddress ||
        this.addressXx
      ) {
        this.required = true;
        return false;
      }
      // 如果身份信息为空
      for (var i = 0; i < this.formArr.length; i++) {
        if (
          this.formArr[i].name == "" ||
          this.formArr[i].sex == "" ||
          this.formArr[i].number == ""
        ) {
          // console.log("error");
          this.required = true;
          return false;
        }
      }
      this.required = false;
      // 如果手机号不合法
      if (this.red) {
        return false;
      }
      if (!this.flagSFZ) {
        // this.$message.error("身份证不能重复,请重新输入");
        return;
      }
      if (this.indexSFZ == null) {
        this.indexSFZ = 0;
      }
      if (this.indexSFZ != null) {
        if (this.formArr[this.indexSFZ].reds) {
          this.$message.error("请重新输入身份证号");
          return false;
        }
      }
      let data = {
        id: this.dialogsignInfoID.id,
        competitionId: this.dialogsignInfoID.competition_id,
        entryCode: this.entry_code, //邀请码
        eventName: this.signInfoData.event_name,
        mailingAddress: this.mailing_address,
        mailingAddressCopy: this.address, //详细地址
        mentorName: this.mentor_name,
        mentorPhone: this.mentor_phone,
        organizationName: this.organization_name,
        teamName: this.team_name,
        participateType: this.picked,
        teamMembers: JSON.stringify(this.formArr),
      };
      // 家庭用户 清除 邀请码
      if (this.picked == 0) {
        (data.entryCode = ""), //邀请码
          (data.organizationName = ""); //机构名称
      }
      updateApplyMessage(data).then((res) => {
        if (res.code == 200) {
          this.alerts = true;
          if (this.alerts) {
            setTimeout(() => {
              this.alerts = false;
              this.$emit("hidePop", false);
            }, 2000);
          }
        } else if (res.code == 201) {
          this.errorText01 = res.msg;
          this.errorShow01 = true;
          setTimeout(() => {
            this.errorShow01 = false;
          }, 2000);
        } else {
          this.errorText01 = res.data;
          this.errorShow01 = true;
          setTimeout(() => {
            this.errorShow01 = false;
          }, 2000);
        }
      });
    },
    cancel() {
      this.$emit("hidePop", false);
    },
  },
};
</script>

<style>
.res:hover,
.item,
.signStep,
.icon:hover,
.page-sign .signStep:after,
.page-sign .signStep .item:after {
  cursor: default !important;
}

.sign {
  height: 100%;
}

.red_01 {
  position: absolute;
  left: 0.07rem;
}

.sign {
  position: relative;
}

.el-dialog__header {
  padding-top: 0.3rem !important;
}

.sign_info .tit {
  cursor: default !important;
}
</style>
