// en
export default {
  vote: {
    mata: "Matata Popular Star Voting In Progress",
    ticket:'One account can only cast one vote a day',
    check:'ticket',
    succ_vote: "voting succeeded",
    work:'Work',
    cancel_vote: "cancellation succeeded",
  },
}