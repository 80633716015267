// zh
export default {
  works: {
    work: "提交作品",
    name: '赛事名称',
    workname: "作品名称",
    enter: "输入作品名称",
    submit_v: "提交视频",
    upload_v: "上传视频",
    size: '视频大小不超过500M',
    submit_p: "提交图片",
    surports: "支持扩展名:.JPG .PNG .GIF .TIF",
    creative: " 创意说明",
    sorry: "抱歉字数已达上限!",
    submit: "提交",
    scsb: "上传失败",
    fileSizeLimit: "文件大小超过限制,请重新上传!",
    fileCountLimit: "文件数量超过限制,请重新上传!",
    uploadFailed: "上传失败!",
    inputWorkName: "请输入作品名称!",
    uploadVideo: "请上传视频!",
    uploadImage: "请上传图片!",
    chuangyi: "请输入创意说明!",
    uploadSuccess: "上传成功!",
  }
};