// zh
export default {
  result: {
    student: "某某某学生:",
    you: '你在',
    mata: "玛塔家庭赛-从林冒险",
    // competition: '比赛中荣获：',
    competition: '荣获',
    // hard: "特此发奖，继续努力哦！",
    hard: "特此发证，以兹鼓励！",
    hard02: "希望今后继续努力，取得更好的成绩",//新增
    score: '你的赛事得分如下：',
    about: "某某某学生:",
    you: '你在',
    expression: "表达",
    reason: "获奖理由：",
    save: "保存分享",  
    story:"  一个个鲜活的小情景故事，让人差点忘了这是场编程比赛呢！小朋友们以编程作为引子，在深入了解野生动物保护的相关知识后，制作了许多有趣的手工，还将动物，人类的朋友这个主题深深印刻在了心里。这样的学习经历是在任何课堂都无法体会的"

  },
};