import evenIntroduction_zh from "./event-introduction/index_zh";
import account_zh from "./account/index_zh";
import personal_zh from "./personal/index_zh";
import faq_zh from "./faq/index_zh";
import matchsign_zh from "./match-sign/index_zh";
import review_2020_2021_zh from "./review-2020-2021/index_zh";
import newsdetails_zh from "./news-details/index_zh";
import signoffline_zh from "./sign-offline/index_zh";
import vote_zh from "./vote/index_zh";
import submitwork_zh from "./submit-work/index_zh";
import signinfo_zh from "./sign-info/index_zh";
import reviewresults_zh from "./review-results/index_zh";
import matchvideo_zh from "./match-video/index_zh";
import index_zh from "./index/index_zh";
import retrieverpassword_zh from "./retriever-password/index_zh";
export default {
  ...evenIntroduction_zh,
  ...account_zh,
  ...personal_zh,
  ...faq_zh,
  ...matchsign_zh,
  ...review_2020_2021_zh,
  ...newsdetails_zh,
  ...signoffline_zh,
  ...vote_zh,
  ...submitwork_zh,
  ...signinfo_zh,
  ...reviewresults_zh,
  ...matchvideo_zh,
  ...index_zh,
  ...retrieverpassword_zh,
  //所有侧导航的name
  loginPage: {
    login: "登录",
    loginout: "退出",
    loginsucceeded: "登录成功",
    username: "请输入用户名",
    password: "请输入密码",
    registernow: "立即注册",
    getverification: "获取验证码",
    forget: "忘记密码？",
    register: "立即注册",
    acount: "账号登录",
    phone: "请输入手机号",
    code: "验证码",
    m: "秒",
    m_success: "修改成功",
    pwd: "请输入密码",
    loginphone: "手机登录",
    Retrievepwd: "找回密码",
    verification: "请输入验证码",
    newpwd: "请输入新密码",
    againpwd: "再次输入新密码",
    submit: "提交",
    registerphone: "请输入注册手机号",
    family: "家庭用户",
    institution: "机构用户",
    kindergarten: "幼儿园",
    primary: "小学",
    tips: "密码由6-16位数字、字母或符号组成、至少包含两种字符",
    read: "我已阅读",
    and: "和",
    matataworld: "《玛塔赛事用户协议》",
    policy: "《隐私政策》",
    // register: "注册",
    success_regist: "注册成功",
    account_login: "账号已存在，请返回登录",
    account_register: "账号不存在，请先注册",
    email: "请输入邮箱账号",
    email_login: "邮箱登录",
    logout_sucess: "退出登录成功",
  },
  //首页
  homePage: {
    more: "查看更多",
    mata_program: "玛塔机器人编程赛",
    events_2023: " 2024年赛事",
    cooper_institutions: "合作机构",
  },
  //navigation
  navigation: {
    event_introduction: "赛事介绍",
    event_registration: "报名赛事",
    event_information: "赛事信息",
    event_review: "赛事回顾",
    personal_center: "个人中心",
    season_introduction_21: "2021赛季介绍",
    event_consultation: "赛事资讯",
    data_download: "资料下载",
    common_problem: "常见问题",
    season_2020: "2020赛季",
    season_2021: "2021赛季",
    season_2022: "2022赛季",
    season_2023: "2023赛季",
  },
  footer: {
    about_matalab: "关于MatataStudio",
    sign_up: "报名参赛",
    contact_us: "联系我们",
    copyright: "Copyright © MATATALAB 深圳市玛塔创想科技有限公司 粤ICP备18147217号",
    contact: "联系方式",
    email: '邮箱：mwrc@matatalab.com'
  },
  down: {
    down: '资料下载',
    download: '下载',
    top: '置顶'
  }
};
