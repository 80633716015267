<template>
  <div>
    <navigation />
    <div class="h77"></div>

    <div class="page-personal">
      <div class="wrap flex f-jb">
        <div class="personal-right personal-right2">
          <div class="right-title">
            <span>{{ $t("works.work") }}</span>
          </div>

          <div class="page-sign">
            <div class="signInput">
              <div class="sginForm">
                <div class="flex input-item f-ac">
                  <div class="tit">{{ $t("works.name") }}</div>
                  <div class="spacer"></div>
                  <div class="flex-item">{{ eventName }}</div>
                </div>

                <div class="flex input-item f-ac">
                  <div class="tit">{{ $t("works.workname") }}</div>
                  <div class="spacer"></div>
                  <div class="flex-item">
                    <input
                      type="text"
                      name=""
                      id=""
                      value=""
                      :placeholder="$t('works.enter')"
                      v-model="productionName"
                    />
                  </div>
                </div>
                <div class="flex input-item f-ac">
                  <div class="tit">{{ $t("works.submit_v") }}</div>
                  <div class="spacer"></div>
                  <div class="flex-item flex f-ac" v-if="isChinese">
                    <div class="btn-video btnVideo">
                      <!-- OSS 上传图片/视频 -->
                      <el-upload
                        ref="uploadVideo"
                        accept=".mp4"
                        drag
                        :before-upload="beforeUpload"
                        :on-success="handleSuccess"
                        :http-request="handleHttpRequest"
                        :headers="uploadHeaders"
                        :on-exceed="uploadLimit"
                        :limit="1"
                        :disabled="disabled"
                        multiple
                        action=""
                        :file-list="fileList"
                        >{{ $t("works.upload_v") }}
                      </el-upload>
                    </div>
                    <span class="gray">{{ $t("works.size") }}</span>
                  </div>
                  <div class="flex-item" v-else>
                    <input
                      v-model="requestVideo"
                      placeholder="Enter video URL"
                      type="text"
                      name=""
                      id=""
                      value=""
                    />
                  </div>
                </div>

                <div class="flex input-item">
                  <div class="tit tit01">
                    <div class="dw">{{ $t("works.submit_p") }}</div>
                  </div>
                  <div class="spacer"></div>
                  <div class="flex-item flex f-ac uploadImg">
                    <div class="boxZW"></div>
                    <img
                      class="uploadImgIcon"
                      src="../assets/img/icon-u.png"
                      style="width: 1.2rem; height: 1.2rem"
                    />

                    <!-- OSS 上传图片/视频 list-type="picture"展示缩略图picture-card 限制数量:limit="10" -->
                    <el-upload
                      v-if="true"
                      class="upload_img"
                      ref="upload"
                      drag
                      accept=".jpg,.png,.gif,.tif"
                      :before-upload="beforeUpload"
                      :on-success="handleSuccess"
                      :http-request="handleHttpRequestImg"
                      :headers="uploadHeaders"
                      :disabled="disabled"
                      multiple
                      action=""
                      :file-list="fileList"
                      list-type="picture-card"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <span class="gray" v-show="jpgImg">{{
                      $t("works.surports")
                    }}</span>
                  </div>
                </div>

                <div class="flex input-item">
                  <div class="tit">{{ $t("works.creative") }}</div>
                  <div class="spacer"></div>
                  <div class="flex-item">
                    <!-- <textarea
                      name=""
                      rows=""
                      cols=""
                      placeholder="创意说明"
                      v-model="originality"
                       maxlength="10"
                    ></textarea> -->
                    <el-input
                      type="textarea"
                      placeholder=""
                      v-model="originality"
                      maxlength="3000"
                      show-word-limit
                      :autosize="{ minRows: 2, maxRows: 4 }"
                    >
                    </el-input>
                    <span
                      style="
                        color: red;
                        line-height: 20px;
                        margin: 3px;
                        white-space: nowrap;
                        font-size: 12px;
                      "
                      v-show="red"
                      >{{ $t("works.sorry") }}</span
                    >
                  </div>
                </div>

                <div class="flex input-item">
                  <div class="flex-item flex f-jc">
                    <div class="btn-submit" @click="btnSubmit">
                      {{ $t("works.submit") }}
                    </div>
                  </div>
                </div>

                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <tipsSuccess
      :successText="successText01"
      v-show="successShow01"
    ></tipsSuccess>
    <tipsError :errorText="errorText01" v-show="errorShow01"></tipsError>
    <footers />
  </div>
</template>
<script src="http://gosspublic.alicdn.com/aliyun-oss-sdk-4.4.4.min.js">
</script>
<script>
import axios from "axios";
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import tipsSuccess from "../components/tips-success.vue"; //成功提示弹框
import tipsError from "../components/tips-error.vue"; //失败提示弹框
import { competitionProduction } from "../api/personal"; //提交作品
// import "./service.js"
import Client from "../utils/Client";

export default {
  data() {
    return {
      successText01: "",
      successShow01: false,
      errorText01: "",
      errorShow01: false,
      region: "oss-cn-shenzhen",
      bucket: "", //这里选择OSS容器
      percentage: 0,
      url: "https://prod-api.matatalab.com/v1/oss/getCredit/png", //后台获取token地址
      ClientObj: null,
      dataObj: {},
      expiration: "",
      fileList: [],
      files: 10,
      uploadHeaders: {
        authorization: "*",
      },
      disabled: false,
      basePath: "",
      dataInfo: null, //上级数据
      eventName: "", //赛事名称
      originality: "", //创意说明
      productionName: "", //作品名称
      // requestVideo: [],//视频地址
      requestVideo: "", //视频限制只传一个
      requestImg: [], //图片地址
      submitWorkInfo: {}, //取出参数值
      red: false,
      jpgImg: true,
    };
  },
  components: {
    navigation,
    footers,
    tipsSuccess,
    tipsError,
  },
  computed: {
    isChinese() {
      console.log(this.returnLang());
      return this.returnLang() === "zh-CN";
    },
  },
  methods: {
    returnLang() {
      // 优先从 localStorage 获取语言设置
      let lang = localStorage.getItem("lang");
      if (!lang) {
        // 如果 localStorage 没有，则从浏览器获取
        const bowerLang = (
          navigator.language ||
          navigator.userLanguage ||
          window.navigator.language
        ).toLowerCase();
        console.log("Browser Language:", bowerLang);
        lang = bowerLang.startsWith("zh") ? "zh-CN" : "en-US";
      }
      return lang;
    },
    getDate() {
      const date = new Date(),
        year = date.getFullYear(),
        month =
          date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`,
        day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
        hh = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`,
        mm =
          date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
      return `${year}${month}${day}${hh}${mm}`;
    },
    getAliToken() {
      //获取Token
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        axios({
          method: "GET",
          url: this.url,
          headers: {
            Authorization: token,
          },
        })
          .then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data;
              // console.log(data)
              this.expiration = data.expiration;
              this.dataObj = {
                region: this.region,
                bucket: data.bucket,
                accessKeyId: data.accessKeyId,
                accessKeySecret: data.accessKeySecret,
                stsToken: data.securityToken,
              };
              this.basePath = data.basePath;
              // console.log(this.dataObj)
              resolve(true);
            } else {
              reject(false);
            }
          })
          .catch((err) => {
            // console.log(err);
            reject(false);
          });
      });
    },
    beforeUpload(file) {
      const size = file.size / 1024 / 1024;
      if (size > 500) {
        this.$message.error(this.$t("works.fileSizeLimit"));
        return false;
      }
      return new Promise((resolve, reject) => {
        this.getAliToken()
          .then((response) => {
            if (response) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((err) => {
            // console.log(err);
            reject(err);
          });
      });
    },
    uploadLimit(files, fileList) {
      if (fileList.length >= 2) {
        //如果上传文件超出限定数量则触发
        this.$message.error(this.$t("works.fileCountLimit"));
      }
      this.$set(fileList[0], "raw", files[0]);
      this.$set(fileList[0], "name", files[0].name);
      this.$refs["uploadVideo"].clearFiles(); //清除文件
      this.$refs["uploadVideo"].handleStart(files[0]); //选择文件后的赋值方法
      this.$refs["uploadVideo"].submit(); //上传
    },
    async handleHttpRequest(option) {
      //上传OSS
      try {
        let vm = this;
        vm.disabled = true;
        const client = Client(this.dataObj),
          file = option.file;
        //随机命名
        const random_name =
          this.random_string(6) +
          "_" +
          new Date().getTime() +
          "." +
          file.name.split(".").pop();
        // 分片上传文件
        await client
          .multipartUpload(this.basePath + "/" + random_name, file, {
            progress: async function (p) {
              let e = {};
              e.percent = p * 100;
              option.onProgress(e);
            },
          })
          .then(({ res }) => {
            // console.log(res);
            if (res.statusCode === 200) {
              vm.disabled = false;
              // option.onSuccess(ret)
              // console.log(res.requestUrls)//文件路径,我们要的是截取问号前面的
              // console.log(res.requestUrls[0].split("?")[0]);//截取问号前面的url
              // this.requestVideo.push(res.requestUrls[0].split("?")[0])
              this.requestVideo = res.requestUrls[0].split("?")[0]; //视频限制只传一个
              // console.log(this.requestVideo);
              return res.requestUrls;
            } else {
              vm.disabled = false;
              option.onError(this.$t("works.uploadFailed"));
            }
          })
          .catch((error) => {
            vm.disabled = false;
            option.onError(this.$t("works.uploadFailed"));
          });
      } catch (error) {
        console.error(error);
        this.disabled = false;
        option.onError(this.$t("works.uploadFailed"));
      }
    },
    // 图片
    async handleHttpRequestImg(option) {
      //上传OSS
      this.jpgImg = false; //图片类型提示消失
      try {
        let vm = this;
        vm.disabled = true;
        const client = Client(this.dataObj),
          file = option.file;
        //随机命名
        const random_name =
          this.random_string(6) +
          "_" +
          new Date().getTime() +
          "." +
          file.name.split(".").pop();
        // 分片上传文件
        await client
          .multipartUpload(this.basePath + "/" + random_name, file, {
            progress: async function (p) {
              let e = {};
              e.percent = p * 100;
              option.onProgress(e);
            },
          })
          .then(({ res }) => {
            // console.log(res);
            if (res.statusCode === 200) {
              vm.disabled = false;
              // option.onSuccess(ret)
              // console.log(res.requestUrls)//文件路径,我们要的是截取问号前面的
              // console.log(res.requestUrls[0].split("?")[0]);//截取问号前面的url
              this.requestImg.push(res.requestUrls[0].split("?")[0]);
              // console.log(this.requestImg);
              return res.requestUrls;
            } else {
              vm.disabled = false;
              option.onError(this.$t("works.uploadFailed"));
            }
          })
          .catch((error) => {
            vm.disabled = false;
            option.onError(this.$t("works.scsb"));
          });
      } catch (error) {
        console.error(error);
        this.disabled = false;
        option.onError(this.$t("works.uploadFailed"));
      }
    },
    handleSuccess(response, file, fileList) {
      // console.log(response);
      // console.log(file);
      // console.log(fileList);
    },
    // 随机生成文件名
    random_string(len) {
      len = len || 32;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678",
        maxPos = chars.length,
        pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    // 提交
    btnSubmit() {
      if (this.productionName == "") {
        this.$message.error(this.$t("works.inputWorkName"));
        return;
      } else if (this.requestVideo == "") {
        this.$message.error(this.$t("works.uploadVideo"));
        return;
      } else if (this.requestImg.length == 0) {
        this.$message.error(this.$t("works.uploadImage"));
        return;
      } else if (this.originality == "") {
        this.$message.error(this.$t("works.chuangyi"));
        return;
      }
      let data = {
        // id: 1,
        id: this.$route.params.id,
        appltId: this.$route.params.id, //报名id
        eventName: this.eventName, //赛事名称
        originality: this.originality, //创意说明
        productionName: this.productionName, //作品名称
        productionPic: this.requestImg.join(","), //提交图片
        // production_video: this.requestVideo.join(","),//提交视频
        productionVideo: this.requestVideo, //提交视频
        videoPic:
          this.requestVideo + "?x-oss-process=video/snapshot,t_10000,m_fast", //视频封面
      };
      if (this.$route.params.id) {
        data.id = this.$route.params.id;
        data.appltId = this.$route.params.id;
      } else {
        data.id = this.submitWorkInfo.id;
        data.appltId = this.submitWorkInfo.id;
      }
      competitionProduction(data).then((res) => {
        if (res.code == 200) {
          // this.$message.success("提交成功!");
          this.successText01 = this.$t("works.uploadSuccess");
          this.successShow01 = true;
          setTimeout(() => {
            this.successShow01 = false;
            this.$router.push("/personal");
          }, 2000);
        } else {
          this.errorText01 = res.msg;
          this.errorShow01 = true;
          setTimeout(() => {
            this.errorShow01 = false;
          }, 2000);
        }
      });
    },
  },
  mounted() {
    // 手动触发更新 isChinese 以确保语言切换后模板能重新渲染
    this.$forceUpdate();
  },
  watch: {
    uploadCode(val) {
      console.info(val);
    },
    // 字数限制 监听
    originality() {
      if (this.originality.length >= 3000) {
        // this.$message.closeAll();
        // this.$message.error("文字已达上限")
        this.red = true;
      } else {
        this.red = false;
      }
    },
    "$i18n.locale"() {
      // 当语言切换时，强制更新组件
      this.$forceUpdate();
    },
  },

  created() {
    this.getAliToken();
    // console.log(this.$route.params.id);
    // console.log(this.$route.params.eventName);
    // this.eventName = this.$route.params.eventName;
    this.submitWorkInfo = JSON.parse(localStorage.getItem("submitWorkInfo"));
    this.eventName = this.submitWorkInfo.eventName;
  },
};
</script>

<style>
.tit01 {
  /* width: 2rem !important; */
  padding-right: 1rem;
  position: relative;
}

.tit01 .dw {
  position: absolute;
  top: 0;
  left: 0;
}

.el-upload-list__item-name {
  width: 6rem;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.btn-video,
.uploadImg {
  position: relative;
}

.uploadImg .uploadImgIcon {
  position: absolute;
  left: 0;
  top: 0;
  /* z-index: 99; */
}

.boxZW {
  /* width: 1.2rem; */
  padding-right: 1.2rem;
  height: 1.2rem;
}

.upload_img {
  margin-left: 0.15rem;
}

.btn-video .el-upload-dragger {
  width: 1.5rem;
  height: 0.4rem;
  /* opacity: 0; */
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.18rem;
  color: #fff;
  background-color: #fd6c0b;
  border: none;
}

.uploadImg .el-upload-dragger {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: pink;
  opacity: 0;
}

/* 上传视频样式 */
.btnVideo {
  position: relative;
}

.btnVideo .el-upload-list {
  position: absolute;
  top: 0;
  left: 1.5rem;
}

.spacer {
  display: inline-block;
  /* 或者使用 float:left; 根据需要调整 */
  width: 10px;
  /* 占位宽度 */
  height: 0;
}

.url-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input::placeholder {
  font-size: 13px;
  /* 设置placeholder的字体大小 */
}
</style>
