// zh
export default {
  works: {
    work: "Submit Work",
    name: 'Competition Name',
    workname: "Work Name",
    enter: "Enter a work name",
    submit_v: "Submit video",
    upload_v: "Upload video",
    size: 'Video size does not exceed 500m',
    submit_p: "Submit picture",
    surports: "Supported extensions:. Jpg. PNG. GIF. TIF",
    creative: " Creative description",
    sorry: "Sorry, the number of words has reached the maximum!",
    submit: "Submit",
    scsb: "Upload failed, please try again!",
    fileSizeLimit: "The file size exceeds the limit, please re-upload!",
    fileCountLimit: "The number of files exceeds the limit, please re-upload!",
    uploadFailed: "Upload failed, please try again!",
    inputWorkName: "Please enter the work name!",
    uploadVideo: "Please upload the video!",
    uploadImage: "Please upload the picture!",
    chuangyi: "Please enter the creative description!",
    uploadSuccess: "Upload success!",
  }
};