<template>
  <div>
    <navigation />
    <div class="h77"></div>

    <div class="wrap pt100 pb100">
      <!-- 给要保存为图片的元素添加 ref -->
      <div ref="imageWrapper" class="page-reviewResults">
        <!-- 队伍名称 -->
        <!-- <div class="name fontSy" v-html="teamName">{{ $t("result.student") }}</div> -->
        <div class="main">
          <!-- 赛事名称 -->
          <div class="title fontSy fontSyEventName" v-html="eventName">
           <!-- {{ $t("result.you") }}<span class="orange">&nbsp;{{ $t("result.mata") }}</span>&nbsp;{{ $t("result.competition") }} -->
          </div>
          <!-- <div class="prize">
            <img src="../assets/img/img3.png" />
          </div> -->
          <!-- 队伍名称 -->
          <!-- <div class="name fontSy fontSyTeamName" v-html="teamName">{{ $t("result.student") }}</div> -->
          <div class="name fontSy fontSyTeamName">
            <span class="fontSyTeamNameItem" v-for="(item,index) in childrenNameList" :key="index">{{item.name}}</span>
          </div>
          <div class="competition">{{ $t("result.competition") }}</div>
          <!-- 什么奖 -->
          <div class="title fontSy fontSyEventName" v-html="eventContent.content"></div>
          <div class="tedxt" style="text-align:center;padding-top:0;padding-bottom:0.1rem;">{{ $t("result.hard") }}</div>
          <div class="tedxt" style="text-align:center;padding-top:0;padding-bottom:0.7rem;">{{ $t("result.hard02") }}</div>
          <!-- 赛事得分 -->
          <div class="tit flex f-ac">
            <img src="../assets/img/icon-start2.png" /> {{ $t("result.score") }}
          </div>
          <div class="list">
            <div class="flex item" v-for="(item,index) in list" :key="index">
              <div class="flex-item" v-html="item.title"> {{ $t("result.expression") }}</div>
              <div class="flex-item" v-html="item.content">10</div>
            </div>
          </div>
          <!-- 获奖理由 -->
          <div class="tit flex f-ac">
            <img src="../assets/img/icon-start2.png" /> {{ $t("result.reason") }}
          </div>
          <div class="info" v-html="award.content" :style="{lineHeight: 30 + 'px'}">
            {{ $t("result.story") }}
          </div>
        </div>
      </div>
      <div class="flex pt100 pb100 f-jc">
        <!-- 给下载按钮添加事件 -->
        <div class="btn-video" style="font-size:0.24rem;width:1.8rem;line-height:0.6rem;" @click="handleDownload">{{ $t("result.save") }}</div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import {  grade,getApplyMessage } from "../api/personal";
import html2canvas from "html2canvas";//引入html2canvas
export default {
  data(){
    return {
      list: [],
      award: {},//获奖理由
      eventContent: {},//赛事结果--什么奖
      eventName: this.$route.params.eventName,//赛事名称
      // eventName: "玛塔家庭赛-从林冒险",//赛事名称
      teamName: this.$route.params.teamName,//队伍名称
      childrenNameList: [],//队伍成员孩子们名字
    }
  },
  components: {
    navigation,
    footers,
  },
  created() {
    this.details();
    this.dataList();
  },
  methods: {
    // 调取详情接口，获取队伍成员名
    details() {
      let data = { id: this.$route.query.id };
      getApplyMessage(data).then((res) => {
        if (res.code == 200) {
          this.eventName = res.data.event_name;
          this.childrenNameList = res.data.team_members; //队伍成员
        }
      });
    },
    // 下载保存图片
    handleDownload(){/** 生成图片*/
      html2canvas(this.$refs.imageWrapper).then(canvas => {
        let dataURL = canvas.toDataURL("image/png");
        if (dataURL !== "") {
          /** 下载图片*/
          let alink = document.createElement("a");
          alink.href = dataURL;
          alink.download = "downloadName.png";
          alink.click();
        }
      });
    },
    dataList(){
      let data = {worksId:this.$route.query.id};
      // let data = {worksId:3};
      grade(data).then(res => {
        if(res.code == 200){
          res.data.map(val => {
            if(val.type == "赛事结果"){
              this.eventContent = val;
            }else if(val.type == "获奖理由"){
              this.award = val;
            }else {
              this.list.push(val);
            }
          });
        }
      })
    },
    replaceP(str){
      return str.replace(/<p>/g, '<p class="info">').replace(/<\/p>/g, '');
    }
  },
};
</script>

<style>
/* 赛事名称 */
.fontSyEventName, .fontSyTeamName, .competition {
  text-align: center;
}
.fontSyEventName {
  font-size: 0.7rem !important;
}
/* 队伍名称 */
.fontSyTeamName {
  font-size: 0.4rem !important;
}
.fontSyTeamName .fontSyTeamNameItem {
  display: inline-block;
  padding-right: .3rem;
}.fontSyTeamName .fontSyTeamNameItem:last-child {
  padding-right: 0;
}
.fontSyEventName p {
	font-family: "SourceHanSansCN-Heavy" !important;
	color: #ec662a !important;
  font-size: 0.7rem !important;
}
.competition {
	font-size: .24rem;
  padding-bottom: 0.2rem;
}
.info>>>p {
	overflow-wrap: break-word;
	padding: .15rem .15rem;
	word-break: break-all;
  word-wrap: break-word;
	width: 50%!important;
}
</style>
