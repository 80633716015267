// zh
export default {
  acount: {
    event: "My event",
    manage: "Settings",
    tel: "Mobile number",
    type: "Account type",
    home: "home user",
    institutional: "institutional user",
    kindergarten: " Kindergarten",
    primary: "Primary school",
    name: "Name",
    family: "Family name",
    org: "Organization name",
    kinder: "kindergarten name",
    pri_sch: "primary school name",
    email: "Email address",
    edit: "Edit",
    tips: "if you need to modify your mobile phone number, please contact --",
    ent_name: "enter name",
    ent_fam: "enter family name",
    ent_org: "enter organization name",
    ent_kin: "enter kindergarten name",
    ent_pri: "enter primary school name",
    ent_email: "enter email address",
    sorry: "sorry, the email format is incorrect!",
    submit: "Submit",
    cancel: "Cancel",
  },
};
