export default {
  password: {
    retrieve: 'retrieve password',
    reg_mobile: '请please enter the registered mobile phone number',
    rcorrect_mobile: 'please enter the correct mobile phone number!',
    ver_code: 'please enter the verification code',
    getcode: 'get verification code',
    seconds: 'seconds',
    enter_new_pass: 'please enter a new password',
    digits_pass: 'the password is 6-16 digits in English plus numbers',
    again_password: 'enter new password again',
    submit: 'Submit',
    modifiy_succee: 'modification succeeded',
    empty: 'Mailbox cannot be empty',

  }
};
