import request from "../utils/request";
// 查看报名赛事
export const getApply = (data) => {
  return request({
    url: "v1/competition/getApply", //接口地址
    method: "post",
    data: data,
  });
};
// 分享投票二维码
export const qrcode = (query) => {
  return request({
    url: "v1/code/qrcode", //接口地址
    method: "get",
    params: query,
  });
};
// 查询当前报名赛事的所有作品
export const findProduction = (query) => {
  return request({
    url: "v1/competition/findProduction", //接口地址
    method: "get",
    params: query,
  });
};
// 点赞
// export const like = query => {
//     return request({
//         url: 'v1/competition/like',//接口地址
//         method: 'get',
//         params: query
//     });
// };
export const like = (data) => {
  return request({
    url: "v1/competition/like", //接口地址
    method: "post",
    data: data,
  });
};
// 取消点赞
// export const unlike = query => {
//     return request({
//         url: '/api/competition/unlike',//接口地址
//         method: 'get',
//         params: query
//     });
// };
export const unlike = (data) => {
  return request({
    // url: '/api/competition/unlike',//接口地址
    url: "/v1/competition/unlike", //接口地址
    method: "post",
    data: data,
  });
};
// 上传视频--阿里云接口
// export const getVideoAuth = query => {
//     return request({
//         // url: '/v1/oss/getVideoAuth',//接口地址
//         url: 'https://dev-api.matatalab.com/v1/oss/getCredit/124.png',
//         method: 'get',
//         params: query
//     });
// };
// 提交作品
export const competitionProduction = (data) => {
  return request({
    // url: 'api/competition/production',//接口地址
    url: "v1/competition/production", //接口地址
    method: "post",
    data: data,
  });
};
// 参赛详情查看
export const getApplyMessage = (query) => {
  return request({
    // url: 'api/competition/getApplyMessage',//接口地址
    url: "v1/competition/getApplyMessage", //接口地址
    method: "get",
    params: query,
  });
};
// 修改报名信息
export const updateApplyMessage = (data) => {
  return request({
    // url: 'api/competition/updateApplyMessage',//接口地址
    url: "v1/competition/updateApplyMessage", //接口地址
    method: "post",
    data: data,
  });
};
// 取消报名
export const competitionDelete = (data) => {
  return request({
    // url: 'api/competition/delete',//接口地址
    url: "v1/competition/delete", //接口地址
    method: "post",
    params: data,
  });
};
// 评审结果
export const grade = (query) => {
  return request({
    // url: 'api/competition/grade',//接口地址
    url: "v1/competition/grade", //接口地址
    method: "get",
    params: query,
  });
};
export const getProduction = (query) => {
  return request({
    url: "v1/code/getProduction", //接口地址
    method: "get",
    params: query,
  });
};
// 点赞 分享 暂时调试用
// export const getProduction = query => {
//     return request({
//         // url: 'http://192.168.0.105:8087/code/getProduction',//接口地址
//         // url: 'api/competition/findProduction',//接口地址
//         url: 'v1/competition/findProduction',//接口地址
//         method: 'get',
//         params: query,
//     });
// };
// banner图
export const getBanner = (query) => {
  return request({
    url: "v1/material/getBanner", //接口地址
    method: "get",
    params: query,
  });
};
