// zh
export default {
  vote: {
    mata: "玛塔人气之星投票进行中",
    ticket:'一个账户一天只能投一票',
    check:'票',
    succ_vote: "投票成功",
    cancel_vote: "取消成功",
    work:'号作品'
  },
};



