// zh
export default {
  event: {
    about: "MatataWorld Robotics Competition",
    mata: "The MatataWorld Robotics Competition (MWRC) is a global robotics event seeking to improve children's all-round skills through fun and challenging activities.In 2020, the MWRC Organizing Committee was initiated and established by China, Japan, Singapore, and Canada to promote the development of STEAM education for preschool children around the world. Offering challenging tasks, the event is designed to increase children's self-confidence in science and technology, and help them apply what they learn to solve real-life problems so as to explore and create a new world of their own. ",
    set: {
      setting: "Competitions",
      programing: "Tangible Coding",
      online: "Offline Robotics Competition",
      offline: "Online Robotics Competition",
    },
    idea: 'Concept',
    share: "Share",
    children: "Children are encouraged to share their creative works made in the competition with peers around the world.",
    confidence: "Confident",
    ihope: "Children are expected to enjoy the satisfaction of completing tasks.",
    innovation: "Create",
    innovativec: "Innovative competitions combined with creative task-based challenges.",
    collaboration: "Collaborate",
    challenges: "Children are expected to enhance team awareness by completing tasks as a team.",
    develop: 'MWRC\'s Development',
    footer: {
      contact: "contact us",
      competition: "competition consultation: MWRC event organizing committee",
      confidence: "Confident",
      tasks: "Children are expected to enjoy the satisfaction of completing tasks.",
      address: "address: 504, building B, Jianxing science and technology building, No. 1004, chaguang Road, Nanshan District, Shenzhen",
      email: "Email: mwrc@matatalab.com。",
      business: "business cooperation: Cheng Wenpeng",
      tel: "Tel: 13410175588",
      email_cycle: "Email：cycle@matatalab.com",
    }
  },
};