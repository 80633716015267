<template>
  <div class="vote page-personal-pop">
    <div class="test main">
      <div class="span title" v-if="list">
        {{ list[0].eventName }}
      </div>
      <div class="span1 stitle">{{ $t("vote.mata") }}</div>
      <div class="span tips">{{ $t("vote.ticket") }}</div>
      <!-- 视频 -->
      <div class="videos list" v-if="false">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        />
        <div class="info flex f-jb f-ac">
          <!-- <div class="tit">list.production</div> -->
          <div class="tit">{{ list.productionName }}</div>
          <div class="flex f-ac">
            <span class="icon">
              <img
                class="iconImg"
                :src="seen ? noopen : open"
                @click="likes()"
                alt=""
              />
            </span>
            {{ count }}{{ $t("vote.check") }}
          </div>
        </div>
      </div>
      <!--  -->
      <div class="videos list" v-for="(item, idx) in list" :key="idx">
        <div class="video-cover">
          <!-- <img src="../assets/img/news6.jpg" alt=""> -->
          <img class="coverImg" :src="item.videoImg" alt="" />
          <i
            class="video-play"
            @click="videoPlay(idx, item.productionVideo, item.videoPic)"
          >
            <img class="playImg" src="../assets/img/video-play.png" alt="" />
          </i>
        </div>
        <div class="info flex f-jb f-ac positionRe">
          <div
            @click="b($event)"
            v-if="item.productionName"
            class="tit tit-txt01"
          >
            {{ item.productionName }}
          </div>
          <div @click="b($event)" v-else class="tit tit-txt01">
            {{ idx + 1 }}{{ $t("vote.work") }}
          </div>
          <!-- <div class="tit">{{idx+1}}号作品</div> -->
          <div class="flex f-ac">
            <span class="icon">
              <!-- <img :src="seen ? noopen : open" @click="likes(idx)" alt="" /> -->
              <img
                class="iconImg01"
                v-show="item.like == 1"
                :src="noopen"
                @click="
                  unlikes(
                    idx,
                    item.id,
                    item.openId,
                    item.competitionId,
                    item.userId
                  )
                "
                alt=""
              />
              <img
                class="iconImg01"
                v-show="item.like == 0"
                :src="open"
                @click="
                  likes(
                    idx,
                    item.id,
                    item.openId,
                    item.competitionId,
                    item.userId
                  )
                "
                alt=""
              /> </span
            >{{ item.likenum }}{{ $t("vote.check") }}
          </div>
        </div>
      </div>
      <!-- 投票成功 -->
      <div class="vote-success" v-show="voteSuccess">
        <div></div>
        <img class="sucessImg" src="../assets/img/rg3.png" alt="" />
        <p>{{ $t("vote.succ_vote") }}</p>
      </div>
      <!-- 取消投票成功 -->
      <div class="vote-success" v-show="voteCancel">
        <div></div>
        <img class="sucessImg" src="../assets/img/rg3.png" alt="" />
        <p>{{ $t("vote.cancel_vote") }}</p>
      </div>
      <!-- 接口失败提示 -->
      <div class="vote-success" v-show="voteCancel01">
        <div></div>
        <img class="sucessImg" src="../assets/img/rg4.png" alt="" />
        <p>{{ failTex }}</p>
      </div>
      <!-- 视频 -->
      <div v-show="videoShow" class="videoMenban">
        <div class="menban01" v-show="menbanShow"></div>
        <div class="video">
          <div class="closeBtn01" @click="closeBtn()"></div>
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          />
        </div>
      </div>

      <div class="imgs" v-if="false">
        <img class="imgss" :src="list.productionPic" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { like, unlike, getProduction } from "../api/personal";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      loading: null,
      menbanShow: false, //蒙版
      videoShow: false, //视频
      voteSuccess: false, //投票成功
      voteCancel: false, //取消投票成功
      voteCancel01: false, // 接口失败提示
      onceFlag: false, //限制用户点击操作
      failTex: "",
      seen: "", //投票图片切换
      list: "",
      open: require("../assets/img/icon-z.png"), //图片地址
      noopen: require("../assets/img/icon-z2.png"), //图片地址
      count: 0, //投票次数
      flg: true,
      videoCover: [], //视频封面
      isRetry: false,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            // src: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4", // url地址
            // src: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
            src: "",
          },
        ],
        poster: "", // 你的封面地址(目前还是静态地址)
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  created() {
    this.getProduction();
  },
  methods: {
    getProduction() {
      let data = {
        // openid:"o1ZDtvj4USDkb4h3jGjBARoSS9U8",
        // applyId: 6
      };
      getProduction().then((res) => {
        if (res.code === 200) {
          this.list = res.data;
          this.list.forEach((val) => {
            // 视频地址 和 封面
            // val.videoImg = val.productionVideo+"?x-oss-process=video/snapshot,t_10000,m_fast";
            val.videoImg = val.videoPic;
          });
        } else {
          if (!this.isRetry) {
            this.failTex = res.msg;
            this.voteCancel01 = true;
            this.getProduction();
            setTimeout(() => {
              this.voteCancel01 = false;
            }, 2000);
            this.isRetry = true;
          }
        }
      });
    },
    // 投票
    likes(idx, id, openId, competitionId, userId) {
      // this.seen = !this.seen;
      // 控制投票的小手展示问题
      // if(this.list[idx].like == 0){
      //   this.list[idx].like = 1
      // }else if(this.list[idx].like == 1){
      //   this.list[idx].like = 0
      // };

      // this.loading = Loading.service({
      //     lock: true,
      //     text: '加载中……',
      //     background: 'rgba(0, 0, 0, 0.7)'
      // });
      let data = {
        worksId: id,
        openId: openId,
        competitionId: competitionId,
        userId: userId,
      };
      like(data).then((res) => {
        if (res.code == 200) {
          // this.loading.close();
          this.voteSuccess = true;
          this.getProduction();
          setTimeout(() => {
            this.voteSuccess = false;
          }, 2000);
        } else if (res.code == 202) {
          return;
        } else {
          // this.loading.close();
          this.failTex = res.msg;
          this.voteCancel01 = true;
          this.getProduction();
          setTimeout(() => {
            this.voteCancel01 = false;
          }, 2000);
        }
      });
    },
    // 取消投票
    unlikes(idx, id, openId, competitionId, userId) {
      // this.loading = Loading.service({
      //     lock: true,
      //     text: '加载中……',
      //     background: 'rgba(0, 0, 0, 0.7)'
      // });
      // this.seen = !this.seen;
      // 控制投票的小手展示问题
      // if(this.list[idx].like == 0){
      //   this.list[idx].like = 1
      // }else if(this.list[idx].like == 1){
      //   this.list[idx].like = 0
      // };
      let data = {
        worksId: id,
        openId: openId,
        competitionId: competitionId,
        userId: userId,
      };
      unlike(data).then((res) => {
        if (res.code == 200) {
          // this.loading.close();
          this.voteCancel = true;
          this.getProduction();
          setTimeout(() => {
            this.voteCancel = false;
          }, 2000);
        } else if (res.code == 202) {
          return;
        } else {
          // this.loading.close();
          this.failTex = res.msg;
          this.voteCancel01 = true;
          this.getProduction();
          setTimeout(() => {
            this.voteCancel01 = false;
          }, 2000);
        }
      });
    },
    // 视频
    videoPlay(idx, videoUrl, videoPic) {
      this.videoShow = true;
      this.menbanShow = true;
      // this.playerOptions.poster = videoUrl + "?x-oss-process=video/snapshot,t_10000,m_fast";
      this.playerOptions.poster = videoPic;
      this.playerOptions.sources[0].src = videoUrl;
    },
    closeBtn() {
      this.playerOptions.poster = "";
      this.videoShow = false;
      this.menbanShow = false;
    },
    // 鼠标移入
    mouseOver() {
      this.cause = true;
    },
    // 鼠标移出
    mouseLeave() {
      this.cause = false;
    },
    b(event) {
      var el = event.currentTarget;
    },
  },
};
</script>
<style scoped></style>
<style>
.vote .test {
  width: 88%;
  /* padding: 0.3rem 0.3rem; */
  padding: 0.3rem 0;
  margin: 0 auto;
}
.vote .test .span {
  padding: 0.2rem 0;
}
.videos {
  width: 47%;
  height: 2rem;
  display: inline-block;
  margin-right: 6%;
}
.videos:nth-child(2n + 1) {
  margin-right: 0;
}
.imgs {
  /* position: absolute; */
  display: flex;
  padding: 0.5rem 0;
  justify-content: space-between;
}
.imgs .imgss {
  width: 1.2rem;
  height: 1.2rem;
}
.icon .iconImg {
  width: 0.25rem;
  height: 0.26rem;
  margin-right: 0.2rem;
}
/* 视频封面 */
.vote .videos .video-cover .coverImg {
  width: 100%;
  height: 100%;
}
.videos .video-cover {
  width: 100%;
  height: 70% !important;
  position: relative;
}
/* 播放按钮 */
.videos .video-cover .video-play {
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.videos .video-cover .video-play .playImg {
  width: 100%;
  height: 100%;
}
/* 视频 */
.videoMenban .video {
  width: 80%;
  height: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
/* 视频进度条 内容按钮 */
.videoMenban .vjs-control-bar .vjs-control {
  width: 2em !important;
}
.menban01 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: black;
  opacity: 0.5;
}
.closeBtn01 {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  z-index: 100;
  top: -0.5rem;
  right: -0.5rem;
  background: url("../assets/img/icon-close.png");
  background-size: cover;
}
.closeBtn01:hover {
  cursor: pointer;
}
/* 溢出 三点 展示 */
.vote .videos .tit-txt01 {
  /* width: 58%; */
  width: 55%;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
}
/* 投票成功 */
.vote-success {
  width: 55%;
  height: 27%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 999; */
}
.vote-success div {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 0.1rem;
}
.vote-success .sucessImg {
  width: 30%;
  height: 32%;
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translateX(-50%);
}
.vote-success p {
  font-size: 0.2rem;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 67%;
  left: 50%;
  transform: translateX(-50%);
}
/* 小手 */
.icon .iconImg01 {
  width: 100%;
  height: 100%;
}
</style>
