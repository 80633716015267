import Vue from 'vue'
import VueRouter from 'vue-router'
import index from "../views/index.vue";
import login from "../views/login.vue";
import register from "../views/register.vue";
import retrievePassword from "../views/retrieve-password.vue";
import matchPhotos from "../views/match-photos.vue"; //赛事回顾--照片列表
import matchVideos from "../views/match-videos.vue"; //赛事回顾--视频列表
import matchReview2021 from "../views/match-review-2021.vue";
// import matchReview2020 from "../views/match-review-2020.vue";
import matchReviewDetail from "../views/match-review-detail.vue";
import matchSign from "../views/match-sign.vue";
import matchSignDetail from "../views/match-sign-detail.vue";
import jinjiangCitySignDetail from "../views/jinjiangCitySignDetail.vue";
import news from "../views/news.vue";
import newsDetails from "../views/news-details.vue";
import personal from "../views/personal.vue";
import reviewResults from "../views/review-results.vue";
import sign from "../views/sign.vue"; //线上
import signOffline from "../views/sign_offline.vue"; //线下
import signSingle from '../views/sign_single.vue';
import signInfo from "../views/sign-info.vue";
import submitWork from "../views/submit-work.vue";
import account from "../views/account.vue";
import down from "../views/down.vue";
import faq from "../views/faq.vue";
import matchVideo from "../views/match-video.vue";
import agreement from "../views/agreement.vue";
import privacy from "../views/privacy.vue";
import agreement_en from '../views/agreement/agreement_en.vue'
import policy_en from '../views/agreement/policy_en.vue'
import vote from "../views/vote.vue";
import eventIntroduction from "../views/event-introduction.vue"; //赛事介绍
import achievement from "../views/achievement.vue" // 比赛成绩
import achievementDetail from "../views/achievementDetail.vue";

Vue.use(VueRouter)
const routes = [{
        path: "/",
        name: "index",
        component: index,
    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    // 赛事介绍
    {
        path: "/eventIntroduction",
        name: "eventIntroduction",
        component: eventIntroduction
    },
    {
        path: "/register",
        name: "register",
        component: register
    },
    {
        path: "/retrievePassword",
        name: "retrievePassword",
        component: retrievePassword
    },
    // 赛事回顾--照片列表
    {
        path: "/matchPhotos",
        name: "matchPhotos",
        component: matchPhotos
    },
    // 赛事回顾--视频列表
    {
        path: "/matchVideos",
        name: "matchVideos",
        component: matchVideos
    },
    // 赛事回顾 2021
    {
        path: "/matchReview",
        name: "matchReview2022",
        // path: "/matchReviewO",
        // name: "matchReviewO",
        component: matchReview2021
    },
    // 赛事回顾 2020
    // {
    //   path: "/matchReview2020",
    //   name: "matchReview2020",
    //   // path: "/matchReview0",
    //   // name: "matchReview0",
    //   component: matchReview2020
    // },
    {
        path: "/matchReviewDetail",
        name: "matchReviewDetail",
        component: matchReviewDetail
    },
    {
        path: "/matchSign",
        name: "matchSign",
        component: matchSign,

    },
    {
        path: "/matchSignDetail",
        name: "matchSignDetail",
        component: matchSignDetail,
    },
    {
        path: "/jinjiangcitySignDetail",
        name: "jinjiangcitySignDetail",
        component: jinjiangCitySignDetail,
    },
    {
        path: "/news",
        name: "news",
        component: news
    },
    {
        path: "/news-details",
        name: "newsDetails",
        component: newsDetails
    },
    {
        path: "/personal",
        name: "personal",
        component: personal,
        // children: [
        //   {
        //     path: "submitWork",
        //     name: "submitWork",
        //     component: submitWork
        //   }
        // ]
    },
    {
        path: "/reviewResults",
        name: "reviewResults",
        component: reviewResults
    },
    {
        path: "/sign",
        name: "sign",
        component: sign
    },
    // 线下
    {
        path: "/signOffline",
        name: "signOffline",
        component: signOffline
    },
    {
        path: "/signSingle",
        name: "signSingle",
        component: signSingle
    },
    {
        path: "/signInfo",
        name: "signInfo",
        component: signInfo
    },
    {
        path: "/submitWork",
        name: "submitWork",
        component: submitWork
    },
    {
        path: "/account",
        name: "account",
        component: account
    },
    {
        path: "/down",
        name: "down",
        component: down
    },
    {
        path: "/faq",
        name: "faq",
        component: faq
    },
    {
        path: "/matchVideo",
        name: "matchVideo",
        component: matchVideo
    },
    {
        path: "/agreement",
        name: "agreement",
        component: agreement
    },
    {
        path: "/privacy",
        name: "privacy",
        component: privacy
    },
    {
        path: "/agreement_en",
        name: "agreement_en",
        component: agreement_en
    },
    {
        path: "/policy_en",
        name: "policy_en",
        component: policy_en
    },
    {
        path: "/vote",
        name: "vote",
        component: vote,
    },
    {
        path: "/achievement",
        name: "achievement",
        component: achievement,
    },
    {
        path: "/achievement-detail",
        name: "achievementDetail",
        component: achievementDetail,
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;
