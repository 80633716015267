// zh
export default {
  result: {
    student: "so and so student:",
    you: 'where are you',
    mata: "Mata family competition - Adventure from the forest",
    competition: 'won the competition:',
    hard: "I hereby give you a prize and keep working hard!",
    hard02: "We hope to continue our efforts and achieve better results in the future",//新增
    score: 'your score is as follows:',
    expression: "expression",
    reason: "reason for award:",
    save: "save share",
    story:" A vivid little scene story, people almost forget that this is a programming competition! The children take programming as an introduction and learn more about it,After the knowledge of wildlife protection, many interesting handicrafts have been made, and the theme of animals and human friends has been deeply engraved in the world.In my heart. Such learning experience can not be experienced in any classroom."

  },
};