import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import JSEncrypt from 'jsencrypt';
import VueBus from 'vue-bus';
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import i18n from './i18n/i18n' // 引入i18n插件
import vueCountryRegionSelect from 'vue-country-region-select'
import VerifySlider from 'vue-verify-slider'

Vue.use(VueVideoPlayer);
Vue.prototype.$video = Video
Vue.use(ElementUI);
Vue.use(VueBus);
Vue.use(vueCountryRegionSelect)
Vue.use(VerifySlider)
Vue.config.productionTip = false
Vue.prototype.$getRsaCode = function(data) { // 注册方法
        var publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQChAEgKDWXk03NX1IrBvD7fye8yHOADydpwUF4BDT14kMXltIfrf4XQfJhnHsWG3ZMKWR/cIYlFrXxATVLdLiLVFq3+buJPZE9o3KhS4P+sQyjQ5pPLMnbvT9zRYvz2Vftwt4Iu6IVpcdacdxyyq/XgYKqQuhmxIVdB/bx9egGxNQIDAQAB";
        let encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey);
        return encryptor.encrypt(data);
    }
    // 导航守卫(登录后返回到登陆前页面)
router.beforeEach((to, from, next) => {
    if (to.path == "/login") {
        localStorage.setItem("preRoute", router.currentRoute.fullPath)
    }
    next()
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')