import request from '../utils/request';
// 赛事回顾
export const history = query => {
    return request({
        url: 'v1/material/history',//接口地址
        method: 'get',
        params: query
    });
};
// 赛事回顾详情
export const getHistory = query => {
    return request({
        url: 'v1/material/getHistory',//接口地址
        method: 'get',
        params: query
    });
};