<template>
  <div class="login">
    <navigation />
    <!-- <div class="h77"></div> -->
    <div class="bg1 minH flex f-ac f-jc">
      <div class="register">
        <div class="logo-yellow"></div>
        <div class="register-cont">
          <emailLogin
            v-if="lang == 'en-CN'"
            @updateEmail="getUpdateEmail"
            @updatePasswords="getUpdatePasswords"
          />
          <!-- 手机号+验证码&&密码 -->
          <phonelLogin
            v-if="lang == 'zh-CN'"
            :iszhangHaoPSD="zhangHaoPSD"
            @updatePhone="getUpdatePhone"
            @updatecredentials="getdatecredentials"
            @updatePasswords="getUpdatePasswords"
          />
          <!-- <div class="btn-register flex f-ac f-jc mt30" @click="logins()"> -->
          <div class="btn-register flex f-ac f-jc mt30" @click="logins()">
            {{ $t("loginPage.login") }}
          </div>
          <div class="mt30 flex f-jb pl30 pr30">
            <a class="f16" @click="changeCod()" v-if="lang == 'zh-CN'">{{
              zhangHaoPSD ? $t("loginPage.loginphone") : $t("loginPage.acount")
            }}</a>
            <a class="f16" v-if="lang == 'en-CN'">{{
              $t("loginPage.email_login")
            }}</a>
            <a class="f16" @click="passwordFun()" v-if="lang == 'zh-CN'">{{
              $t("loginPage.forget")
            }}</a>
            <a class="f16 orange" @click="push()">{{
              $t("loginPage.registernow")
            }}</a>
          </div>
          <div class="alerts" v-show="alerts">
            <div class="alert">
              <img src="../assets/img/rg1.png" alt="" />
              <div>{{ $t("loginPage.loginsucceeded") }}</div>
            </div>
          </div>
          <div class="aler" v-show="defeated">
            <div class="ale">
              <img src="../assets/img/rg2.png" alt="" />
              <div>{{ $t("loginPage.account_register") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tipsError :errorText="errorText01" v-show="errorShow01"></tipsError>
    <tipsSuccess
      :successText="successText01"
      v-show="successShow01"
    ></tipsSuccess>
    <footers />
  </div>
</template>
<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { login, loginByEmail } from "../api/login";
import { encryptedData } from "../utils/rsa";
import emailLogin from "../components/emailLogin";
import phonelLogin from "../components/phoneLogin";
import tipsError from "../components/tips-error.vue"; //失败提示弹框
import tipsSuccess from "../components/tips-success.vue"; //成功提示弹框
import JSEncrypt from "jsencrypt";
// import { code, login } from "../api/login";
export default {
  data() {
    return {
      successShow01: false,
      successText01: "",
      errorText01: "",
      errorShow01: false,
      msg: this.$t("loginPage.phone"),
      code: this.$t("loginPage.verification"),
      pwd: this.$t("loginPage.pwd"),
      defeated: false,
      zhangHaoPSD: false,
      alerts: false,
      show: true,
      count: "",
      timer: null,
      pass: false, //账号登录
      mobile: "",
      passwords: "",
      credentials: "",
      seen: null,
      lang: "zh-CN",
      email: "",
      emailPwd: "",
      password: "",
      passwords: "",
      red: false,
      currPath: null,
      accountType: 0,
    };
  },
  components: {
    navigation,
    footers,
    tipsError,
    emailLogin,
    phonelLogin,
    tipsSuccess,
  },
  watch: {
    getLang(lang) {
      localStorage.getItem("lang", lang);
      this.lang = lang;
    },
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
  },
  created() {
    this.lang = this.$store.state.lang;
    document.documentElement.scrollTop = 0; //跳回顶部
    // 取出存储的登录前的路由
    this.currPath = localStorage.getItem("preRoute");
  },
  methods: {
    changeCod() {
      this.pass = !this.pass;
      this.zhangHaoPSD = !this.zhangHaoPSD;
    },
    admin() {
      this.pass = true;
      this.zhanghao = true;
    },
    phone() {
      this.zhanghao = false;
      this.pass = false;
    },
    //登录
    logins() {
      if (this.lang == "zh-CN") {
        // 如果手机号为空 return
        if (this.mobile == "") {
          this.$message.error("请输入手机号");
          return;
        }
        // 如果手机号不合法 return
        if (this.red) {
          this.$message.error("请输入正确的手机号");
          return;
        }
        // 如果时账号密码登录  密码不合法 return
        if (this.pass) {
          if (this.passwords == "") {
            this.$message.error("请输入密码");
            return;
          }
        } else if (!this.pass) {
          // 如果是验证码登录
          if (this.credentials == "") {
            this.$message.error("请输入验证码");
            return;
          }
        }
        // 账号密码模式
        if (this.zhangHaoPSD) {
          let data = {
            mobile: this.mobile,
            credentials: encryptedData(this.passwords),
            type: 0,
          };
          login(data)
            .then((res) => {
              if (res.code === 200) {
                this.alerts = true;
                if (this.alerts) {
                  setTimeout(() => {
                    this.alerts = false;
                    // 如果是在报名页面跳回登录的,登录后回到报名页面
                    this.$router.push("/sign");
                    let path = localStorage.getItem("matchSignDetail");
                    let tmpPath = localStorage.getItem("jinjiangcitySignDetail");
                    if(tmpPath){
                      this.$router.push("/signSingle");
                    }
                    else if (path) {
                      this.$router.push("/matchSignDetail");
                    } else if (this.currPath == "/retrievePassword") {
                      this.$router.push("/");
                    } else if (this.currPath == "/register") {
                      this.$router.push("/");
                    } else {
                      // 重定向到登录前的页面
                      this.$router.push(this.currPath);
                    }
                  }, 2000);
                }
                localStorage.setItem("token", res.data.accessToken);
              }
            })
            .catch((error) => {
              const { data } = error.response;
              if (data.code == "3003") {
                console.log("wandadadadadad");
                this.errorText01 = data.msg;
                this.errorShow01 = true;
                setTimeout(() => {
                  this.errorShow01 = false;
                }, 2000);
              } else if (data.code == "2002") {
                localStorage.removeItem("token"), (this.defeated = true);
                if (this.defeated) {
                  setTimeout(() => {
                    this.defeated = false;
                    this.$router.push("/register");
                  }, 2000);
                }
              } else if (data.code == "3004") {
                this.errorText01 = data.msg;
                this.errorShow01 = true;
                setTimeout(() => {
                  this.errorShow01 = false;
                }, 2000);
              }
            });
        } else if (this.credentials) {
          let data = {
            accountType: this.accountType,
            mobile: this.mobile,
            credentials: this.credentials,
            type: 1,
          };
          login(data)
            .then((res) => {
              if (res.code === 200) {
                this.alerts = true;
                if (this.alerts) {
                  setTimeout(() => {
                    this.alerts = false;
                    // 如果是在报名页面跳回登录的,登录后回到报名页面
                    let path = localStorage.getItem("matchSignDetail");
                    if (path) {
                      this.$router.push("/matchSignDetail");
                    } else if (this.currPath == "/retrievePassword") {
                      this.$router.push("/");
                    } else if (this.currPath == "/register") {
                      this.$router.push("/");
                    } else {
                      // 重定向到登录前的页面
                      this.$router.push(this.currPath);
                    }
                  }, 2000);
                }
                localStorage.setItem("token", res.data.accessToken);
              }
            })
            .catch((error) => {
              if (!error || !error.response) return console.log(error);
              const { data } = error.response;
              if (data.code == "3006") {
                this.errorText01 = data.msg;
                this.errorShow01 = true;
                setTimeout(() => {
                  this.errorShow01 = false;
                }, 2000);
              } else if (data.code == "2002") {
                localStorage.removeItem("token"), (this.defeated = true);
                if (this.defeated) {
                  setTimeout(() => {
                    this.defeated = false;
                    this.$router.push("/register");
                  }, 2000);
                }
              }
            });
        }
      } else {
        // 英文环境下
        if (this.email == "") {
          this.$message.error("请输入邮箱账号");
          return;
        }
        if (this.passwords == "") {
          this.$message.error("请输入邮箱密码");
          return;
        }
        let data = {
          email: this.email,
          password: encryptedData(this.passwords),
        };
        loginByEmail(data)
          .then((res) => {
            if (res.code == 200) {
              this.successText01 = res.msg;
              this.successShow01 = true;
              setTimeout(() => {
                this.susuccessShow01 = false;
                // 如果是在报名页面跳回登录的,登录后回到报名页面
                let path = localStorage.getItem("matchSignDetail");
                if (path) {
                  this.$router.push("/matchSignDetail");
                } else if (this.currPath == "/retrievePassword") {
                  this.$router.push("/");
                } else if (this.currPath == "/register") {
                  this.$router.push("/");
                } else {
                  // 重定向到登录前的页面
                  this.$router.push(this.currPath);
                }
              }, 2000);

              localStorage.setItem("token", res.data.accessToken);
            }
          })
          .catch((error) => {
            if (!error || !error.response) return;
            const { data } = error.response;
            // 密码错误
            if (data.code == "3003") {
              this.errorText01 = data.msg;
              this.errorShow01 = true;
              setTimeout(() => {
                this.errorShow01 = false;
              }, 2000);
            } else if (data.code == "2002") {
              localStorage.removeItem("token"), (this.defeated = true);
              if (this.defeated) {
                setTimeout(() => {
                  this.defeated = false;
                  this.$router.push("/register");
                }, 2000);
              }
            }
          });
      }
    },
    push() {
      this.$router.push("/register");
    },
    passwordFun() {
      this.$router.push("/retrievePassword");
    },
    // 子组件传递的值
    getUpdatePhone(val) {
      this.mobile = val;
    },
    getUpdatePasswords(val) {
      this.passwords = val;
    },
    getUpdateEmail(val) {
      this.email = val;
    },
    getdatecredentials(val) {
      this.credentials = val;
    },
  },
};
</script>

<style>
.f16:hover {
  cursor: pointer;
}
</style>
