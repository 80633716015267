<template>
  <div class="registers">
    <navigation />
    <!-- <div class="h77"></div> -->
    <div class="bg1 minH flex f-ac f-jc">
      <div class="register">
        <div class="logo-yellow" style="margin-bottom: 0.6rem"></div>
        <div class="register-cont">
          <registerByEma v-if="lang == 'en-CN'" :show="show" @updateEmail="getUpdateEmail"
            @updatePasswords="getUpdatePasswords" @updatecredentials="getdatecredentials" />
          <registerByphoner v-if="lang == 'zh-CN'" :show="show" @updatePhone="getUpdatePhone"
            @updatePasswords="getUpdatePasswords" @updatecredentials="getdatecredentials" />
          <div class="item-tips">
            {{ $t("loginPage.tips") }}
          </div>
          <div class="flex label-list f-jb">
            <div class="label-item">
              <input v-model="accountType" value="0" class="magic-radio" type="radio" name="radio1" id="r1-1" />
              <label for="r1-1">{{ $t("loginPage.family") }}</label>
            </div>
            <div class="label-item">
              <input v-model="accountType" value="1" class="magic-radio" type="radio" name="radio1" id="r1-2" />
              <label for="r1-2">{{ $t("loginPage.institution") }}</label>
            </div>
            <div class="label-item">
              <input v-model="accountType" value="2" class="magic-radio" type="radio" name="radio1" id="r1-3" />
              <label for="r1-3">{{ $t("loginPage.kindergarten") }}</label>
            </div>
            <div class="label-item">
              <input v-model="accountType" value="3" class="magic-radio" type="radio" name="radio1" id="r1-4" />
              <label for="r1-4">{{ $t("loginPage.primary") }}</label>
            </div>
          </div>

          <div class="flex label-list2" :class="lang == 'en-CN' ? 'label-list2' : 'label-list2zh'">
            <div class="label-item" style="line-height:15px margin-bottom:10px just-content:center">
              <input :class="lang == 'en-CN'
            ? 'magic-checkbox'
            : 'magic-checkboxzh,magic-checkboxzh'
            " type="checkbox" name="checkbox1" id="c1-1" />
              <label for="c1-1" style="position: relative" @click="read()">
                <i class="read"></i>
                {{ $t("loginPage.read") }}&nbsp;<a class="orange" @click.prevent.stop="agreement()">{{
            $t("loginPage.matataworld") }}
                </a>{{ $t("loginPage.and")
                }}<a class="orange" @click.prevent.stop="privacy()">{{
            $t("loginPage.policy")
          }}</a></label>
            </div>
          </div>
          <div class="btn-register flex f-ac f-jc mt20" @click="registers()">
            {{ $t("loginPage.register") }}
          </div>
          <div class="alerts" v-show="alerts">
            <div class="alert">
              <img src="../assets/img/rg1.png" alt="" />
              <div>{{ $t("loginPage.success_regist") }}</div>
            </div>
          </div>
          <div class="aler" v-show="defeated">
            <div class="ale">
              <img src="../assets/img/rg2.png" alt="" />
              <div>{{ $t("loginPage.account_login") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tipsError :errorText="errorText01" v-show="errorShow01"></tipsError>
    <tipsSuccess :successText="successText01" v-show="successShow01"></tipsSuccess>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import tipsError from "../components/tips-error.vue"; //失败提示弹框
import tipsSuccess from "../components/tips-success.vue"; //成功提示弹框
import registerByEma from "../components/emailRegister.vue";
import registerByphoner from "../components/phoneRegister.vue";
import { register, registerByEmail } from "../api/login";
import { encryptedData } from "../utils/rsa";
export default {
  data() {
    return {
      registerphone: this.$t("loginPage.registerphone"),
      successShow01: false,
      successText01: "",
      errorText01: "",
      errorShow01: false,
      defeated: false,
      alerts: false,
      show: true,
      count: "",
      timer: null,
      mobile: "", //手机号
      credentials: "", //验证码
      password: "", //密码
      accountType: "",
      seen: "",
      lang: "",
      email: "",
      passwords: "",
      red: false,
      flagFead: false,
      redPWD: false,
      lang_l: "",
      zhangHaoPSD: false,
    };
  },
  components: {
    navigation,
    footers,
    tipsError,
    registerByEma,
    registerByphoner,
    tipsSuccess,
  },
  watch: {
    getLang(val) {
      localStorage.setItem("val", val);
      this.lang = val;
    },
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
  },
  created() {
    this.lang = this.$store.state.lang;
  },
  methods: {
    // 已阅读
    read() {
      if (this.flagFead) {
        this.flagFead = false;
      } else {
        this.flagFead = true;
      }
    },
    // 密码校验  做限制6-16位英文加数字
    passwordChexk() {
      var rePwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      var pwd = this.password;
      if (rePwd.test(pwd)) {
        this.redPWD = false;
      } else {
        this.redPWD = true;
      }
    },
    // 注册
    registers() {
      if (this.lang == "zh-CN") {
        // 中文下 手机验证码
        if (this.mobile == "") {
          this.$message.error("请输入手机号");
          return;
        }
        // 如果手机号不合法 return
        if (this.red) {
          this.$message.error("请输入正确的手机号");
          return;
        }
        // 如果验证码为空 return
        if (this.credentials == "") {
          this.$message.error("请输入验证码");
          return;
        }
        // 密码不合法 return
        if (this.passwords == "") {
          this.$message.error("请输入6-16位英文加数字密码");
          return;
        }
        // 必须勾选类型
        if (this.accountType == "") {
          this.$message.error("请选择用户类型");
          return;
        }
        // 必须勾选已阅读
        if (!this.flagFead) {
          this.$message.error("请勾选我已阅读");
        }
        let data = {
          accountType: this.accountType,
          mobile: this.mobile,
          credentials: encryptedData(this.passwords),
          type: "0",
        };
        register(data)
          .then((res) => {
            if (res.code == 200) {
              this.alerts = true;
              if (this.alerts) {
                setTimeout(() => {
                  this.alerts = false;
                  this.$router.push("/login");
                }, 2000);
              }
            }
          })
          .catch((error) => {
            const { data } = error.response;
            if (data.code == "2001") {
              this.errorText01 = data.msg;
              this.errorShow01 = true;
              setTimeout(() => {
                this.errorShow01 = false;
                this.$router.push("/login");
              }, 2000);
            }
          });
        if (this.credentials) {
          let data = {
            accountType: this.accountType, //账号类型
            mobile: this.mobile,
            credentials: this.credentials,
            type: "1",
          };
          register(data)
            .then((res) => {
              debugger;
              if (res.code == 200) {
                this.alerts = true;
                if (this.alerts) {
                  setTimeout(() => {
                    this.alerts = false;
                    this.$router.push("/login");
                  }, 2000);
                }
              }
            })
            .catch((error) => {
              const { data } = error.response;
              if (data.code == "3003") {
                this.errorText01 = data.msg;
                this.errorShow01 = true;
                setTimeout(() => {
                  this.errorShow01 = false;
                }, 2000);
              } else if (data.code == "3006") {
                this.errorText01 = data.msg;
                this.errorShow01 = true;
                setTimeout(() => {
                  this.errorShow01 = false;
                }, 2000);
              } else if (data.code == "2001") {
                this.errorText01 = data.msg;
                this.errorShow01 = true;
                setTimeout(() => {
                  this.errorShow01 = false;
                  this.$router.push("/login");
                }, 2000);
              }
            });
        }
      } else {
        // 如果验证码为空 return
        if (this.email == "") {
          this.$message.error("Please enter email account");
          return;
        }
        if (this.credentials == "") {
          this.$message.error("Please enter the verification code");
          return;
        }
        // 密码不合法 return
        if (this.passwords == "") {
          this.$message.error("Please input a password");
          return;
        }
        // 用户类型
        if (this.accountType == "") {
          this.$message.error("Select user type");
          return;
        }
        // 必须勾选已阅读
        if (!this.flagFead) {
          this.$message.error("Please check I have read");
          return;
        }
        // 英文下 邮箱 + 密码 + 验证码
        let data = {
          email: this.email,
          code: this.credentials,
          type: 1,
          password: encryptedData(this.passwords),
        };
        registerByEmail(data)
          .then((res) => {
            if (res.code == 200) {
              this.successText01 = res.msg;
              this.successShow01 = true;
              setTimeout(() => {
                this.errorShow01 = false;
                this.$router.push("/login");
              }, 3000);
            } else {
              console.log(res.msg);
              this.errorText01 = res.msg;
              this.errorShow01 = true;
              setTimeout(() => {
                this.errorShow01 = false;
              }, 2000);
            }
          })
          .catch((error) => {
            if (!error || !error.response) return;
            const { data } = error.response;
            if (data.code == "2001") {
              this.errorText01 = data.msg;
              this.errorShow01 = true;
              setTimeout(() => {
                this.$router.push("/login");
                this.errorShow01 = false;
              }, 2000);
            } else if (data.code == "1002") {
              this.errorText01 = data.data[0];
              this.errorShow01 = true;
              setTimeout(() => {
                this.errorShow01 = false;
              }, 2000);
            } else {
              this.errorText01 = data.data;
              this.errorShow01 = true;
              setTimeout(() => {
                this.errorShow01 = false;
              }, 2000);
            }
          });
      }
    },
    agreement() {
      const isflag = localStorage.getItem("lang");
      // if (isflag == "zh") {
      //   this.$router.push("/agreement");
      // } else {
      //   this.$router.push("/agreement_en");
      // }
      console.log(isflag);
      const routeName = isflag == "zh-CN" ? 'agreement' : 'agreement_en';
      // 使用router.resolve来得到Vue路由的完整路径
      const routePath = this.$router.resolve({ name: routeName }).href;
      // 打开一个新的标签页
      window.open(routePath, '_blank');
    },
    privacy() {
      const isflag = localStorage.getItem("lang");
      // if (isflag == "zh") {
      //   this.$router.push("/privacy");
      // } else {
      //   this.$router.push("/prlicy_en");
      // }
      const routeName = isflag == "zh-CN" ? 'privacy' : 'policy_en';
      const routePath = this.$router.resolve({ name: routeName }).href;
      window.open(routePath, '_blank');
    },
    // 子组件传递的值
    getUpdatePhone(val) {
      this.mobile = val;
    },
    getUpdatePasswords(val) {
      this.passwords = val;
    },
    getUpdateEmail(val) {
      this.email = val;
    },
    getdatecredentials(val) {
      this.credentials = val;
    },
  },
};
</script>

<style scoped>
.label-list2 label a {
  margin: 3px 0;
  display: inline-block;
}

.label-list2zh label a {
  margin: 3px 0;
  display: inline-block;
}

.magic-checkbox+label:before {
  border-radius: 3px;
  margin-bottom: -17px;
  margin-top: -0.2rem;
}

.magic-checkboxzh+label:before {
  border-radius: 3px;
  margin-bottom: -17px;
  margin-top: -0.2rem;
}

.read {
  width: 0.15rem;
  height: 0.15rem;
  background-color: pink;
  position: absolute;
  top: 0;
  left: -0.27rem;
  opacity: 0;
}
</style>
