import request from '../utils/request';
// 资料下载
export const download = query => {
    return request({
        url: 'v1/material/data',//接口地址
        method: 'get',
        params: query
    });
};
// banner图
export const getBanner = query => {
    return request({
        url: 'v1/material/getBanner',//接口地址
        method: 'get',
        params: query
    });
};