
import evenIntroduction_en from "./event-introduction/index_en";
import account_en from "./account/index_en";
import personal_en from "./personal/index_en";
import faq_en from "./faq/index_en";
import matchsign_en from "./match-sign/index_en";
import review_2020_2021_en from "./review-2020-2021/index_en";
import newsdetails_en from "./news-details/index_en";
import signoffline_en from "./sign-offline/index_en";
import vote_en from "./vote/index_en";
import submitwork_en from "./submit-work/index_en";
import signinfo_en from "./sign-info/index_en";
import reviewresults_en from "./review-results/index_en";
import matchvideo_en from "./match-video/index_en";
import index_en from "./index/index_en";
import retrieverpassword_en from "./retriever-password/index_en";
export default {
  ...evenIntroduction_en,
  ...account_en,
  ...personal_en,
  ...matchsign_en,
  ...faq_en,
  ...review_2020_2021_en,
  ...newsdetails_en,
  ...signoffline_en,
  ...vote_en,
  ...submitwork_en,
  ...signinfo_en,
  ...reviewresults_en,
  ...matchvideo_en,
  ...index_en,
  ...retrieverpassword_en,
  //所有侧导航的name
  loginPage: {
    login: 'Sign In',
    loginout: 'Sign Out',
    loginsucceeded: 'Login succeeded',
    username: 'Enter your username',
    password: 'Enter your password',
    registernow: 'Register Now',
    forget: 'Forgot Password？',
    getverification: 'Verification Code',
    // register: 'Create new account',
    acount: 'Account login',
    phone: 'Please enter your mobile phone number',
    code: 'Verification Code',
    m: 's',
    m_success: 'Modified successfully',
    pwd: 'Please input a password',
    loginphone: 'Mobile login',
    Retrievepwd: 'Retrieve password',
    verification: 'Please enter the verification code',
    newpwd: 'Please enter a new password',
    againpwd: 'Enter the new password again',
    submit: 'Submit',
    registerphone: 'Please enter your registered mobile number',
    family: 'Family',
    institution: 'Institution',
    kindergarten: ' Kindergarten',
    primary: 'Primary school',
    tips: 'The password consists of 6-16 digits, letters or symbols and contains at least two characters',
    read: ' I have read the',
    and: 'and',
    matataworld: '《MatataWorld user agreement》',
    policy: '《Privacy policy》',
    register: 'Register',
    success_regist: 'Successfully registered',
    account_login: 'Account already exists, please return to login',
    account_register: 'Account does not exist, please register first',
    logout_sucess: 'Logout successfully',
    email: ' Please enter email account',
    email_login: 'Mailbox login',
  },
  //所有表单的的placehoolder
  //首页
  homePage: {
    more: 'See more',
    mata_program: 'Mata robot programming competition',
    events_2023: 'MWRC 2024',
    cooper_institutions: 'International Judge',
  },
  // navigation
  navigation: {
    event_introduction: 'Overview',
    event_registration: 'Competition',
    event_information: 'Information',
    event_review: 'Review',
    personal_center: 'My Profile',
    season_introduction_21: 'MWRC 2021',
    event_consultation: 'Announcements',
    data_download: 'Download',
    common_problem: 'FAQs',
    season_2020: 'MWRC 2020',
    season_2021: 'MWRC 2021',
    season_2022: 'MWRC 2022',
    season_2023: 'MWRC 2023',
  },
  footer: {
    about_matalab: 'About MatataStudio',
    sign_up: 'Registration',
    contact_us: 'Contact Us',
    copyright: 'Copyright © 2024 MATATALAB All Rights Reserved',
    contact: "contact information",
    email: 'Email:mwrc@matatalab.com'
  },
  down: {
    down: 'Data download',
    download: 'download',
    top: 'Topping'
  }
}
