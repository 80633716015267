import axios from "axios";

function returnLang() {
    // 测试国外情况，注释这2行
    var bowerLang = (
        navigator.userLanguage || window.navigator.language
    ).toLowerCase();
    if (bowerLang == "zh-cn") return "zh-CN";
    return "en-US";
}

const $axios = axios.create({
    baseURL: "/api/",
    // baseURL: process.env.NODE_ENV === "development" ? "/api" : process.env.VUE_APP_BASE_URL,
    // baseURL: 'https://prod-api.matatalab.com/',
    // https://dev-api.matatalab.com/user/login
    // process.env.VUE_APP_BASE_API,
    timeout: 10000,
});
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
(config) => {
    return config;
},
    (error) => {
        return Promise.reject();
    };
$axios.interceptors.request.use(
    (config) => {
        // 可以设置 加载的动画效果 的展示
        // 在必要的路由设置一些额外的参数 ---- token信息携带放在此处
        // 将token放到请求头中发送给服务器
        const token = localStorage.getItem("token");
        if (token) {
            // 请求头携带token
            config.headers.Authorization = token;
            // config.headers.token = token
        }
        config.headers.Accept = "application/json";
        let currentLang = localStorage.getItem("lang") || returnLang() || "zh-CN";
        // let currentLang = localStorage.getItem('lang')
        if (currentLang === "en") {
            config.headers["Accept-Language"] = "en-US";
        } else {
            config.headers["Accept-Language"] = "zh-CN";
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 响应拦截器
$axios.interceptors.response.use(
    (response) => {
        if (response.status < 300) {
            return response.data;
        } else {
            // Promise.reject(response);
        }
    },
    (error) => {
        if (error.response.status === 401) {
            setTimeout(() => {
                // this.$router.push("/login");
                // 取出当前页面路由，如果是报名页跳转回报名详情，个人中心跳转回登录
                if (
                    this.routePath == "/personal" ||
                    this.routePath == "/account" ||
                    this.routePath == "/submitWork"
                ) {
                    this.$router.push("/login");
                } else if (
                    this.routePath == "/sign" ||
                    this.routePath == "/signOffline"
                ) {
                    this.$router.push("/login");
                } else {
                    // 登录和退出的按钮显示和隐藏
                }
            }, 2000);
            localStorage.removeItem("token");
            localStorage.removeItem("signData");
            localStorage.removeItem("getCompetitionId");
            localStorage.removeItem("matchSignDetail");
            localStorage.removeItem("matchReviewDetailId");
        }
        const {data} = error.response;
        return Promise.reject(error);
    }
);
let service = (options) => {
    let currentLang = localStorage.getItem("lang") || returnLang() || "zh-CN";
    let url =
        options.url +
        (options.url.includes("?") ? "&" : "?") +
        "lang=" +
        currentLang;
    return $axios({
        ...options,
        url,
    });
};

export default service;

const GET = (url, prams) =>
    service({
        url,
        method: "get",
        params: prams,
    });

const POST = (url, prams) =>
    service({
        url,
        method: "POST",
        data: prams,
    });
export {POST, GET};
