<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <img :src="getBannerImgUrl()" class="img" />
      </div>
    </div>
    <div class="page-personal">
      <div class="wrap flex f-jb">
        <div class="personal-left">
          <div class="personal-nav">
            <a class="item flex f-ac f-jc" @click="mine()">
              <span class="s1"></span>{{ $t("acount.event") }}
            </a>
            <a class="item active flex f-ac f-jc">
              <span class="s2"></span>{{ $t("acount.manage") }}
            </a>
          </div>
        </div>
        <div class="personal-right" v-show="accounts">
          <div class="pl80">
            <div class="right-title">
              <span style="font-size: 0.18rem">{{ $t("acount.type") }}</span>
            </div>
            <div class="account-info pt100">
              <div class="flex item">
                <div class="type">{{ $t("acount.tel") }}</div>
                <div class="flex-item">{{ list.mobile }}</div>
              </div>
              <div class="flex item">
                <div class="type">{{ $t("acount.tel") }}</div>
                <div v-if="list.type == 0" class="flex-item">
                  {{ $t("acount.home") }}
                </div>
                <div v-if="list.type == 1" class="flex-item">
                  {{ $t("acount.institutional") }}
                </div>
                <div v-if="list.type == 2" class="flex-item">
                  {{ $t("acount.kindergarten") }}
                </div>
                <div v-if="list.type == 3" class="flex-item">
                  {{ $t("acount.primary") }}
                </div>
              </div>
              <div class="flex item">
                <div class="type">{{ $t("acount.name") }}</div>
                <div class="flex-item">{{ list.nickname }}</div>
              </div>
              <div v-if="list.type == 0" class="flex item">
                <div class="type">{{ $t("acount.family") }}</div>
                <div class="flex-item">{{ list.address }}</div>
              </div>
              <div v-else-if="list.type == 1" class="flex item">
                <div class="type">{{ $t("acount.org") }}</div>
                <div class="flex-item">{{ list.address }}</div>
              </div>
              <div v-else-if="list.type == 2" class="flex item">
                <div class="type">{{ $t("acount.kinder") }}</div>
                <div class="flex-item">{{ list.address }}</div>
              </div>
              <div v-else-if="list.type == 3" class="flex item">
                <div class="type">{{ $t("acount.pri_sch") }}</div>
                <div class="flex-item">{{ list.address }}</div>
              </div>
              <div class="flex item">
                <div class="type">{{ $t("acount.email") }}</div>
                <div class="flex-item">{{ list.email }}</div>
              </div>
            </div>
            <div class="pt100 pb100">
              <a class="btn-video" @click="edit()">{{ $t("acount.edit") }}</a>
            </div>
          </div>
        </div>
        <div class="personal-right" v-show="edits">
          <div class="pl80">
            <div class="right-title">
              <span style="font-size: 0.18rem">{{ $t("acount.manage") }}</span>
            </div>

            <div class="page-sign">
              <div class="signInput">
                <div class="sginForm">
                  <div class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.tel") }}</div>
                    <!-- 当手机号存在，不可修改 -->
                    <div class="flex-item" v-if="oldMobile != ''">
                      {{ list.mobile }}
                      <span class="gray">{{ $t("acount.tips") }}</span>
                    </div>
                    <!-- 当手机号为空，可修改 -->
                    <div class="flex-item" v-else>
                      <input type="text" name="" placeholder="输入手机号" v-model.trim="newMobile" />
                    </div>
                  </div>
                  <div class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.type") }}</div>
                    <div class="flex-item">
                      <div class="flex label-list f-jb">
                        <div class="label-item">
                          <input class="magic-radio" type="radio" name="radio1" id="r1-1" value="0"
                            v-model="list.type" />
                          <label for="r1-1">{{ $t("acount.home") }}</label>
                        </div>
                        <div class="label-item">
                          <input class="magic-radio" type="radio" name="radio1" id="r1-2" value="1"
                            v-model="list.type" />
                          <label for="r1-2">{{
          $t("acount.institutional")
        }}</label>
                        </div>
                        <div class="label-item">
                          <input class="magic-radio" type="radio" name="radio1" id="r1-3" value="2"
                            v-model="list.type" />
                          <label for="r1-3">{{
          $t("acount.kindergarten")
        }}</label>
                        </div>
                        <div class="label-item">
                          <input class="magic-radio" type="radio" name="radio1" id="r1-4" value="3"
                            v-model="list.type" />
                          <label for="r1-4">{{ $t("acount.primary") }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.name") }}</div>
                    <div class="flex-item">
                      <input type="text" name="" :placeholder="$t('acount.name')" v-model="list.nickname" />
                    </div>
                  </div>
                  <!-- 家庭用户 -->
                  <div v-if="list.type == 0" class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.family") }}</div>
                    <div class="flex-item">
                      <input type="text" name="" :placeholder="$t('acount.ent_fam')" v-model="list.address" />
                    </div>
                  </div>
                  <!-- 机构用户 -->
                  <div v-else-if="list.type == 1" class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.org") }}</div>
                    <div class="flex-item">
                      <input type="text" name="" :placeholder="$t('acount.ent_org')" v-model="list.address" />
                    </div>
                  </div>
                  <!-- 幼儿园 -->
                  <div v-else-if="list.type == 2" class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.kinder") }}</div>
                    <div class="flex-item">
                      <input type="text" name="" :placeholder="$t('acount.ent_kin')" v-model="list.address" />
                    </div>
                  </div>
                  <!-- 小学 -->
                  <div v-else-if="list.type == 3" class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.pri_sch") }}</div>
                    <div class="flex-item">
                      <input type="text" name="" :placeholder="$t('acount.ent_pri')" v-model="list.address" />
                    </div>
                  </div>
                  <!--  -->
                  <div class="flex input-item f-ac">
                    <div class="tit">{{ $t("acount.email") }}</div>
                    <div class="flex-item">
                      <input type="text" name="" :placeholder="$t('acount.ent_email')" v-model="list.email"
                        @blur="email()" />
                      <span style="
                          color: red;
                          line-height: 20px;
                          margin: 3px;
                          white-space: nowrap;
                          font-size: 12px;
                        " v-show="red">{{ $t("acount.sorry") }}
                      </span>
                    </div>
                  </div>
                  <div class="flex input-item">
                    <div class="flex-item flex">
                      <div class="btn-submit" @click="submit()">
                        {{ $t("acount.submit") }}
                      </div>
                      <div class="btn-cancel" @click="cancel()">
                        {{ $t("acount.cancel") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { getUserInfo, message } from "../api/account";
export default {
  data() {
    return {
      list: '',
      oldMobile: "",
      newMobile: "",
      list: {
        email: "",
      },
      accounts: true,//账户信息
      edits: false,//编辑列表
      red: false,
    }
  },
  components: {
    navigation,
    footers,
  },
  created() {
    this.getList();
  },
  methods: {
    // 校验邮箱格式
    email() {
      var re = /^[\w.-]+@[\w.-]+\.\w+$/;
      let str = this.list.email;
      if (re.test(str)) {
        this.red = false;
      } else {
        this.red = true;
      }
      // 如果邮箱号为空，则不出现红字提示
      if (this.list.email == "" || this.list.email == null || !this.list.email) {
        this.red = false;
      }
      console.log(this.list.email);
    },
    mine() {
      this.$router.push("/personal");
    },
    // 点击编辑
    edit() {
      this.edits = true;
      this.accounts = false;
      this.email(); //邮箱格式初始为false
    },
    cancel() {
      this.accounts = true;
      this.edits = false;
      this.getList();
    },
    getList() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.oldMobile = res.data.mobile
          // this.oldMobile = ""
        } else {
        }
      }).catch((err) => {
        // console.log(err)
      });
    },
    submit() {
      if (this.red) {
        this.$message.error("请输入正确的邮箱格式！");
        return;
      };
      // console.log(this.list);
      // 如果手机号可输入
      if (this.oldMobile == "") {
        if (this.newMobile == "") {
          this.$message.error("请输入所有用户信息,再提交!");
          return;
        }
      }
      if (this.list.nickname == " " || this.list.address == " " || this.list.email == " " || this.list.nickname == "" || this.list.address == "" || this.list.email == "" || this.list.nickname == null || this.list.address == null || this.list.email == null || !this.list.nickname || !this.list.address || !this.list.email) {
        this.$message.error("请输入所有用户信息,再提交!");
        return;
      }
      let data = {
        address: this.list.address,
        email: this.list.email,
        id: this.list.id,
        mobile: this.newMobile,
        nickname: this.list.nickname,
        type: this.list.type
      }
      if (this.newMobile == "") {
        data.mobile = this.oldMobile
      }
      message(data).then((res) => {
        // console.log(data)
        this.accounts = true
        this.edits = false
        this.getList()
      }).catch(err => {
        this.$router.push("/");
        this.$message.error("身份验证失败，请重新登录！")
      })
    },
    returnLang() {
      var bowerLang = (
        navigator.userLanguage || window.navigator.language
      ).toLowerCase();
      if (bowerLang == "zh-cn") return "zh-CN";
      return "en-US";
    },
    getBannerImgUrl() {
      let currentLang =
        localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang == "zh-CN") {
        return require("../assets/img/personal_CN.png");
      } else {
        return require("../assets/img/personal_EN.png");
      }
    }
  },
};
</script>

<style></style>
