// zh
export default {
  event: {
    about: "关于MWRC玛塔世界机器人大赛",
    mata: "玛塔世界机器人大赛（MatataWorld Robotics Competition，简称MWRC)是一个全球性的儿童机器人赛事平台，旨在通过有趣且富有挑战性的赛事活动，提升孩子的综合能力。2020年，由中国、日本、新加坡、加拿大等国家共同发起并成立了MWRC竞赛委员会，赛事组委会旨在通过举办MWRC玛塔世界机器人竞赛活动，推动全世界的学前儿童STEAM教育的发展，在具有挑战性的比赛中提高儿童的科技自信心，并将这些知识解决实际生活中的问题，探索和创造孩子们的新世界。",
    set: {
      setting: "赛事设置",
      programing: "实物编程",
      online: "线下城市挑战赛",
      offline: "线上挑战赛",
    },
    idea: '赛事理念',
    share: "分享",
    children: " 孩子们通过比赛，将自己的创意成果与全世界小朋友分享。",
    confidence: "自信",
    ihope: "希望孩子享受完成任务时带来带来的喜悦。",
    innovation: "创新",
    innovativec: "创新型赛事，任务式与创意式比赛内容相结合。",
    collaboration: "协作",
    challenges: " 以团队的形式完成任务挑战，通过比赛培养孩子团队意识。",
    develop: '发展历程',
    footer: {
      contact: "联系我们",
      competition: " 参赛咨询：MWRC赛事组委会",
      confidence: "自信",
      tasks: "希望孩子享受完成任务时带来带来的喜悦。",
      address: "地址：深圳市南山区茶光路1004号健兴科技大厦B栋504",
      email: "Email: mwrc@matatalab.com。",
      business: "商务合作：程文鹏",
      tel: "联系电话：13410175588",
      email_cycle: "Email：cycle@matatalab.com",
    }
  },
};