import request from '../utils/request';
// 查询问答
export const question = query => {
    return request({
        url: 'v1/material/question',//接口地址
        method: 'get',
        params: query
    });
};
// banner图
export const getBanner = query => {
    return request({
        url: 'v1/material/getBanner',//接口地址
        method: 'get',
        params: query
    });
};