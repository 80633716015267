<template>
  <div>
    <div class="item flex item-input">
      <input
        type="text"
        :placeholder="$t('loginPage.email')"
        class="flex-item"
        @input="emailInput"
        v-model.trim="emailValue"
      />
    </div>
    <div class="item flex item-input">
      <input
        :type="seen ? 'password' : 'text'"
        :placeholder="$t('loginPage.pwd')"
        class="flex-item"
        @input="pwdInput"
        v-model.trim="passwordsValue"
        autocomplete="new-password"
      />
      <img :src="seen ? openeye : nopeneye" class="eye" @click="seen = !seen" />
    </div>
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
export default {
  name: "EmailLogin",
  props: {
    email: {
      type: String,
      default: "",
      common: "邮箱",
    },
    passwords: {
      type: String,
      default: "",
      common: "密码",
    },
  },
  data() {
    return {
      emailValue: "",
      passwordsValue: "",
      openeye: require("../assets/img/eye-open.png"), //图片地址
      nopeneye: require("../assets/img/eye-close.png"), //图片地址
      seen: true,
    };
  },
  mounted() {},
  methods: {
    emailInput() {
      this.$emit("updateEmail", this.emailValue);
    },
    pwdInput() {
      this.$emit("updatePasswords", this.passwordsValue);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
