// zh
export default {
    person: {
        event: "My Events",
        account: 'Settings',
        select: "Please Select",
        team: "Team Name",
        activity: "Time",
        status: "Current Status",
        operation: "Operation",
        online: 'Online',
        offline: 'Offline',
        select_all: 'Select all',
        to: "to",
        approved: "Under Approval",
        work: "Submit A Work",
        pproved: "Approved",
        review: "Under Review",
        failed: "Audit Failed",
        ended: "Audit Ended",
        no_status: "No Status",
        details: 'Entry Details',
        cancel: "Cancel Registration",
        share_voting: "Share Voting",
        enroll: "Re Enroll",
        results: "Review Results",
        // 查看参赛详情
        welcome: "Welcome to MatataWorld Robotics Competition",
        name: "Name",
        even: "Competition",
        type: "Type",
        home: "Home user",
        institutional: "Institutional user",
        kndergarten: "Kindergarten",
        primary: "Primary school",
        code: "Invitation code",
        team_name: "Team name",
        mata_1: "Mata 1",
        name_f: "Name:",
        gender: "Gender:",
        id_number: "ID number ",
        organization: "Organization name",
        mata_event: " Mata event",
        // 家庭用户
        parent_name: "parent name",
        Ms_Zhang: "Ms. Zhang",
        phone: "parent phone",
        //家庭外其他用户类型
        tel: "Instructor Phone",
        instructor_tel: "Prize mailing address",
        address: "Prize mailing address",
        shenzhen: "Shenzhen Nanshan Science Park 105",
        detailed_add: "Detailed address",
        // 取消弹框
        want_cancel: "Are you sure you want to cancel the registration?",
        prompt: "prompt",
        OK: "OK",
        cancel1: "Cancel",
        cancel_succeed: "cancellation succeeded!",
        scan: 'Scan code to share, let friends help you',
        wait: "the sharing voting time has not started yet, please wait patiently!",
        time_end: "sharing voting time has ended!",
        wait_patiently: "The submission time has not started yet, please wait patiently!",
        submit_end: "Submission time has ended!",
    },
};
