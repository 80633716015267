<template>
    <div>
    <!-- 提示失败 -->
          <div class="alerts-success">
            <div class="alert-success">
              <img src="../assets/img/rg2.png" alt="" />
              <div>{{errorText}}</div>
            </div>
          </div>
    </div>
</template>
<script>
export default {
    props: ["errorText"],
    data(){
        return {
        }
    },
    mounted(){
    },
    methods: {
    },
}
</script>
<style>
</style>