import $ from 'jquery';
$(function() {
	// $(window).scroll(function() {
	// 	var sc = $(window).scrollTop();
	// 	var rwidth = $(window).width()
	// 	if(sc > 80) {
	// 		 $(".header").addClass('fix');
	// 	} else {
	// 		   $(".header").removeClass('fix');
	// 	}
	// })
	
	
	// if($('.index-banner').length > 0){
	// 	var indexbanner = new Swiper('.index-banner .swiper-container', {
	// 				slidesPerView: 'auto',
	// 				loop: true,
	// 				autoplay:true,
	// 				 pagination: {
	// 			        el: '.index-banner .pagination',
	// 			      },
	// 			});
	// }
	if($('.page-sign-detail .matchJudges .judgesInfo ').length > 0){
		var indexbanner = new Swiper('.page-sign-detail .matchJudges .judgesInfo  .swiper-container', {
					slidesPerView: 'auto',
					loop: true,
					autoplay:true,
					 navigation: {
						nextEl: '.page-sign-detail .matchJudges .judgesInfo .btn-next',
						prevEl: '.page-sign-detail .matchJudges .judgesInfo  .btn-prev',
					},
				});
	}
	
	if($('.matchPhoto .swiper-container').length > 0){
		var videoSwiperIndex = 0;
				var videoSwiper = new Swiper('.matchPhoto .swiper-container', {
					watchSlidesProgress: true,
					slidesPerView: 'auto',
					centeredSlides: true,
					
					loop: $('.matchPhoto .swiper-container .swiper-slide').length > 1 ? true : false,
					loopedSlides: 5,
					autoplay: false,
					navigation: {
						nextEl: '.matchPhoto .hotslide-next',
						prevEl: '.matchPhoto .hotslide-prev',
					},
					on: {
						progress: function(progress) {
							for(i = 0; i < this.slides.length; i++) {
								var slide = this.slides.eq(i);
								var slideProgress = this.slides[i].progress;
								modify = 1;
								if(Math.abs(slideProgress) > 1) {
									//modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
								}
								translate = slideProgress * modify * 850 + 'px';
								scale = 1 - Math.abs(slideProgress) / 3;
								zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
								slide.transform('translateX(' + translate + ') scale(' + scale + ')');
								slide.css('zIndex', zIndex);
								slide.css('opacity', 1);
								if(Math.abs(slideProgress) > 3) {
									slide.css('opacity', 0);
								}
							}
						},
						slideChangeTransitionEnd: function() {
							videoSwiperIndex = this.activeIndex

						},
						setTransition: function(transition) {
							for(var i = 0; i < this.slides.length; i++) {
								var slide = this.slides.eq(i)
								slide.transition(transition);
							}

						}
					}

				})
	}
	
	
	if($('.index-video video').length > 0){
		$('.index-video .wrap .img').on('click',function(){
			$(this).fadeOut();
			$(this).parent().find('video')[0].play()
		})
	}
	
	$('.pop-photos .item').on('click',function(e){
		e.preventDefault();
		if($(this).attr('data-url')){
			if($(this).attr('data-type') == 'photo'){
				$('.pop .item').html('<img src="" class="img"/>')
				$('.pop .item img').attr('src',$(this).attr('data-url'))
			}else if($(this).attr('data-type') == 'video'){
				$('.pop .item').html('<video id="video" src="" controls="controls"  playsinline=""  webkit-playsinline="" x5-video-player-type="h5" x-webkit-airplay="allow" /></video>')
				$('.pop .item video').attr('src',$(this).attr('data-url'))
			}
			$('.pop').fadeIn();
		}
	})
	
	$('.pop,.pop .btn-close').on('click',function(){
		$('.pop').fadeOut();
	})
	$('.pop .info').on('click',function(event){
		window.event? window.event.cancelBubble = true : e.stopPropagation();

	})
	
	$('.page-personal-pop,.page-personal-pop .btn-close').on('click',function(){
		$('.page-personal-pop').fadeOut();
	})
	
	$('.btn-personal-detail').on('click',function(){
		$('.page-personal-pop').fadeIn();
	})
	
	
	$('.page-personal-pop .main').on('click',function(event){
		window.event? window.event.cancelBubble = true : e.stopPropagation();

	})
	
	
	$('.btn-eye').on('click',function(){
		if($(this).hasClass('open')){
			$(this).removeClass('open')
			$(this).parent().find('input').attr('type','password')
		}else{
			$(this).addClass('open')
			$(this).parent().find('input').attr('type','txt')
		}
	})
	
	
	$('.page-sign .sginForm .btn-up').on('click',function(event){
		$('.btn-down').show();
		var html = $('.page-sign .sginForm .team-list:first-child').clone()
		html.find('input').val('')
		$('.page-sign .sginForm .team').append(html)
	})
	
	$('.page-sign .sginForm .btn-down').on('click',function(event){
		
		$('.page-sign .sginForm .team-list:last-child').remove()
		if($('.page-sign .sginForm .team-list').length == 1){
			$('.page-sign .sginForm .btn-down').hide()
		}
	})
	
	
	
})