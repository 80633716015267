<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="user-content">
      <span style="font-size: 32px">玛塔创想赛事用户服务协议</span>
      <div>
        欢迎您使用深圳市玛塔创想科技有限公司（以下简称“玛塔创想”）的产品和服务（包括网站 https://www.matatalab.com/ 或其子域名网站、玛塔赛事平台等任一网站或应用程序，以下也称“赛事平台”），为使用玛塔创想赛事平台服务，您应阅读并遵守《玛塔创想赛事用户服务协议》（下称“ 本协议 ”）。
         请您（以下也称“用户”）于使用玛塔赛事平台服务前认真阅读并充分理解本协议的全部内容（特别是免除或者限制责任的条款以及法律适用和争议解决条款，
        该类条款可能以粗体及下划线方式标注以提示您重点注意）。如您不同意本协议任何条款，应当立即停止注册程序并停止使用玛塔创想赛事平台服务；只要您【点击“同意”本协议的按钮】，
        或使用任何玛塔创想赛事平台所提供的服务，即视为您已经全文阅读并充分理解和自愿接受本协议之全部内容，本协议即在您和玛塔创想赛事平台之间正式订立并即时生效，
        形成对双方均具有约束力的法律文件。
      </div>
      <div><strong>一、服务协议的确认和接纳+</strong></div>
      <div>
        1.本协议是用户与玛塔创想之间关于使用玛塔创想产品或服务的协议，不适用于用户与用户之间产生的法律关系或纠纷。
      </div>
      <div>
        2.玛塔创想发布的《玛塔创想用户隐私政策》 、声明以及其他不时发布的平台规则均是本协议的构成部分，与本协议不可分割且具有同等法律效力。
      </div>
      <div>
        3.您确认，您具备与您在玛塔创想赛事平台开展的行为（包括但不限于订立协议、购买平台产品或服务等）相适应的民事行为能力。若
        您为未成年人或存在其他不具备与您行为相适应的民事行为能力的情形，则应获得您的法定监护人的知情同意，并在法定监护人的监护、
        指导下阅读本协议、使用玛塔创想的产品或服务，否则您应立即终止注册或停止使用玛塔创想赛事平台服务；您及您的法定监护人应依照法
        律之规定承担因此而导致的相应责任，且玛塔创想不就此承担任何责任。
      </div>
      <div>
        4.玛塔创想有权根据相关法律法规及政策的变化或业务发展需要不时修订本协议条款（包括《玛塔创想用户隐私政策》以及其他声明或平台
        规则的发布及修订等），该等修订将以平台公告的方式通知用户。如用户不同意修订后协议内容的，应立即终止使用玛塔创想赛事平台；如用户继续使
        用玛塔创想赛事平台，即视为用户接受修订后的协议。
      </div>
      <div><strong> 二、用户的权利和义务</strong></div>
      <div>
        1.用户使用玛塔创想的产品或服务时，可直接登录邮箱使用服务，或者提供个人信息，例如用户名和密码、邮箱、电话号码、第三方账号（微信和QQ）、学号，注册相关账户，享受提供的相应服务。
      </div>
      <div>
        2.用户申请注册账户时，应按照平台要求提供所需信息资料，并保证该等账户信息资料真实有效且符合相关法律法规规定及公序良俗要求 ；相关注册信息发生变更时，用户应及时进行更新，否则相关后果由您自行承担 。为使您更好地使用玛塔创想赛事平台服务、保障您的账户安全，玛塔创想可根据需要及相关法律规定要求您完成实名认证。
      </div>
      <div>
        3.除玛塔创想存在过错情形外，用户对其注册账户项下的一切行为结果（包括但不限于发布信息、购买产品/服务、作品创作等）负完全的责任；
        用户应保证其注册账号作出的行为不违反任何法律法规和公序良俗要求。未经玛塔创想同意，
        用户不得以任何形式擅自转让或授权他人使用自己于玛塔创想的注册账户。
      </div>
      <div>
        4. 用户有义务妥善保管好自己的注册账号、密码及与注册账户绑定的各类账号及密码，并确保您在结束产品使用或服务时以正确的步骤退出登录。如用户未妥善保管相关账号及密
        码而对用户、玛塔创想或其他第三方造成损害的，将由用户承担全部责任。
      </div>
      <div>
        5.如您发现有他人冒用或盗用您的注册账户，或存在任何其他非法使用（包括未经合法授权使用）注册账户之情形，应立即以有效方式通知玛塔创想（可发送邮件至mwrc@matatalab.com）；
        同时，您理解玛塔创想对您的任何请求采取行动均需要合理时间且玛塔创想所采取行动可能无法避免或阻止损害的形成或扩大，除玛塔创想存在法定过错情形外，玛塔创想不因此承担任何责任。
      </div>
      <div>
        6.用户承诺，在使用玛塔创想的产品或服务时实施的所有行为遵守国家法律、法规和平台的相关规定/规则以及各种社会公共利益和公共道德，不扰乱玛塔创想的正常运营秩序，
        不发布或传播违反法律法规、公共利益或可能严重损害平台和他人合法利益的信息。
      </div>
      <div>
        7.用户在未经玛塔创想事先书面同意的情况下，不得对玛塔创想的任何产品和服务内容作商业性利用，包括但不限于以复制、传播等方式使用在平台上展示的任何资料。
      </div>
      <div><strong>三、玛塔创想的权利和义务</strong></div>
      <div>
        1.
        玛塔创想应在现有技术和条件基础上尽最大努力维护玛塔创想赛事平台的正常运行，并努力提升和改进技术，使用户在平台的正常网络活动能够顺利进行。
      </div>
      <div>
        2. 如用户违反相关法律法规的规定、本协议的约定或玛塔创想赛事平台发布的相关规则、声明等内容，玛塔创想有权采取包括但不限于删除相关信息、立即暂停或终止相关服务、
        冻结或注销账户、拉入黑名单、向有关监管部门或国家机关报告等一项或多项处理措施。因此造成玛塔创想或其他第三方损失的，用户还应承担损失赔偿责任。
      </div>
      <div>
        3. 用户理解并同意，玛塔创想赛事平台经营者可能根据平台业务调整发生变更或新增，同时，玛塔创想亦有权将其于本协议项下的权利、义务委托第三方行使、履行；用户于
        本协议项下的权益不会因前述情形受到影响，针对前述玛塔创想赛事平台经营变更或新增的情形，玛塔创想将就有关情况通知用户，用户于此同意与变更后的平台经营者或新增平台经营者共同履行本协议。
      </div>
      <div>
        4.
        鉴于平台服务的特殊性，玛塔创想没有义务对用户的注册信息及资料以及所有的行为进行事先审查，但玛塔创想有权根据不同情况按照相关法律法规规定和/或本协议约定选择保留或删除相关
        信息以及选择继续或停止对该用户提供服务，并向用户追究相关法律责任。
      </div>
      <div>
        5. 肖像权授权许可。用户同意并许可，玛塔创想有权拍摄用户参与玛塔创想主办或合作的线下活动的照片及视频；且对于该等照片或视频载体中的用户肖像权，用户于此授予玛塔创
        想进行合法使用的权利。用户同意，前述许可均为无偿许可。
      </div>
      <div>
        6.
        玛塔创想【经合理判断认为】您的注册账户的存在被非法使用的情形或您的注册账户使用可能危及您的账户安全或玛塔创想赛事平台安全的，有权采取暂停或停止向您的注册账户提供相关服务等救济措施。
      </div>
      <div><strong> 四、服务的开通、支付、变更、中断和终止</strong></div>
      <div>
        1.
        玛塔创想可能通过玛塔创想赛事平台提供部分付费产品/服务，有关付费产品/服务的收费标准、方式等具体信息详见玛塔创想赛事平台的有效公示信息（包括相关平台规则等）。
        用户购买玛塔创想赛事平台相关产品/服务时，可通过玛塔创想赛事平台提供的支付通道支付相关费用。
      </div>
      <div>
        2. 用户所购买的付费产品/服务的具体价格以有效订单的页面展示为准。
      </div>
      <div>
        3.
        用户可就其购买的相关产品/服务于玛塔创想赛事平台申请开具增值税普通发票并按玛塔创想赛事平台要求提供发票开具所需信息；若需开具增值税专用发票，用户应于购买相关产品/服务前
         向玛塔创想赛事平台说明并按照玛塔创想赛事平台要求提供发票开具所需信息。发票开具相关事宜，用户可联系玛塔创想赛事平台客服处理。
      </div>
      <div>
        4.
        有关付费产品/服务退费等其他具体事项安排，【请见玛塔创赛赛事平台发布的具体规则】或咨询玛塔创想赛事平台客服。
      </div>
      <div>
        5. 用户理解，玛塔创想赛事平台需要定期或不定期地对相关设备进行升级、检修或者维护，如因此类情况而造成玛塔创想赛事平台服务在合理时间内的中断，玛塔创想无需为此承担任何责任，
        但玛塔创想将尽可能及时通知受到影响或可能受到影响的用户。
      </div>
      <div>
        6. 如玛塔创想【有合理理由怀疑】用户可能存在下列任一情形的，玛塔创想有权不经通知而随时中断或终止向用户提供本协议项下的服务及/或按照本协议或平台规则采取其他处理措施，且
        无需对用户或任何第三方承担任何责任，由此造成的损失由用户自行独立承担，玛塔创想保留向用户追究相关责任的权利：
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; a)
        侵犯公民个人、社会组织、企事业单位合法权益的，包括但不限于侮辱、诽谤、谩骂公民个人，诋毁社会组织或企事业单位商誉或信誉等；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; b)
        以任何方式损害玛塔创想及关联方的商誉或信誉等合法权益；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; c) 损害国家或社会的公共利益、风俗道德的；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; d) 其他违反法律法规、政策或本协议约定的行为；
      </div>
      <div>
        7.
        如用户希望注销注册账户，应向平台提出申请（发送邮件至mwrc@matatalab.com），经平台审核同意后由平台注销该注册账户；用户于注册账户注销后继续使用玛塔创想平台其他服务的，本协议仍继续有效。
      </div>
      <div><strong>五、用户个人信息的保护</strong></div>
      <div>
        1.
        玛塔创想赛事平台遵守相关法律法规对于用户个人隐私信息保护的规定，玛塔创想将按照本协议及 《玛塔创想用户隐私政策》 规定收集、使用、储存、处理并保护您的个人信息。
        本协议对用户个人信息保护相关事项未作明确的，以《玛塔创想用户隐私政策》的内容为准。
      </div>
      <div>
        2. 玛塔创想不会将您的个人信息转移或披露给任何第三方，但存在以下任一情形时除外 ：
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;a) 相关法律法规或司法机关、行政机关要求；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;b)
        为完成合并、分立、收购等股权或资产重组或破产清算需要；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; c) 为向您提供玛塔创想赛事平台服务所必需；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; d)
        根据《玛塔创想用户隐私政策》或其他平台规则可以转移或披露给第三方的情形。
      </div>
      <div>
        3. 玛塔创想赛事平台发布的《玛塔创想用户隐私政策》，特别是专门适用于儿童个人信息的内容。用户使用玛塔创想赛事平台服务，即视为您及您的监护人（如适用）
        同意《玛塔创想用户隐私政策》，包括但不限于同意玛塔创想赛事平台按照《 玛塔创想用户隐私政策 》收集和使用用户的个人信息。
      </div>
      <div><strong> 六、责任限制</strong></div>
      <div>
        1. 您理解并同意：玛塔创想赛事平台所提供服务是按照现有技术和条件所能达到的现状提供的；玛塔创想会尽最大努力保证平台的正常运行，但无法随时预见和防范法律、技术以及其他风险，
        玛塔创想对此类风险造成的后果在法律允许的范围内免责。
      </div>
      <div>
        2. 如发生以下任一情形，将可能导致玛塔创想基于本协议提供的服务或本协议的履行出现履行不能、履行障碍、履行瑕疵或履行内容变更等情形，用户对此表示理解，且同意玛塔创想无需就此承担任何责任：
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;a)
        自然灾害、战争、灾难、罢工、政府行为、黑客攻击、计算机病毒侵入、电信部门通信障碍等玛塔创想赛事平台无法预见或不可对抗的力量；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; b)
        玛塔创想为平台的正常运行所需定期或不定期地进行的平台升级、检修或维护；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp; c)
        在平台已基于现有技术条件尽善意管理的情况下，设备或系统的故障以及数据安全事件；
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;d)
        其他法律法规或权力机关认定玛塔创想赛事平台无需承担责任的情形。
      </div>
      <div><strong>七、权利声明</strong></div>
      <div>
        1. 除法律法规规定或本协议约定的例外情形外，玛塔创想是平台中所有信息内容的所有权及知识产权权利人，未经玛塔创想许可，用户不得擅自使用该等信息内容。 前述 信息内容 包括但不限于商标、程序代码、界面设计、数据资料、文案、图片、图形、图表、音频、视频及动画等。
      </div>
      <div>
        2. 用户明确知悉且不可撤销的同意，用户经许可使用玛塔创想赛事平台于现在和将来提供之任何作品、素材、信息（包括但不限于玛塔创想创意作品等），或者利用玛塔创想赛事平台于
        现在和将来提供之任何服务（包括但不限于编程创作工具等）创作之作品（下称“玛塔创想衍生作品”）的所有权（包括但不限于著作权等知识产权权利）归属玛塔创想与用户共有。
      </div>
      <div>
        3. 用户基于玛塔创想衍生作品自行再创作（包括但不限于进行改编、汇编）的，需取得玛塔创想的同意，且未经玛塔创想同意，用户不得就该等新作品获取或声称拥有独
        立著作权、商标权或任何其他专有权利。
      </div>
      <div>
        4. 无论是用户基于玛塔创想衍生作品自行再创作形成的作品，或是未直接或间接使用任何玛塔创想赛事平台资源或服务进行创作的用户作品（下称“用户原创作品“），
        或是用户向平台提供的任何非个人信息，一旦在玛塔创想赛事平台上传、发布或通过玛塔创想赛事平台所提供服务以任何形式传输、呈现，即表明用户同意：在法律允许的范围内，
        独家授予玛塔创想就该等作品/信息在全球范围内、永久性、免费的使用权（包括存储、使用、发布、复制、改编或创作衍生作品等），以及玛塔创想可以自身名义对第三方侵权行为取证及提起诉讼的权利。
      </div>
      <div>
        5.
        玛塔创想按照本协议约定就玛塔创想衍生作品或用户创作的其他作品行使相关权利时，应为用户署名，如无特殊约定或客观条件造成的难以实现，署名为用户在玛塔创想注册的用户名；
        用户保证该等作品均不侵犯任何人的合法权利，包括但不限于著作权、专利权、商标专用权、技术秘密等知识产权，肖像权、名誉权、姓名权等人格权等，且玛塔创想可以依照本协议约定获得并行使相关权利。
      </div>
      <div>
        6.
        用户应保证制作、改编及上传的任何素材/内容不得侵犯任何第三方的合法权益或违反用户与其他任何第三方之间的协议，由此产生的任何纠纷、争议或损害，由用户与第三方自行解决，
        玛塔创想不承担任何责任。就任何第三方未经授权使用用户素材和用户改编素材的行为，用户授予和同意玛塔创想采取任何玛塔创想认为适当的行为进行制止，且相关费用及收益均由玛塔创想承担/享有。
        如玛塔创想认为有必要，用户应协助玛塔创想提供相关证明资料。
      </div>
      <div>
        7.
        除非事先获得玛塔创想赛事平台及相关权利人的书面授权，任何人不得对平台信息内容有擅自使用、修改、重制或者公开播送、散布、发行、公开发表、制作衍生成果、毁损、破解、逆向
        编译等行为。否则，按照本协议第九条之约定承担责任。
      </div>
      <div><strong>八、通知</strong></div>
      <div>
        1.
        用户有义务保证其注册账户联系信息诸如电子邮箱地址、联系电话等的真实性、有效性及安全性，以确保玛塔创想可以通过上述联系方式与用户进行有效的联系。玛塔创想将通过手机短信、
        电子邮件、平台站内推送以及于平台内显著位置进行公告等形式向用户作出各类通知，其中以电子方式发出的通知，在发送成功后即视为送达；以纸质载体发出的书面通知，按照用户提供联
        系地址寄件签收或无合理理由拒收之日视为送达。 此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。
      </div>
      <div>
        2.
        【您同意玛塔创想在提供服务的过程中以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在玛塔创想平台的任何位置上投放广告，在您上传、传播的内容中投放广告），
        您同意接受玛塔创想通过您提供的联系方式或玛塔创想赛事平台向您发送促销或其他相关商业信息。如果您不再同意玛塔创想向您发送信息的，请通过玛塔创想赛事平台提供的退订功能或以
        书面告知方式退订。】
      </div>
      <div><strong> 九、责任与后果</strong></div>
      <div>
        1. 如您违反本协议约定，玛塔创想有权进行独立判断并采取相应措施，包括但不限于通过技术手段删除、屏蔽相关内容或断开链接等；同时，玛塔创想有权根据您的违约行为性质采取包括但不限
        于暂停或终止向您提供相关服务以及追究相应法律责任等措施。
      </div>
      <div>
        2. 若用户的行为直接或间接导致玛塔创想或其他第三方遭受损失（包括经济损失、商誉损失及对外支付的赔偿金、和解款、诉讼费、律师费等费用），用户应全部损失承担赔偿责任。
      </div>
      <div><strong>十、争议解决及法律适用</strong></div>
      <div>
        1. 本协议的订立、生效、履行、解释及争议的解决均应适用中华人民共和国（为本协议之目的，不包括香港、澳门特别行政区及台湾地区）法律。
      </div>
      <div>
        2. 用户与玛塔创想因本协议所引起或与本协议有关的任何争议，应首先友好协商解决；协商不成的，双方同意任一方可将争议提交玛塔创想所在地有管辖权的人民法院通过诉讼解决。
      </div>
      <div><strong>十一、其他</strong></div>
      <div>
        1.
        本协议中的标题仅为方便阅读而设，在解释本协议时应被忽略，不能作为本协议条款解释的依据。
      </div>
      <div>2. 倘若本协议部分条款被裁定为无效，不影响其他条款的效力。</div>
      <div>
        3. 如您在阅读本协议过程中有任何疑义，您可通过拨打 +86
        0755-86567042 电话及平台公布的其他联系方式（mwrc@matatalab.com）联系玛塔创想进行咨询。
      </div>
      <div>
        4.
        在法律允许的范围内，玛塔创想保留对本协议（包括平台规则）的最终解释权。
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
export default {
  components: {
    navigation,
    footers,
  },
};
</script>

<style>
.user-content {
  width: 10rem;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 2;
}
</style>
