<template>
  <div>
    <navigation />

    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <!-- <div class="detail-info flex f-ac f-jc">
          <div class="wrap">
            <div class="tit cursorDefault">{{ $t("video.video") }}</div>
          </div>
        </div> -->
        <!-- <img :src="detailPage" class="img" /> -->
        <img :src="getBannerImgUrl()" alt="" class="img" />
      </div>
    </div>

    <div class="page-photos-list mt100 videoItem">
      <div class="wrap clearfix pop-photos">
        <a
          href="javascript:;"
          class="item videoA"
          data-url="img/news5.jpg"
          data-type="photo"
          v-for="(item, idx) in videoCover"
          :key="idx"
          @click="videoPlay(item.videoUrl, item.videoImg, idx)"
        >
          <div class="img">
            <img :src="item.videoImg" alt="" class="img begin" />
            <i class="videoI"></i>
            <!-- 标题 -->
            <div class="video-title">
              <span v-html="item.title"></span>
            </div>
          </div>
        </a>
      </div>
      <!-- 视频 -->
      <div v-show="videoShow">
        <div class="menban" v-show="menbanShow"></div>
        <div class="video videoStyle02">
          <div class="closeBtn" @click="closeBtn()"></div>
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          />
        </div>
      </div>
    </div>
    <div class="pop">
      <div class="main flex f-ac f-jc">
        <div class="info">
          <div class="btn-close"></div>
          <div class="item"></div>
        </div>
      </div>
    </div>

    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { getHistory } from "../api/matchReview";
export default {
  data() {
    return {
      detailPage: "",
      videoList: "",
      videoCover: [], //视频封面
      menbanShow: false, //蒙版
      videoShow: false, //视频
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src:
              "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4", // url地址
            // src: "http://matatalab.oss-cn-shenzhen.aliyuncs.com/service/png/15989361274/2021-09-15/BK6mMS_1631700337602.mp4",
            // src:""
          },
        ],
        poster:
          // "",
          "https://p1.music.126.net/5zs7IvmLv7KahY3BFzUmrg==/109951163635241613.jpg?param=600y500", // 你的封面地址(目前还是静态地址)
        // "https://matatalab.oss-cn-shenzhen.aliyuncs.com/service/png/15989361274/2021-09-17/bYkyZs_1631875956228.mp4?x-oss-process=video/snapshot,t_10000,m_fast",
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  components: {
    navigation,
    footers,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        id: localStorage.getItem("matchReviewDetailId"),
      };
      getHistory(data).then((res) => {
        if (res.code == 200) {
          this.detailPage = res.data.detailPage;
          this.videoList = res.data.videoList;
          this.videoList.forEach((val) => {
            // 视频封面
            // this.videoCover.push(val.video+"?x-oss-process=video/snapshot,t_10000,m_fast")
            this.videoCover.push({
              videoUrl: val.video,
              videoImg: val.cover,
              title: val.title,
            });
            // this.videoCover.push({videoUrl:val.video,videoImg:val.video+"?x-oss-process=video/snapshot,t_10000,m_fast"})
          });
        }
      });
    },
    videoPlay(videoUrl, videoImg, idx) {
      this.menbanShow = true;
      this.playerOptions.sources[0].src = videoUrl;
      // this.playerOptions.poster = videoUrl + "?x-oss-process=video/snapshot,t_10000,m_fast";
      this.playerOptions.poster = videoImg;
      this.videoShow = true;
      this.menbanShow = true;
    },
    closeBtn() {
      this.playerOptions.poster = "";
      this.videoShow = false;
      this.menbanShow = false;
    },
    returnLang() {
      var bowerLang = (
        navigator.userLanguage || window.navigator.language
      ).toLowerCase();
      if (bowerLang == "zh-cn") return "zh-CN";
      return "en-US";
    },
    getBannerImgUrl() {
      let currentLang =
        localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang == "zh-CN") {
        return require("../assets/img/video_banner_zh.png");
      } else {
        return require("../assets/img/video_banner_en.png");
      }
    },
  },
};
</script>

<style>
/* 视频样式 */
.videoStyle02 .video-js .vjs-volume-panel .vjs-volume-control {
  opacity: 1 !important;
}
.videoStyle02 .vjs-current-time {
  margin-left: 0.6rem !important;
}
.videoItem .pop-photos {
  position: relative;
}
.videoItem .video,
.videoItem .video .video-player {
  width: 12rem;
  height: 6rem;
}
.videoItem .video {
  /* position: absolute; */
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.videoItem .video-js.vjs-fluid,
.videoItem .video-js.vjs-16-9,
.videoItem .video-js.vjs-4-3,
.videoItem .video-js.vjs-9-16,
.videoItem .video-js.vjs-1-1 {
  height: 100%;
}
.videoItem .vjs_video_1877-dimensions.vjs-fluid {
  padding-top: 0;
  border-radius: 0.08rem;
}
.videoItem .vjs-custom-skin > .video-js .vjs-control-bar {
  border-radius: 0.08rem;
}
.videoA {
  position: relative;
  border-radius: 0.08rem;
}
.videoA .videoI {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  transform: translate(-50%, -50%);
  background: url("../assets/img/video-play.png") no-repeat;
  background-size: 100% auto;
}
.menban {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: black;
  opacity: 0.5;
}
.closeBtn {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  z-index: 100;
  top: -0.5rem;
  right: -0.5rem;
  background: url("../assets/img/icon-close.png");
  background-size: cover;
}
.closeBtn:hover {
  cursor: pointer;
}
.page-photos-list .video-title {
  font-size: 0.24rem;
  width: 100%;
  height: 60px;
  /* line-height: 60px; */
  background-color: #fd6c0b;
  color: #fff;
  position: absolute;
  bottom: 0;
}
.page-photos-list .video-title span {
  line-height: 0.3rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 10px;
  box-sizing: border-box;
  /* 多行文本 溢出隐藏显示三点... */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
