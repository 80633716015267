<template>
    <div class="achievement">
        <navigation />
        <div class="bg1 minH flex f-ac f-jc">
            <div class="achievement-register">
                <div class="achievement-res">
                    {{ $t('achievement.title') }}
                </div>
                <el-form :model="formData" class="achievement-form" :rules="rules" ref="formData">
                    <el-form-item label="" prop="name">
                        <el-input v-model="formData.name" :placeholder="$t('achievement.nameInput')"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="idCard">
                        <el-input v-model="formData.idCard" :placeholder="$t('achievement.idCardInput')"></el-input>
                    </el-form-item>
                    <VerifySlider class="achievemt-verify" :tips="$t('achievement.tips')"
                        :successTips="$t('achievement.successTips')" :success="success" :height="50"
                        :bgColor="'#F5F5F5'" :textColor="'#333'" :sliderColor="'#1E9FFF'"
                        :sliderIcon="'el-icon-circle-check'" :sliderSize="16" :sliderBgColor="'#FFF'"
                        :sliderBorderColor="'#DCDFE6'" :sliderBorderWidth="1" @success="onSuccess">
                    </VerifySlider>
                    <div v-if="verifyError" class="verify-error">
                        {{ $t("achievement.errorTip") }}
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">
                            {{ $t("achievement.query") }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <footers />
    </div>
</template>
<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { VerifySlider } from 'vue-verify-slider'
import { listAchievement } from "./../api/achievement";
export default {
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: this.$t("achievement.nameInput"), trigger: 'blur' }
                ],
                idCard: [
                    { required: true, message: this.$t("achievement.idCardInput"), trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (!value) return callback()
                            if (!/^\d{17}(\d|X|x)$/.test(value)) {
                                return callback(new Error(this.$t("achievement.idCardTip")))
                            } else {
                                callback()
                            }
                        }
                    }
                ]
            },
            success: false,
            verifyError: false,
            formData: {
                name: '',
                idCard: ''
            }
        };
    },
    components: {
        navigation,
        footers,
        VerifySlider
    },
    watch: {
    },
    computed: {
    },
    created() {
        this.lang = this.$store.state.lang;
        document.documentElement.scrollTop = 0; //跳回顶部
        // 取出存储的登录前的路由
        this.currPath = localStorage.getItem("preRoute");
    },
    methods: {
        onSuccess() {
            this.success = true;
            this.verifyError = false
        },
        onSubmit() {
            this.$refs['formData'].validate((valid) => {
                if (valid) {
                    if (this.success) {
                        let data = {
                            id_card: this.formData.idCard,
                            name: this.formData.name
                        }
                        listAchievement(data).then(res => {
                            if (res.code === 200 && res.data) {
                                localStorage.setItem("achievementData", JSON.stringify(res.data));
                                this.$router.push({
                                    name: "achievementDetail",
                                    query: {
                                        id: res.data.id,
                                    }
                                });
                            } else {
                                this.$message.error("当前查询人员暂无成绩,请检查身份信息无误后重新查询");
                            }
                        });
                    } else {
                        this.verifyError = true
                    }
                }
            })
        }
    },
};
</script>

<style scoped>
.achievement-register {
    width: 6.66rem;
    height: 5rem;
    background: rgba(246, 246, 247, .95);
    background-size: cover;
    border-radius: 0.16rem;
    padding: 0.3rem 0;
    margin: 0.6rem 0;
}

.achievement-res {
    text-align: center;
    font-size: 0.3rem;
    margin-bottom: 0.2rem;
}

.achievement-form {
    width: 4.6rem;
    text-align: center;
    margin: 0 auto;
}

.achievement-form .el-input {
    background: #fff;
    background-size: cover;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    border-radius: 0.32rem;
    overflow: hidden;
    align-items: center;
    box-sizing: border-box;
}

.achievement-form .el-button {
    margin-top: 0.4rem;
    width: 4.6rem;
    height: 0.72rem;
    border-radius: 0.75rem;
    background-size: cover;
    color: #fff;
    font-size: .36rem;
    cursor: pointer;
}

.el-form-item {
    margin-bottom: 0.1rem !important;
}

.achievemt-verify {
    margin: 0 auto;
    /* width: 100%; */
}

.achievement-register /deep/ .el-form-item__error {
    position: absolute !important;
    top: 78% !important;
    left: 30px !important;
}

.verify-error {
    color: red;
    margin-top: 0.1rem;
}
</style>
