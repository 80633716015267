// en
export default {
  siginfo: {
    welcome: 'Welcome to MatataWorld Robotics Competition',
    initregistration: 'submit registration',
    reviewapplication: 'enrollment approval',
    approved: 'approved',
    announcewinners: 'evaluation results',
    submitworks: 'upload works',
    event_name: 'Event name',
    type: 'participation type',
    family: 'family user',
    institution: 'institutional user',
    kindergarten: 'kindergarten',
    primary: 'primary school',
    code: 'enter the invitation code',
    ent_code: 'please enter the invitation code',
    correct_code: 'please enter the correct invitation code',
    entrycode: 'please enter the invitation code!',
    teamname: 'team name',
    ent_teamname: 'please enter the team name',
    teammembers: 'team members',
    name: 'name',
    ent_name: 'please enter your name',
    sex: 'gender',
    ent_sex: 'please enter gender',
    id_number: 'ID number',
    ent_idnumber: 'please enter the ID number',
    corret_idnumber: 'please enter the correct ID number!',
    parent_name: 'parent name',
    please_parent_name: "please enter your parent's name",
    parent_phone: 'Parent phone',
    please_parent_phone: "Please enter your parent's phone",
    illege: 'Sorry, the mobile phone number is illegal!',
    org: 'organization name',
    ent_org: 'Please enter the organization name',
    kindergarten_name: 'kindergarten name',
    please_kid_name: 'Please enter the kindergarten name',
    primary_school: 'primary school name',
    please_primary_name: 'Please enter the primary school ',
    instructor_parent: 'instructor',
    instructor_phone: 'instructor phone',
    ent_instr_name: "Please enter the teacher's name",
    ent_instru_phone: "Please enter the instructor's contact number",
    prize_addr: 'prize mailing address',
    address: 'Please enter the address',
    ent_email: 'Please enter your mailing address!',
    detailed_addr: 'detailed address',
    ent_detail_addr: 'Please enter detailed address!',
    submit: 'Submit',
    information: '  Please complete the registration information (Note: all information is required)',
    notice: 'You have successfully registered. We will inform you of the review results in the form of SMS',
    cancel: 'Cancel',
    ent_org_name: 'Please enter organization name!',
    ent_kid_name: "Please enter kindergarten name!",
    ent_pri_school: "Please enter the primary school name!",
    ent_contacnt_phone: 'Please enter your contact number!',
    ent_parent_name: "Please enter your parent's name!",
    ent_structor_name: 'Please enter the name of the instructor!',
    ent_structor_phone: "Please enter the instructor's phone!",
    ent_parent_phone: "Please enter your parent's phone!"

  }
};
