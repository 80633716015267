import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function returnLang() {
  // 测试国外情况，注释这2行
  var bowerLang = (navigator.userLanguage || window.navigator.language).toLowerCase();
  if (bowerLang == 'zh-cn') return 'zh-CN'
  return "en-CN";
}
export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('lang') || returnLang() || 'zh-CN', //初始化，有其他的也是调用
  },
  mutations: {
    change(state, lang) {
      state.lang = lang;
      localStorage.setItem('lang', lang);
      // window.app.$i18n.locale = lang
    }
  },
  actions: {},
  modules: {}
})