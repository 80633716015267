<template>
  <div>
    <navigation/>
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <div class="detail-info flex f-ac f-jc">
          <!-- <div class="wrap">
            <div class="tit" style="cursor: default">{{ $t("news.news") }}</div>
          </div> -->
        </div>
        <img :src="bannerImg" class="img"/>
      </div>
    </div>
    <div class="wrap clearfix wrap2">
      <div class="page-news-list mt100" v-for="(item, idx) in list" :key="idx">
        <div class="news-click" @click="getnewshistory(item.id)">
          <div class="item flex f-ac">
            <div class="img">
              <div><img :src="item.picture" class="img"/></div>
            </div>
            <div class="info info1 flex-item">
              <div class="tit title">{{ item.title }}</div>
              <!-- <div class="txt ellipsis-3 rich rich01" v-html="item.content"></div> -->
              <!-- <a class="btn" @click="getnewshistory(item.id)">{{
                $t("news.learn_more")
                }}</a> -->
            </div>
            <div class="time timeNews">
              <div class="d">{{ item.creationTime.slice(8, 10) }}</div>
              <div class="y">{{ item.creationTime.slice(0, 7) }}</div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
      </div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next" :total="total"
                     style="margin-bottom: 0.2rem">
      </el-pagination>
    </div>
    <footers/>
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import {news, getBanner} from "../api/news";

export default {
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //多少页
      total: 10, //多少条
      list: [],
      bannerImg: null,
    };
  },
  components: {
    navigation,
    footers,
  },
  created() {
    this.getList();
    this.getBannerImg();
    if (!this.list) {
      this.$router.push("/news");
    }
  },
  methods: {
    // banner图
    getBannerImg() {
      let data = {type: 2};
      getBanner(data).then((res) => {
        if (res.code == 200) {
          this.bannerImg = res.data;
        }
      });
    },
    getList() {
      let data = {
        page: this.currentPage,
        size: this.pageSize,
      };
      news(data).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.total = res.total;
        } else {
        }
      });
    },
    // 分页事件
    // 每页多少条
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    getnewshistory(id) {
      this.$router.push({
        name: "newsDetails",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style>
.wrap2 {
  margin-top: 1rem;
}

.page-pages a:hover {
  cursor: pointer !important;
}

.page-news-list .img {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.04rem;
}

.img a {
  display: block;
}

.rich img {
  display: block;
  max-width: 100%;
}

.rich video {
  display: block;
  width: 100%;
  height: 600px;
}

.rich01 p {
  display: none;
}

.rich01 p:first-child {
  display: block;
}

.rich01 ul {
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}

.rich01 h2 {
  display: none;
}

.itemNews {
  display: flex;
  align-items: stretch;
  /* 如有必要，保持项目垂直居中 */
}

.imgNews {
  flex-shrink: 0;
  width: 100px;
  height: 80px;
  /* 其他图片样式 */
}

.info1 {
  flex-grow: 1;
  padding: 10px 10px 40px 10px;
  /* 根据实际需要调整 */
  min-width: 10rem;
  /* 为了在flex容器内部允许内容被收缩 */
  .title {
    overflow: visible;
    /* 确保内容超出也会显示 */
    text-overflow: clip;
    /* 超出部分显示原文本 */
    white-space: normal;
    /* 允许自动换行 */
    word-wrap: break-word;
    /* 在必要时可以在单词内换行 */
    line-height: 1.2em;
    /* 根据实际情况调整 */
    max-height: none;
    /* 移除最大高度限制，允许内容自由展开 */
    font-size: 20px !important;
  }
}


.timeNews {
  flex-shrink: 0;
  /* 防止日期部分收缩 */
  width: 50px;
  /* 根据实际需要调整 */
  margin-left: auto;
  /* 确定日期始终停靠在右侧 */
  /* 其他日期样式 */
}

.news-click {
  padding-right: 1rem;
}

.news-click, .news-click * {
  cursor: pointer;
}

.page-news-list .news-click:hover {
  background-color: #F4F6F8;
}
</style>
