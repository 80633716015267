<template>
  <div class="achievementDetail">
    <navigation />
    <div class="minH flex f-ac f-jc">
      <div class="achievement-register">
        <div class="achievement-res">
            {{$t('achievement.title')}}
        </div>
        <table class="achievementTable">
          <tbody>
            <tr>
              <td class="achievement-header">{{ $t("achievement.name") }}</td>
              <td class="achievement-value">{{formData.name}}</td>
            </tr>
            <tr>
              <td class="achievement-header">{{$t('achievement.creativityScore')}}</td>
              <td class="achievement-value">{{formData.creativityScore}}</td>
            </tr>
            <tr>
              <td class="achievement-header">{{$t('achievement.taskScore')}}</td>
              <td class="achievement-value">{{formData.taskScore}}</td>
            </tr>
            <tr>
              <td class="achievement-header">{{$t('achievement.sumScore')}}</td>
              <td class="achievement-value">{{formData.sumScore}}</td>
            </tr>
            <tr>
              <td class="achievement-header">{{$t('achievement.duration')}}</td>
              <td class="achievement-value">{{formData.duration < 60 ? `${formData.duration}秒` : formData.duration < 3600 ? `${Math.floor(formData.duration / 60)}分${formData.duration % 60}秒` : `${Math.floor(formData.duration / 3600)}时${Math.floor((formData.duration % 3600) / 60)}分${formData.duration % 60}秒`}}</td>
            </tr>
            <tr>
              <td class="achievement-header">{{$t('achievement.award')}}</td>
              <td class="achievement-value">{{formData.award}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
export default {
  data() {
    return {
        formData: {
            name: '',
            score: null,
            duration: null,
            award: ''
        }
    };
  },
  components: {
    navigation,
    footers
  },
  watch: {
  },
  computed: {
  },
  created() {
    this.lang = this.$store.state.lang;
    document.documentElement.scrollTop = 0; //跳回顶部
    // 取出存储的登录前的路由
    this.currPath = localStorage.getItem("preRoute");
    this.formData = JSON.parse(localStorage.getItem("achievementData"));
    let taskScore = this.formData.taskScore ? this.formData.taskScore : 0
    let creativityScore = this.formData.creativityScore ? this.formData.creativityScore : 0
    this.formData.sumScore = taskScore + creativityScore
  },
  methods: {
  },
};
</script>

<style scoped>
.achievement-register{
    min-width: 6rem;
}
.el-form-item{
    margin-bottom: 0.1rem!important;
}
.achievement-form{
  min-width: 65%;
}
:global(.achievementDetail .el-form-item__content) {
  float: right;
}
.achievement-res{
    text-align: center;
    font-size: 0.3rem;
    margin-bottom: 0.7rem;
}
.achievementTable {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 10px;
  border: 1px solid #ccc;
}

td.achievement-header {
  width: 30%;
  text-align: left;
  font-weight: bold;
}

td.achievement-value {
  width: 70%;
  text-align: right;
}
</style>
