<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="user-content">
      <span style="font-size: 32px">Privacy Policy</span>
      <div>
        This privacy policy (the “Privacy Policy”) is designed to inform you
        about the use of information that you provide to Matatalab Co., Ltd.
        (“Matatalab,” “we,” or “us”) through Matatalab’s website relating to DIY
        robotics construction and science, technology, engineering and
        mathematics education platform for makers, hobbyists, (“STEM”) learners,
        and educators, located at www.matatalab.com (“Site”) and the services,
        features, content, applications and products we offer (the
        “Application”).
      </div>
      <div>
        This Privacy Policy covers the treatment of personally identifiable
        information (“Personal Data”) gathered when you are using or accessing
        the Application. This Privacy Policy is meant to help you understand
        what Personal Data we collect, why we collect it and what we do with it.
        This Privacy Policy should be read carefully prior to using the Site as
        it is important. It may be printed or downloaded at any time. The
        Privacy Policy will be stored at: mwrc.matatalab.com/policy_en/.
      </div>
      <div><strong>Our Privacy Policy explains:</strong></div>
      <ul>
        <li>What information we collect and why we collect it.</li>
        <li>How your information is used.</li>
        <li>How you can access and update your information.</li>
      </ul>
      <div>
        We have tried to keep the wording of Privacy Policy as simple as
        possible. Please also visit our Terms and Conditions at
        https://www.matataworld.com/general-terms-conditions/ establishing the
        use, disclaimers, and limitations of liability governing the use of the
        Site.
      </div>
      <div>
        This Privacy Policy applies to all of the services offered by Matatalab
        but excludes any external services that have separate privacy policies
        that do not incorporate this Privacy Policy. Our Privacy Policy also
        does not apply to other companies or organizations that advertise our
        products or services and that may use cookies and other technologies to
        serve and offer relevant ads.
      </div>
      <div><strong>Collection and use of Personal Data</strong></div>
      <div>
        Among the types of Personal Data that this Application collects, by
        itself or through third parties, there are in any case: cookies, usage
        data and email.
      </div>
      <div>
        <strong>We collect Personal Data from you when you voluntarily choose to
          provide us with such information,</strong>
        such as when you agree to this when you visit the website, register for
        newsletters, sign up for forums or other user communities, make use of
        the Matatalab App, place orders, request information from Matatalab. We
        collect this Personal Data to provide better services to all of our
        customers.
      </div>
      <div>
        <strong>We also collect Personal Data from you when you watch a video on
          YouTube, visit a website that uses interaction with our ads and
          content.</strong>This information includes device-specific information (such as your
        hardware model, operating system version, unique device identifiers, and
        mobile network information including phone number). We may associate
        your device or phone number with your Matatalab account. We collect this
        information to provide better services to all of our customers.
        Matatalab makes use of server logs, this processes your visiting record
        (when was the Site visited and from which IP address). The purpose of
        these server logs is to help maintain the servers and prevent malicious
        use (for example blocking an IP address if this is the source of a DDOS
        attack). Matatalab may use your location to provide the content of the
        Site in corresponding language and the GPS signal to establish a
        Bluetooth connection. This information is, however, not stored. No other
        Personal Data is processed by these server logs. Such personal
        information may include information that can be used to identify and/or
        contact a natural person such as name, postal address, telephone number,
        email address, fax number, organization, job description, scope of
        business operations. We use your Personal Data we collect to provide,
        maintain, protect and improve the Application and to protect our Site
        and our customers. We maintain such Personal Data for internal purposes
        only, as well as to ensure that you receive any information that you may
        request from Matatalab, and to provide you with information on our
        products and services. If you contact Matatalab, we keep a record of
        your communication to help solve any issues you might be facing. We may
        use your email address to inform you about our services, such as letting
        you know about upcoming changes or improvements to our products and
        services. We will not sell, exchange, transfer or otherwise give your
        Personal Data to any third party without your explicit and informed
        consent subject to the exceptions elaborated below in the section of
        this Privacy Policy entitled “Disclosure of Personal Information”. We
        will ask for your explicit and informed consent before using information
        for a purpose other than those set out in this Privacy Policy. Matatalab
        processes Personal Data on servers in the European Union and in the
        United States and in some cases China or Japan. Matatalab does not store
        or keep the Personal Data stored longer than necessary for the purposes
        for which the Personal Data was processed. Personal Data will be kept
        until a customer deletes their account. At the moment a customer deletes
        their Matatalab account, Matatalab will no longer be able to retrieve
        the Personal Data any longer.
      </div>
      <div><strong> Disclosure of Personal Data</strong></div>
      <div>
        As a general matter, we do not sell, trade, or otherwise transfer to
        third parties your Personal Data. We will share your Personal Data with
        companies, organizations or individuals outside of Matatalab with your
        explicit and informed consent to do so. This does not apply to trusted
        third parties so designated by Matatalab, who assist us in operating the
        Site, conducting our business, or servicing you (“Trusted Third
        Parties”). Rather, we may transmit your Personal Data to Trusted Third
        Parties. We will only transmit your Personal Data to Trusted Third
        Parties following their agreement and commitment to keep all Personal
        Data confidential. This Personal Data is given to Trusted Third Parties
        based on our instructions and in compliance with our Privacy Policy and
        any other confidentiality and security measures. We may also release
        your Personal Data when we believe such release is necessary comply with
        applicable law, enforce the Site’s policies, or protect Matatalab’s
        legal rights, property, or safety, or those of third parties. This may
        be the case:
      </div>
      <div>
        <ul>
          <li>
            to comply with an applicable law or regulation, legal proceedings or
            any other enforceable governmental request or order.
          </li>
          <li>in case of an investigation of potential violations.</li>
          <li>
            to detect, prevent any type of fraud, security or technical issues.
            Information that is not personally identifiable may be shared
            publicly, for example for statistics about our Site or Application.
          </li>
        </ul>
      </div>
      <div>
        If Matatalab is involved in any kind of merger, acquisition or sale, we
        will still ensure the confidentiality of any Personal Data.
      </div>
      <div><strong> Transfer of Personal Data outside the EU</strong></div>
      <div>
        Your Personal Data may be transferred to and stored in servers located
        in the United States and in some cases China (when you use the
        Application with Chinese settings) and Japan (related to customer
        service).
      </div>
      <div>Our websites use cookies to remember:</div>
      <div>
        We use information collected from cookies (which are text files placed
        on your computer) and other technologies
      </div>
      <div>
        <ul>
          <li>Your preferences, such as the site language</li>
          <li>The items currently in your shopping cart</li>
          <li>Whether you have logged in to the website</li>
        </ul>
        <div>
          Also, some videos embedded in our pages use a cookie to anonymously
          gather statistics on how you got there and what videos you visited.
          Cookies (random-generated numbers to tell us that these visits are
          from the same anonymous user) are also stored by Google Analytics, the
          corporate service which measures how well our websites meet the needs
          of its users by analyzing how users use the site.
        </div>
      </div>

      <div>
        You may refuse the use of cookies by selecting the appropriate settings
        on your browser. However, please note that should you refuse the use of
        cookies, you may not be able to use certain features otherwise available
        on the Site. The cookie-related information is<strong>not used to identify</strong>  you personally. These
        cookies are not used for any purpose other than
        those described here. Place of processing : USA – Privacy Policy –
        Opt-out. You can opt-out of Google Analytics by installing Google’s
        opt-out browser add-on, and out of interest-based Google ads using
        Google’s Ads Settings.
      </div>

      <div><i>Facebook</i></div>
      <div>
        The Facebook Like button and social widgets are services allowing
        interaction with the Facebook social network provided by Facebook, Inc.
      </div>
      <div><strong>（一）Cookie</strong></div>
      <div>
        The Site allows for direct interaction with external social networks or
        other external platform that are outside our control. The interaction
        and information obtained by this Application are always subject to the
        user’s privacy settings for each social network. We are not responsible
        for the security or privacy of any information collected by other
        websites or other services. Information collected by third parties,
        which may include such aspects as location data or contact details, is
        governed by their privacy practices. You should exercise caution, and
        review the privacy statements applicable to the third-party websites and
        services you use. If a service enabling interaction with social networks
        is installed it may still collect traffic data for the pages where the
        service is installed, even when customers do not use it.
      </div>
      <div><i>Personal Data collected: Cookie and Usage Data.</i></div>
      <div><i>Place of processing : USA – Privacy Policy.</i></div>
      <div>
        To opt-out of activity-based ads in Facebook, visit your Facebook Ad
        Settings.
      </div>
      <div>Alibaba Cloud</div>
      <div>
        Alibaba Cloud  is a cloud computing service created by Alibaba Cloud for
        building, testing, deploying and managing applications and services
        through a global network of Alibaba Cloud-managed data centers. Cloud
        computing is an information technology paradigm that enables access to
        shared pools of configurable system resources and the provision of
        services, often over the internet. Alibaba Cloud is used to support the
        Application. Your registered email address, name, published content and
        comments may be shared withAlibaba Cloud .
      </div>
      <div>withAlibaba Cloud .</div>
      <div>
        Personal Data collected: your registered email address, name, published
        content and comments.
      </div>
      <div><i>Place of processing: Germany– Privacy Policy.</i></div>
      <div><i>PayPal and similar providers</i></div>
      <div>
        If you make a purchase on the Site, we may ask you for your credit card
        and billing information. This Personal Data is solely processed and
        handled by our payment providers such as PayPal, 2Checkout or
        BankTransfer. PayPal and similar providers are worldwide online payments
        systems that support online money transfers and operate as payment
        processor for Matatalab. Matatalab does not have any access to the
        Personal Data collected by payment providers.
      </div>
      <div>
        <i>Personal Data collected: credit card and billing information.</i>
      </div>
      <div><i>Place of processing: USA – Privacy Policy.</i></div>
      <div>Twitter</div>
      <div>
        The Twitter Tweet button and social widgets are services allowing
        interaction with the Twitter social network provided by Twitter Inc.
      </div>
      <div><i>Personal Data collected: Cookie and Usage Data.</i></div>
      <div><i>Place of processing : USA – Privacy Policy.</i></div>
      <div>
        If you would like to change the way data is collected about you in
        Twitter, visit Twitter account settings, Do Not Track browser setting,
        and the opt-out choices of Twitter’s ads. This Application does not
        support “Do Not Track” requests. To determine whether any of the third
        party services it uses honor the “Do Not Track” requests, please read
        their privacy policies. If you do not want our advertising providers to
        use your visits to Twitter websites to show you personalized ads on our
        behalf, you can opt-out using your Do Not Track browser setting.
      </div>
      <div><i>YouTube</i></div>
      <div>
        YouTube is a video content visualization service provided by YouTube
        that allows this Application to incorporate content of this kind on its
        pages.
      </div>
      <div><i>Personal Data collected: Cookie and Usage Data.</i></div>
      <div><i>Place of processing : USA – Privacy Policy.</i></div>
      <div>
        <i>To opt-out activity-based ads on YouTube, visit your YouTube account
          settings.</i>
      </div>
      <div>
        Our Privacy Policy also does not apply to these external social networks
        and platforms.
      </div>
      <div>
        Our third-party advertising providers, including Google Analytics, also
        use these technologies for marketing on our behalf, including the
        delivery of interest-based ads and measurement of their performance.
      </div>
      <div><strong>Managing email addresses and sending messages</strong></div>
      <div>
        These services provided by third parties make it possible to manage a
        database of subscription based email contacts to communicate with
        customers via email. The services are used to collect data concerning
        the date and time when the mail is viewed by the customer, as well as
        when the user interacts with incoming mail, such as by clicking on links
        included in the email.
      </div>
      <div><i>MailChimp</i></div>
      <div>
        MailChimp is an email address management and message sending service
        provided by The Rocket Science Group, LLC d/b/a MailChimp, a State of
        Georgia limited liability company. MailChimp is a third party mailing
        service that sends emails to our customers on behalf of Matatalab.
        Personal data is stored until you opt-out of the mailing service.
      </div>
      <div><i>Personal Data collected: Email address.</i></div>
      <div><i>Place of processing : United States – Privacy Policy.</i></div>
      <div><strong>Security</strong></div>
      <div>
        We use a variety of security measures to maintain the safety of your
        Personal Data. We take great care to ensure that your Personal Data is
        stored securely on our servers, and we follow the standards generally
        accepted in the industry to protect Personal Data submitted to us. In
        particular, we:
      </div>
      <div>
        <ul>
          <li>
            we encrypt many of our services using SSL (Secure Sockets Layer)
            when transmitting data. When a browser attempts to access a website
            that is secured by SSL, the browser and the web server establish a
            SSL connection, using keys. SSL creates a secure connection by
            establishing an encrypted connection.
          </li>
          <li>
            hashing is used when storing passwords. Hashing is a one-way
            function in which a hashed value cannot be reversed to obtain the
            password. Only an encryption key can decrypt the password. Only the
            hashed password is stored by Matatalab.
          </li>
          <li>
            we review our information collection, storage and processing
            practices, including physical security measures, to guard against
            unauthorized access to systems.
          </li>
          <li>
            we restrict access to Personal Data to our employees, contractors
            and agents who need to know that information in order to process if
            for Matatalab and who are subject to strict contractual
            confidentiality obligations.
          </li>
        </ul>
      </div>
      <div>
        It is important to note, however, that no method of transmission or
        storage via the internet is 100% secure, and thus we cannot absolutely
        guarantee the security of your Personal Data submitted via the Site.
      </div>
      <div>
        We aim to maintain our services in a manner that protects Personal Data
        from accidental or malicious destruction. As a consequence, we may not
        immediately delete residual copies from our active servers and may not
        remove information from our backup systems. Matatalab uses the Cloud
        server services of providers such as Amazon and Microsoft. These
        providers also store the information in the United States. Upon deletion
        of an account, this Personal Data is inaccessible to Makeblock unless
        otherwise required by law.
      </div>
      <div>
        <strong>Accessing and updating your Personal Data</strong>
      </div>
      <div>
        We understand our customers have different privacy concerns. Our aim is
        to be clear about what Personal Data we collect in order for our
        customer to make informed decisions.
      </div>
      <div>
        If the Personal Data is wrong or incomplete, we strive to give our
        customers ways to update it quickly or delete it by contacting us in the
        manner as described in the section of this Privacy Policy entitled
        “Contact Information.” This may not be the case if that information has
        to be kept for legal purposes.
      </div>
      <div>
        <strong>Opting-out</strong>
      </div>
      <div>
        We provide you with the opportunity to “opt-out” of having your Personal
        Data used for certain purposes.
      </div>
      <div>
        These “opt-out” opportunities include, by way of example only, the
        possibility to opt-out receiving the Matatalab newsletters if you no
        longer wish to receive them, by following the instructions included in
        each communication, or by contacting us in the manner as described in
        the section of this Privacy Policy entitled “Contact Information.”
      </div>
      <div>
        <strong>Changes to our Privacy Policy</strong>
      </div>
      <div>
        We may amend this Privacy Policy from time to time as we add new
        features and services to the Application, as laws change, and as privacy
        and security practices evolve. We regularly review our compliance with
        our Privacy Policy. When we receive formal written complaints, we will
        contact the person who made the complaint to follow up. We work with the
        appropriate regulatory authorities, including local data protection
        authorities, to resolve any complaints that we cannot resolve with our
        customers directly. Should we change our Privacy Policy, we will notify
        you of any material changes by posting them to the Site, and to other
        places that we deem appropriate for informing you thereof. It is
        strongly recommended to check this page often, referring to the date of
        the last modification listed at the bottom. In case of significant
        changes, you will be provided with a notice (such as an email
        notification). We will in any case not reduce your rights under this
        Privacy Policy without your explicit and informed consent. If a user
        objects to any of the changes to this Privacy Policy, the user must
        cease using this Application and can request that Matatalab erase the
        Personal Data. Unless stated otherwise, the then-current Privacy Policy
        applies to all Personal Data Matatalab has about users.
      </div>
      <div>
        All prior versions of this Privacy Policy will be kept in an archive for
        your review upon request.
      </div>
      <div>
        <strong>Applicable law</strong>
      </div>
      <div>
        By visiting this Site, you agree that the laws of the Netherlands,
        without regard to principles of conflict of laws, will govern this
        Privacy Policy.
      </div>
      <div>
        <strong>The rights of users</strong>
      </div>
      <div>
        Users have the right to withdraw their consent for the processing of
        Personal Data or the further processing of Personal Data by Matatalab at
        any time. A withdrawal should be sent to Matatalab at the contact
        information set out below.
      </div>
      <div>
        In addition to the information contained in this Privacy Policy, you may
        be provided with additional and contextual information concerning
        particular services or the collection and processing of your Personal
        Data upon request.
      </div>
      <div>
        Users have the right at any time, to know whether their Personal Data
        has been stored and can consult Matatalab to learn about their contents
        and origin, to verify their accuracy or to ask for them to be
        supplemented, cancelled, updated or corrected, or for their
        transformation into anonymous format or to block any data held in
        violation of the law, as well as to oppose their treatment for any and
        all legitimate reasons. Requests should be sent to Matatalab at the
        contact information set out below.
      </div>
      <div>
        Users have the right to request to obtain and reuse their Personal Data
        for their own purposes across different services. Requests should be
        sent to Matatalab at the contact information set out below.
      </div>
      <div>
        Users have the right to lodge a complaint against Matatalab with a
        supervisory authority. More details concerning the collection or
        processing of Personal Data may be requested from Matatalab at any time.
        Please see the contact information at the end of this Privacy Policy.
      </div>
      <div>
        <strong> Contact information </strong>
      </div>
      <div>Matatalab is responsible for this Privacy Policy.</div>
      <div>DATA CONTROLLER AND OWNER: Matatalab Co., Ltd.</div>
      <div>Thank you for taking the time to read this Privacy Policy.</div>
      <div>Copyright 2024 Matatalab Co., Ltd. All rights reserved.</div>
      <div>Updated on 2024/03/25</div>
      <div>
        If you have any questions about this Privacy Policy, the practices of
        this Site, or your dealings with this Site, please contact us at https://www.matatastudio.com
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "./../../assets/css/index.css";
import "./../../assets/css/common.css";
import "./../../assets/js/action";
import navigation from "./../../components/navigation.vue";
import footers from "./../../components/footers.vue";
export default {
  components: {
    navigation,
    footers,
  },
};
</script>

<style></style>
