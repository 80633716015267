import { render, staticRenderFns } from "./emailLogin.vue?vue&type=template&id=f2bc21aa&scoped=true&"
import script from "./emailLogin.vue?vue&type=script&lang=js&"
export * from "./emailLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2bc21aa",
  null
  
)

export default component.exports