<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <!-- <div class="detail-info flex f-ac f-jc">
          <div class="wrap">
            <div class="tit cursorDefault">{{ $t("video.Highlights") }}</div> 
          </div>
        </div> -->
        <!-- <img :src="urlImg" class="img" /> -->
        <img :src="getBannerImgUrl()" alt="" class="img" />
      </div>
    </div>

    <div class="page-photos-list mt100">
      <div class="wrap clearfix pop-photos">
        <a
          class="item"
          v-for="(item, inx) in imgs"
          :key="inx"
          :data-url="item"
          data-type="photo"
        >
          <div class="img">
            <img :src="item" :id="item" @click="big(item)" class="img begin" />
          </div>
        </a>
      </div>
      <div v-show="photosShow">
        <div class="menban" v-show="shadeShow"></div>
        <div class="photos">
          <div class="closeBtn" @click="closeBtn()"></div>
          <img :src="photos" alt="" />
        </div>
      </div>
    </div>

    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { getHistory } from "../api/matchReview";

export default {
  data() {
    return {
      imgs: [],
      photosShow: false, //图片放大
      shadeShow: false,
      photos: "",
      urlImg: "", //首图路径
    };
  },
  components: {
    navigation,
    footers,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        id: this.$route.query.id,
      };
      if (this.$route.query.id) {
        data.id = this.$route.query.id;
      } else {
        data.id = localStorage.getItem("matchReviewDetailId");
      }
      getHistory(data).then((res) => {
        if (res.code == 200) {
          this.imgs = res.data.eventPhotos;
          this.urlImg = res.data.detailPage;
        }
      });
    },
    big(item) {
      this.photosShow = true;
      this.shadeShow = true;
      this.photos = item;
    },
    closeBtn() {
      this.photosShow = false;
      this.shadeShow = false;
    },
    returnLang() {
      var bowerLang = (
        navigator.userLanguage || window.navigator.language
      ).toLowerCase();
      if (bowerLang == "zh-cn") return "zh-CN";
      return "en-US";
    },
    getBannerImgUrl() {
      let currentLang =
        localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang == "zh-CN") {
        return require("../assets/img/phone_banner_zh.png");
      } else {
        return require("../assets/img/phone_banner_en.png");
      }
    },
  },
};
</script>

<style>
.photos {
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.photos img {
  width: 100%;
  height: 100%;
}
</style>
