// zh
export default {
  acount: {
    event: "我的赛事",
    manage: '账户管理',
    tel: "手机号码",
    type: "账户类型",
    home: "家庭用户",
    institutional: '机构用户',
    kindergarten: " 幼儿园",
    primary: "小学",
    name: "姓名",
    family: "家庭名称",
    org: "机构名称",
    kinder: "幼儿园名称",
    pri_sch: "小学名称",
    email: '邮箱地址',
    edit: "编辑",
    tips: "tips:如需修改手机号码，请联系--",
    ent_name: " 输入姓名",
    ent_fam: "输入家庭名称",
    ent_org: "输入机构名称",
    ent_kin: "输入幼儿园名称",
    ent_pri: "输入小学名称",
    ent_email: "输入邮箱地址",
    sorry: "抱歉邮箱格式不正确!",
    submit: '提交',
    cancel: "取消",
  }
};