<template>
    <div>
    <!-- 提示成功 -->
          <div class="alerts-success">
            <div class="alert-success">
              <img src="../assets/img/rg1.png" alt="" />
              <div>{{successText}}</div>
            </div>
          </div>
    </div>
</template>
<script>
export default {
    props: ["successText"],
    data(){
        return {
        }
    },
    mounted(){
    },
    methods: {
    },
}
</script>
<style>
</style>