<template>
  <div>
    <navigation />
    <div class="top">
      <!-- <img src="../assets/img/eve-top.png" alt="" /> -->
      <img :src="getBannerImgUrl()" alt="" />
    </div>
    <div class="about">
      <img :src="getAboutImgUrl()" alt="" />
      <div class="about-cont">
        <h4>{{ $t("event.about") }}</h4>
        <p>
          {{ $t("event.mata") }}
        </p>
      </div>
    </div>
    <div class="set" :style="{background: 'url(' + getBackgroundImgUrl() + ')',backgroundSize: 'cover'}">
      <div class="xh">01</div>
      <h3>{{ $t("event.set.setting") }}</h3>
      <div class="img-list">
        <div class="left" :style="{background: 'url(' + getSetLeftImgUrl() + ')',backgroundSize: 'cover'}">
          <p class="txt01">{{ $t("event.set.programing") }}</p>
          <p class="txt02">{{ $t("event.set.online") }}</p>
        </div>
        <div class="right" :style="{background: 'url(' + getSetRightImgUrl() + ')',backgroundSize: 'cover'}">
          <p class="txt01">{{ $t("event.set.programing") }}</p>
          <p class="txt02">{{ $t("event.set.offline") }}</p>
        </div>
      </div>
    </div>
    <div class="idea" :style="{background: 'url(' + getIdeaBackgroundImgUrl() + ')',backgroundSize: 'cover'}">
      <div class="xh">02</div>
      <h3>{{ $t("event.idea") }}</h3>
      <div class="img-list">
        <div class="con con01" :style="{background: 'url(' + getIdea01Url() + ')',backgroundSize: 'cover'}">
          <div class="flot">
            <img src="../assets/img/en1.png" alt="" />
            <p class="txt01">{{ $t("event.share") }}</p>
          </div>
          <!-- <p class="txt02">
            鼓励孩子们通过参加比赛，将自己的创意成果与全世界的小朋友一起分享。
          </p> -->
          <p class="txt02">
            {{ $t("event.children") }}
          </p>
        </div>
        <div class="con con02" :style="{background: 'url(' + getIdea02Url() + ')',backgroundSize: 'cover'}">
          <div class="flot">
            <img src="../assets/img/en2.png" alt="" />
            <p class="txt01">{{ $t("event.confidence") }}</p>
          </div>

          <p class="txt02">{{ $t("event.ihope") }}</p>
        </div>
        <div class="con con03" :style="{background: 'url(' + getIdea03Url() + ')',backgroundSize: 'cover'}">
          <div class="flot">
            <img src="../assets/img/en3.png" alt="" />
            <p class="txt01">{{ $t("event.innovation") }}</p>
          </div>

          <p class="txt02">{{ $t("event.innovativec") }}</p>
        </div>
        <div class="con con04" :style="{background: 'url(' + getIdea04Url() + ')',backgroundSize: 'cover'}">
          <div class="flot">
            <img src="../assets/img/en4.png" alt="" />
            <p class="txt01">{{ $t("event.collaboration") }}</p>
          </div>

          <!-- <p class="txt02">
            鼓励以团队的形式完成任务挑战，通过比赛培养孩子的团队意识。
          </p> -->
          <p class="txt02">
            {{ $t("event.challenges") }}
          </p>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="course">
      <div class="xh">03</div>
      <h3>{{ $t("event.develop") }}</h3>
      <!-- <img src="../assets/img/eve-fz.png" alt="" /> -->
      <img :src="getFzImgUrl()" alt="" />
    </div>
    <!-- 底部 -->
    <div class="footer" v-if="false">
      <div class="tit">Contact</div>
      <div class="me">联系我们</div>
      <div class="info01">
        <p>参赛咨询：MWRC赛事组委会</p>
        <p>地址：深圳市南山区茶光路1004号健兴科技大厦B栋504</p>
        <p>Email: mwrc@matatalab.com</p>
      </div>
      <div class="info02">
        <p>商务合作：程文鹏</p>
        <p>联系电话：13410175588</p>
        <p>Email：cycle@matatalab.com</p>
      </div>
    </div>
    <footers />
    <backTop />
  </div>
</template>
<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import backTop from "../components/back-top.vue";
export default {
  data() {
    return {};
  },
  methods: {
    returnLang() {
      var bowerLang = (
        navigator.userLanguage || window.navigator.language
      ).toLowerCase();
      if (bowerLang == "zh-cn") return "zh-CN";
      return "en-US";
    },
    getBannerImgUrl() {
      let currentLang =
        localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang == "zh-CN") {
        return require("../assets/img/eve-top.png");
      } else {
        return require("../assets/img/eve-top-en.png");
      }
    },
    getAboutImgUrl() {
      let currentLang =
          localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang == "zh-CN") {
        return require("../assets/img/eve-cont.png");
      } else {
        return require("../assets/img/eve-cont-en.png");
      }
    },
    getBackgroundImgUrl() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-sz-bgc.png");
      } else {
        return require("../assets/img/eve-sz-bgc-en.png"); // 假设有英文版背景图
      }
    },
    getSetLeftImgUrl() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-sz-01.png");
      } else {
        return require("../assets/img/eve-sz-en.png"); // 假设有英文版背景图
      }
    },
    getSetRightImgUrl() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-sz-02.png");
      } else {
        return require("../assets/img/eve-sz-02-en.png"); // 假设有英文版背景图
      }
    },
    getIdeaBackgroundImgUrl() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-ln-bgc.png");
      } else {
        return require("../assets/img/eve-ln-bgc-en.png"); // 假设有英文版背景图
      }
    },
    getIdea01Url() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-ln-01.png");
      } else {
        return require("../assets/img/eve-ln-01-en.png"); // 假设有英文版背景图
      }
    },
    getIdea02Url() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-ln-02.png");
      } else {
        return require("../assets/img/eve-ln-02-en.png"); // 假设有英文版背景图
      }
    },
    getIdea03Url() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-ln-03.png");
      } else {
        return require("../assets/img/eve-ln-03-en.png"); // 假设有英文版背景图
      }
    },
    getIdea04Url() {
      let currentLang = localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang === "zh-CN") {
        return require("../assets/img/eve-ln-04.png");
      } else {
        return require("../assets/img/eve-ln-04-en.png"); // 假设有英文版背景图
      }
    },
    getFzImgUrl() {
      let currentLang =
        localStorage.getItem("lang") || this.returnLang() || "zh-CN";
      if (currentLang == "zh-CN") {
        return require("../assets/img/eve-fz.png");
      } else {
        return require("../assets/img/eve-fz-en.png");
      }
    },
  },
  components: {
    navigation,
    footers,
    backTop,
  },
};
</script>
<style scoped>
.top {
  width: 100%;
  height: 9.3rem;
}

.top img {
  width: 100%;
  height: 100%;
}

.about {
  width: 100%;
  height: 6.56rem;
  background: url("../assets/img/eve-bgc.png");
  margin-top: 1.3rem;
  position: relative;
  background-size: cover;
}

.about img {
  width: 5.14rem;
  height: 3.43rem;
  position: absolute;
  top: 2.29rem;
  left: 3.62rem;
}

.about .about-cont {
  width: 7.55rem;
  /* height: 3.43rem; */
  position: absolute;
  top: 2.29rem;
  left: 9.46rem;
}

.about-cont h4 {
  height: 0.85rem;
  font-size: 0.39rem;
  font-weight: 700;
  color: #333;
}

.about-cont p {
  line-height: 0.3rem;
  font-size: 0.16rem;
  color: #666;
}

.set {
  width: 100%;
  height: 5rem;
  margin-top: 1.6rem;
  margin-bottom: 3.15rem;
  text-align: center;
  padding-top: 0.8rem;
  position: relative;
  background-size: cover;
}

.set .xh,
.set h3,
.idea .xh,
.idea h3 {
  font-size: 0.4rem;
  font-weight: 700;
  color: #fff;
}

.set .xh,
.idea .xh {
  height: 0.51rem;
  line-height: 0.51rem;
}

.set h3,
.idea h3 {
  margin-top: 0.32rem;
}

.set .img-list {
  width: 11rem;
  height: 3.9rem;
  position: absolute;
  top: 2.89rem;
  left: 4.1rem;
}

.set .img-list .left p,
.set .img-list .right p {
  margin-bottom: -6px;
}

.set .img-list .left {
  width: 5.28rem;
  height: 100%;
  background: url("../assets/img/eve-sz-01.png");
  float: left;
  text-align: center;
  padding-top: 2.98rem;
  background-size: cover;
}

.set .img-list .right {
  width: 5.28rem;
  height: 100%;
  background: url("../assets/img/eve-sz-02.png");
  float: right;
  text-align: center;
  padding-top: 2.98rem;
  background-size: cover;
}

.idea .img-list p {
  color: #fff !important;
}

.set .img-list .left .txt01,
.set .img-list .right .txt01 {
  font-size: 0.18rem;
  font-weight: bold;
  color: #fff;
  line-height: 0.5rem;
}

.set .img-list .left .txt02,
.set .img-list .right .txt02 {
  font-size: 0.26rem;
  color: #fff;
}

.idea {
  width: 100%;
  height: 7.17rem;
  background: url("../assets/img/eve-ln-bgc.png");
  text-align: center;
  padding-top: 0.8rem;
  position: relative;
  background-size: cover;
}

.idea .img-list {
  width: 11.02rem;
  height: 2.81rem;
  box-sizing: border-box;
  position: absolute;
  top: 3rem;
  left: 4rem;
}

.idea .img-list .con {
  width: 2.53rem;
  height: 2.81rem;
  margin-right: 0.3rem;
  float: left;
}

.idea .img-list div:last-child {
  margin-right: 0;
}

.idea .img-list .con {
  text-align: left;
  padding-top: 1.8rem;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.idea .img-list .con01 {
  background: url("../assets/img/eve-ln-01.png");
  background-size: cover;
}

.idea .img-list .con02 {
  background: url("../assets/img/eve-ln-02.png");
  background-size: cover;
}

.idea .img-list .con03 {
  background: url("../assets/img/eve-ln-03.png");
  background-size: cover;
}

.idea .img-list .con04 {
  background: url("../assets/img/eve-ln-04.png");
  background-size: cover;
}

/* 赛事理念  分享、 */
.idea .img-list .txt01 {
  font-size: 0.24rem;
  /* margin-bottom: 0.17rem !important; */
  margin: 0 !important;
  padding-bottom: 0.1rem;
}

.idea .img-list .txt02 {
  font-size: 0.15rem;
  /* font-size: 0.16rem; */
  margin-bottom: 0;
  /* 多行文本 溢出隐藏显示三点... */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.idea .flot img {
  width: 0.25rem;
  height: 0.22rem;
  margin-right: 0.1rem;
  margin-top: 0.05rem;
}

.idea .flot {
  display: flex;
}

.course {
  margin-top: 1rem;
  /* height: 3rem;
    background: pink; */
  text-align: center;
  margin-bottom: 1rem;
}

.course .xh,
.course h3 {
  font-size: 0.4rem;
  color: #333;
  font-weight: bold;
  line-height: 0.8rem;
}

.course img {
  width: 11rem;
  height: 2.53rem;
  margin-top: 0.3rem;
}

.footer {
  height: 5.41rem;
  background-color: #4d4d4d;
  margin-top: 1.66rem;
  padding: 0.4rem 0 0 4.1rem;
}

.footer div,
.footer p {
  color: #fff;
}

.footer .tit,
.footer .me {
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 0.58rem;
}

.footer .info01 {
  margin-top: 0.8rem;
  margin-bottom: 0.6rem;
  font-size: 0.14rem;
  line-height: 0.23rem;
}

.footer .info02 {
  font-size: 0.14rem;
  line-height: 0.23rem;
}
</style>
