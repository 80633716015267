<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="user-content">
      <span style="font-size: 32px">Terms of Use</span>
      <div><strong>Accept the terms</strong></div>
      <div>
        By logging in to this site, you are committed to reading, understanding
        and accepting the following terms of use. If you do not understand or do
        not accept any of these contents, please leave this page
        immediately. Matatalab reserves the right to update and modify these
        Terms of Use at any time without notice. Matatalab reserves the right to
        take legal action and requires reasonable compensation for any breach of
        these Terms of Use.
      </div>
      <div><strong>Copyright</strong></div>
      <div>
        Unless otherwise stated, the copyright of all contents of this website
        belongs to Matatalab. No part of this website may be reproduced,
        distributed, photocopied, displayed, linked, or transmitted by any other
        person without the written permission of Matatalab or any other
        copyrighted third party, uploaded to other servers by way of hyperlinks
        , Remain in the information retrieval system, or download or copy for
        any commercial purpose. If you download or copy the content of this
        website for non-commercial purposes, you should not modify any content
        during its use, and you should retain the content and form of the
        copyright statement or other ownership statement contained in the
        original.
      </div>
      <div><strong>Trademark rights</strong></div>
      <div>
        Unless otherwise stated, all trademarks, logos, logos, or other marks
        displayed, referred to or otherwise used on this site belong to
        Matatalab. The above trademarks and logos may not be used in any way
        without the written permission of Matatalab or a trademark licensed by
        third parties.
      </div>
      <div><strong>Statement</strong></div>
      <div>
        This site is for content use only and is provided for your convenience.
        Matatalab strives to provide accurate information without liability for
        the accuracy of the information. Matatalab reserves the right to modify
        the contents or the product information without notice.
      </div>
      <div>
        All content on this site is provided in good faith and Matatalab makes
        no representations or warranties with respect to the content of this
        site and Matatalab specifically disclaims any warranty or commitment
        whatsoever of the content of this site to the fullest extent permitted
        by law, Express or implied, including the description of the product’s
        performance, suitability, and legitimacy.
      </div>

      <div><strong>Limitation of Liability</strong></div>
      <div>
        Neither Matatalab nor its affiliates, affiliates, directors,
        representatives, employees or other representatives make any damages
        whatsoever for any loss whatsoever, including direct, indirect, special,
        incidental, consequential damages, arising out of the use of this
        website, the use of the website or the content of the website
        responsibility.
      </div>
      <div><strong>Product supply</strong></div>
      <div>
        The products, services, etc. displayed on this site may differ from
        those in other countries or regions. If you need specific product or
        service information, contact your local dealer.
      </div>
      <div><strong>Third-party links</strong></div>
      <div>
        For your convenience, this website may contain links to third party
        websites, and Matatalab is not responsible for any content on third
        party websites. When using third-party websites, you may need to review
        and accept its terms of use. In addition, third party links do not imply
        that Matatalab endorses any product or service on the website.
      </div>
      <div><strong>Access is password protected / secure</strong></div>
      <div>
        Only authorized users can access and use this site’s password protected
        secure area. Unauthorized users attempting to visit these areas may be
        held legally responsible.
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "./../../assets/css/index.css";
import "./../../assets/css/common.css";
import "./../../assets/js/action";
import navigation from "./../../components/navigation.vue";
import footers from "./../../components/footers.vue";
export default {
  components: {
    navigation,
    footers,
  },
};
</script>

<style>
.user-content {
  width: 10rem;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 2;
}
</style>