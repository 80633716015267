// zh
export default {
 password: { 
    retrieve: '找回密码',
    reg_mobile: '请输入注册手机号',
    rcorrect_mobile : '请输入正确的手机号!',
    ver_code: '请输入验证码',
    getcode: '获取验证码',
    seconds: '秒',
    enter_new_pass: '请输入新密码',
    digits_pass: '密码为6-16位英文加数字',
    again_password: '再次输入新密码',
    submit: '提交',
    modifiy_succee: '修改成功',
    empty:'邮箱名不能为空',
    
  }
};
