<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="user-content">
      <span style="font-size: 32px">隐私政策</span>
      <div>
        深圳市玛塔创想科技有限公司是依据中华人民共和国法律成立的有限责任公司（以下简称“玛塔创想”或“我们”）。我们重视维护和保护用户的个人信息。您在使用玛塔创想的产品或服务（包括网站 https://www.matatalab.com 或其子域名网站、玛塔赛事平台 等任一网站或应用程序）时，我们可能会收集和使用您的个人信息。本《隐私权政策》旨在向您说明我们如何收集、储存、使用或分享您的个人信息。希望您仔细阅读本《隐私权政策》，以便在需要的时候，能让您作出合适的选择。
      </div>
      <div>
        您使用或继续使用玛塔创想的产品或服务，即意味着您同意我们按照本《隐私权政策》收集、储存、使用和分享您的个人信息。
      </div>
      <div>最近更新日期：2021年08月30日。</div>
      <div>公司地址地址：深圳市南山区沙河西路3151号健兴科技大厦B座504</div>
      <div>如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：</div>
      <div>电子邮件：mwrc@matatalab.com</div>
      <div><strong>本政策将帮助您了解以下内容：</strong></div>
      <div>1、我们如何收集和使用您的个人信息</div>
      <div>2、我们如何使用Cookie和同类技术</div>
      <div>3、我们如何共享、转让、公开披露您的个人信息</div>
      <div>4、我们如何保护您的个人信息</div>
      <div>5、您的权利</div>
      <div>6、我们如何处理儿童的个人信息</div>
      <div>7、您的个人信息如何在全球范围转移</div>
      <div>8、本政策如何更新</div>
      <div>9、如何联系我们</div>
      <div>
        玛塔创想深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，玛塔创想承诺，我们将按相应的安全标准，采取相应的安全保护措施来保护您的个人信息。
        请在使用我们的产品(或服务)前，仔细阅读并了解本《隐私政策》。
      </div>
      <div><strong> 一、我们如何收集和使用您的个人信息</strong></div>
      <div>
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
      </div>
      <div>玛塔创想仅会出于本政策所述的以下目的，收集和使用您的个人信息：</div>
      <div><strong>（一）为您提供软件编程服务</strong></div>
      <div>
        1. 您在注册账号时填写的信息。当您使用我们的产品或服务时，您可以直接通过邮箱登录，或者您可以向我们提供个人信息，其中包括您的用户名和密码，您还可以选择在自己的账号中添加邮箱、电话号码、第三方账号（微信和QQ）、学号注册登录网页或APP。
      </div>
      <div>
        2. 您通过我们的客服或参加玛塔创想举办的活动时所提交的信息。您参与线上活动时填写的调查问卷中可能包含您的姓名、电话、电子邮箱、学生工号等。
      </div>
      <div>
        3. 您通过其他方式向所提交的信息。当您使用我们的服务时，可以自愿填写昵称、性别、生日和所在地信息。可能会利用这些信息建立用户画像，以便为您提供更加准确和个性化的产品服务。
      </div>
      <div>
        4. 操作日志信息。当您使用玛塔创想的线上产品或服务时，系统可能通过cookies或其他方式自动收集某些信息作为有关网络日志保存。收集此类信息的目的在于：通过数据统计改进和优化产品体验、保障服务稳定和网络安全。此类信息包含：您使用APP时的详细使用情况，您登录网页时的浏览信息，您的产品服务使用记录、网络状态、蓝牙权限、使用语言、访问日期和时间、位置权限、存储权限、语音权限、图像获取（人脸识别的语句块）。
      </div>
      <div><strong> （二）为您推送和展示定制内容</strong></div>
      <div>
        基于收集到的信息，我们可以向您推荐您可能感兴趣的内容，包括但不限于向您推荐、展示您的编程内容，用户问卷调查，个性化广告等。
      </div>
      <div><strong> （三）持续开发和优化产品服务</strong></div>
      <div>
        我们使用收集到的信息保障我们的业务运营，例如评估、维护和改进产品和服务的性能，当软件系统发生故障时，玛塔创想开放平台会记录和分析系统故障时产生的信息，优化平台的相关服务。为迭代和开发新的产品和服务，我们可能对收集的信息加以统计分析辅助决策，但形成的数据分析结果不包含您的任何身份识别信息。例如玛塔赛事平台在提供某些功能或服务中邀请用户参与活动，加入活动的用户，玛塔赛事平台会根据需要对产品或服务的各项功能使用情况进行统计，这样可以通过分析统计数据提高产品或服务质量，推出对用户有帮助的创新产品或服务提升。如您不想参与该计划，可以选择关闭相关产品/服务中的相关功能。
      </div>
      <div>
        当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。
      </div>
      <div>
        当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      </div>
      <div><strong>二、我们如何使用Cookie和同类技术</strong></div>
      <div><strong>（一）Cookie</strong></div>
      <div>
        为确保软件运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好等数据。我们不会将Cookie用于本政策所述目的之外的任何用途。
      </div>
      <div><strong>（二）网站信标和像素标签</strong></div>
      <div>
        除Cookie外，我们还会在网站和APP上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。
      </div>
      <div><strong>（三）蓝牙</strong></div>
      <div>
        蓝牙技术是一种无线数据和语音通信开放的全球规范，它是基于低成本的近距离无线连接，为固定和移动设备建立通信环境的一种特殊的近距离无线技术连接。 当你使用玛塔创想的产品或服务时，需要打开设备蓝牙权限，连接产品。
      </div>
      <div><strong>三、我们如何共享、转让、公开披露您的个人信息</strong></div>
      <div><strong>（一）共享</strong></div>
      <div>
        我们不会与玛塔创想以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
      </div>
      <div>
        1. 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
      </div>
      <div>
        2. 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
      </div>
      <div>
        3. 与我们的附属公司共享：您的个人信息可能会与玛塔创想的附属公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。附属公司如要改变个人信息的处理目的，将再次征求您的授权同意。。
      </div>
      <div>
        对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      </div>
      <div><strong> （二）转让</strong></div>
      <div>
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      </div>
      <div>
        1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
      </div>
      <div>
        2. 在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </div>
      <div><strong> （三）公开披露</strong></div>
      <div>我们仅会在以下情况下，公开披露您的个人信息：</div>
      <div>1. 获得您明确同意后；</div>
      <div>
        2. 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
      </div>
      <div><strong> 四、我们如何保护您的个人信息</strong></div>
      <div>
        1. 我们已使用安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据(如信用卡信息)时受SSL加密保护；我们同时对软件APP提供安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      </div>
      <div>
        2. 我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
      </div>
      <div>
        3. 互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他APP用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
      </div>
      <div>
        4. 互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。我们提醒您知悉可能遭遇的互联网风险有：我们的物理、技术、或管理防护设施遭到破坏，可能导致信息被非授权访问、公开披露、篡改、或毁坏。
      </div>
      <div>
        5. 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </div>
      <div><strong>五、您的权利</strong></div>
      <div><strong>（一）访问您的个人信息</strong></div>
      <div>
        您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
      </div>
      <div>
        账户信息——如果您希望访问或编辑您的账户中的个人资料信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问APP主页面执行此类操作。
      </div>
      <div>
        对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电子邮件至mwrc@matatalab.com。
      </div>
      <div><strong>（二）更正您的个人信息</strong></div>
      <div>
        当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过随时使用我们的Web表单联系，或发送电子邮件至mwrc@matatalab.com提出更正申请。我们将在15天内回复您的更正请求。
      </div>
      <div><strong>（三）删除您的个人信息</strong></div>
      <div>在以下情形中，您可以向我们提出删除个人信息的请求：</div>
      <div>1. 如果我们处理个人信息的行为违反法律法规；</div>
      <div>2. 如果我们收集、使用您的个人信息，却未征得您的同意；</div>
      <div>3. 如果我们处理个人信息的行为违反了与您的约定；</div>
      <div>4. 如果您不再使用我们的产品或服务，或您注销了账号；</div>
      <div>5. 如果我们不再为您提供产品或服务。</div>
      <div>
        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
      </div>
      <div>
        当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </div>
      <div><strong>（四）改变您授权同意的范围</strong></div>
      <div>
        每个业务功能需要一些基本的个人信息才能得以完成(见本政策“第一部分”)。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。您可以通过发送信息至邮箱mwrc@matatalab.com告知您的授权。
      </div>
      <div>
        当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
      </div>
      <div><strong>（五）个人信息主体获取个人信息副本</strong></div>
      <div>
        您有权获取您的个人信息副本，您可以通过电子邮箱mwrc@matatalab.com获取您的信息。
      </div>
      <div><strong>（六）约束信息系统自动决策</strong></div>
      <div>
        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
      </div>
      <div><strong>（七）响应您的上述请求</strong></div>
      <div>
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        我们将在15天内做出答复。如您不满意，还可以通过以下途径投诉：发送邮件至mwrc@matatalab.com。
      </div>
      <div>
        对于您合理的请求，我们不收取费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(例如，涉及备份磁带上存放的信息)的请求，我们可能会予以拒绝。
      </div>
      <div>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
      <div>1. 与国家安全、国防安全直接相关的；</div>
      <div>2. 与公共安全、公共卫生、重大公共利益直接相关的；</div>
      <div>3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
      <div>4. 有充分证据表明您存在主观恶意或滥用权利的；</div>
      <div>
        5. 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
      </div>
      <div>6. 涉及商业秘密的。</div>
      <div><strong>六、我们如何处理儿童的个人信息</strong></div>
      <div>
        我们的产品、网站和服务同时面向学生。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。
      </div>
      <div>
        对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
      </div>
      <div>
        尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。
        如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会尽快删除相关数据。
      </div>
      <div><strong>七、您的个人信息如何在全球范围转移</strong></div>
      <div>
        原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
        由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家／地区的境外管辖区，或者受到来自这些管辖区的访问。
      </div>
      <div>
        此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
      </div>
      <div><strong>八、本政策如何更新</strong></div>
      <div>我们的隐私政策可能变更。</div>
      <div>
        未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
      </div>
      <div>
        对于重大变更，我们还会提供更为显著的通知(包括对于某些服务，我们会通过电子邮件发送通知，说明隐私政策的具体变更内容)。
      </div>
      <div>本政策所指的重大变更包括但不限于：</div>
      <div>
        1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </div>
      <div>
        2. 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </div>
      <div>3. 个人信息共享、转让或公开披露的主要对象发生变化；</div>
      <div>4. 您参与个人信息处理方面的权利及其行使方式发生重大变化；</div>
      <div>
        5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </div>
      <div>6. 个人信息安全影响评估报告表明存在高风险时。</div>
      <div>我们还会将本政策的旧版本存档，供您查阅。</div>
      <div><strong>九、如何联系我们</strong></div>
      <div>
        如果您对本隐私政策有任何疑问、意见或建议，通过邮件方式与我们联系：
      </div>
      <div>mwrc@matatalab.com。</div>
      <div>
        我们设立了个人信息保护专职部门(或个人信息保护专员)，您可以通过以下方式与其联系：
      </div>
      <div>mwrc@matatalab.com。</div>
      <div>
        如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向【玛塔创想住所地有管辖权的法院提起诉讼】。
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
export default {
  components: {
    navigation,
    footers,
  },
};
</script>

<style>
</style>