// zh
export default {
    person: {
        event: "我的赛事",
        account: '账户管理',
        select: "请选择",
        name: "赛事名称",
        team: "队伍名称",
        type: "赛事类型",
        activity: "活动时间",
        status: "当前状态",
        operation: "操作",
        online: '线上赛',
        offline: '线下赛',

        select_all: '全选',
        to: "至",
        approved: "审核中",
        work: "提交作品",
        pproved: "审核通过",
        review: "评审中",
        failed: "审核失败",
        ended: "审核结束",
        no_status: "暂无状态",

        details: '参赛详情',
        cancel: "取消报名",
        share_voting: "分享投票",
        enroll: "重新报名",
        results: "评审结果",
        scan: "扫码分享，让好友为你助力",
        // 查看参赛详情
        welcome: "欢迎参加玛塔编程赛",
        even: "赛事",
        home: "家庭用户",
        institutional: "机构用户",
        kndergarten: "幼儿园",
        primary: "小学",
        code: "参赛邀请码",
        team_name: "队伍名称",
        mata_1: "玛塔队1",
        name_f: "姓名 :",
        gender: "性别 :",
        id_number: "身份证号码 : ",
        organization: "机构名称",
        mata_event: " 玛塔赛事",
        //家庭用户
        parent_name: "家长姓名",
        Ms_Zhang: "张女士",
        phone: "家长电话",
        //家庭外其他用户类型
        tel: "指导老师",
        instructor_tel: "指导老师电话",
        address: "奖品邮寄地址",
        shenzhen: "深圳市南山科技园105",
        detailed_add: "详细地址",
        // 取消弹框
        want_cancel: "您确定要取消报名吗?",
        prompt: "提示",
        OK: "确定",
        cancel1: "取消",
        cancel_succeed: "取消报名成功!",
        wait: "分享投票时间还未开始，请您耐心等待！",
        time_end: "分享投票时间已结束！",
        wait_patiently: "提交作品时间还未开始，请您耐心等待！",
        submit_end: "提交作品时间已结束！",

    },
};
