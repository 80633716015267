<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <!-- <div class="detail-info flex f-ac f-jc">
          <div class="wrap">
            <div class="tit" style="cursor: default">{{ $t("news.news") }}</div>
          </div>
        </div> -->
        <img :src="bannerImg" class="img" />
      </div>
    </div>
    <div class="wrap clearfix wrap2 ql-editor">
      <div
        class="page-newsDetails-list mt100"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="title">{{ item.title }}</div>
        <div class="newsDtails ">
          <div class="txt rich" v-html="item.content"></div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import "../assets/css/quill.css";
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { getBanner, newss } from "../api/news";
export default {
  data() {
    return {
      newsDetail: {},
      list: [],
      bannerImg: "",
    };
  },
  components: {
    navigation,
    footers,
  },
  created() {
    let ops = this.$route.query.id;
    if (!ops) {
      this.$router.push({
        name: "news",
      });
      return;
    }
    // 传递参数的类型
    this.getBannerImg();
    this.getList(ops);
  },
  methods: {
    getBannerImg() {
      let data = { type: 2 };
      getBanner(data).then((res) => {
        if (res.code == 200) {
          this.bannerImg = res.data;
        }
      });
    },
    getList(ops) {
      // let ops = this.$route.query.id;
      // localStorage.setItem("newsDetails", id);
      newss(ops).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          console.log(this.list);
          this.total = res.total;
        } else {
        }
      });
    },
  },
};
</script>

<style>
.wrap2 {
  margin-top: 1rem;
}
.page-pages a:hover {
  cursor: pointer !important;
}
.page-news-list .img {
  border-radius: 0.04rem;
}
.rich img{ display:block; max-width: 100%;margin: auto;}
.rich video{ display:block; width: 100%;height: 600px;margin: auto;}
</style>
