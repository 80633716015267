<template>
  <div class="Navigation">
    <footer class="footer">
      <div class="wrap">
        <div class="flex f-jb">
          <div class="flex f-ac nav">
            <a href="https://matatalab.com/zh-hans" target="_blank" v-if="this.lang == 'zh-CN'">{{
              $t("footer.about_matalab")
            }}</a>
            <a href="https://www.matatastudio.com" target="_blank" v-if="this.lang == 'en-CN'">{{
              $t("footer.about_matalab")
            }}</a>
            <span></span>
            <!-- <a :href="matchSign" @click="matchSignBtn()">报名参赛</a> -->
            <a @click="matchSignBtn()">{{ $t("footer.sign_up") }}</a>
            <span></span>
            <div class="wechat">
              <a>{{ $t("footer.contact_us") }}</a>
              <div class="wxcode" v-if="this.lang == 'zh-CN' ? 'wxcode' : 'wxcodeen'">
                <h2>{{ $t("footer.contact") }}</h2>
                <p>{{ $t("footer.email") }}</p>
                <img v-show="this.lang == 'zh-CN'" src="https://static.matatalab.com/admin/img/2021-11-24/code.png"
                  alt="" />
              </div>
            </div>
          </div>
          <div class="footer-link flex">
            <a class="" href="https://twitter.com/MatataStudioEDU" target="_blank"><img
                src="../assets/img/bird.png" /></a>
            <!-- <a class="" @click="dialogTableVisible = true"><img src="../assets/img/wx.png" /></a> -->
            <a href="https://www.linkedin.com/company/matatastudio" class="" target="_blank"><img
                src="../assets/img/in.png" /></a>
            <a href="https://www.facebook.com/MatataStudio" class="" target="_blank"><img
                src="../assets/img/f.png" /></a>
            <a href="https://www.instagram.com/matatastudioedu" class="" target="_blank"><img
                src="../assets/img/ins.png" /></a>
          </div>
          <el-dialog :visible.sync="dialogTableVisible" :show-close="false">
            <div class="modal-body">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="py-2">
                    <div class="text-center">
                      <img
                        src="https://fast.matatalab.com/cdn/ff/3IaOjgGJ-7VWBvZqkpE0JuiQLK3xPRKrnHwxvD5tOS4/1602497083/public/2020-10/C%E7%AB%AF15cm.jpg"
                        alt="" />
                    </div>
                    <div class="text-center">
                      <p>
                        <strong>家庭端公众号</strong><br />玛塔创想编程机器人
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="py-2">
                    <div class="text-center">
                      <img
                        src="https://fast.matatalab.com/cdn/ff/qfI4-2pmO_Tae8FKn7V5yEKGfSbpbzm7WrLVUxcGYao/1602497059/public/2020-10/B%E7%AB%AF15cm.jpg"
                        alt="" />
                    </div>
                    <div class="text-center">
                      <p>
                        <strong>教育端公众号</strong><br />玛塔创想教育服务平台
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>

        <div class="footer-copy">
          {{ $t("footer.copyright") }}
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
export default {
  name: "footers",
  props: {},
  data() {
    return {
      matchSign: "",
      dialogTableVisible: false,
      flag: false,
      lang: "",
    };
  },
  created() {
    this.lang = localStorage.getItem("lang");
  },
  methods: {
    matchSignBtn() {
      this.matchSign = this.$router.push("/matchSign");
      if (this.$route.path == "/matchSign") {
        document.documentElement.scrollTop = 0; //跳回顶部
      }
    },
  },
};
</script>
<style>
.modal-body {
  position: relative;
  flex: 1 1 auto;
  /* padding: 1rem; */
}

.row {
  display: flex;
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-12 {
  padding-right: 15px;
  padding-left: 15px;
}

.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.text-center {
  text-align: center;
}

.text-center img {
  width: 2rem;
}

.el-dialog__body {
  padding: 0 !important;
}

@keyframes myMou1 {
  0% {
    margin-left: 50px;
  }

  100% {
    margin-left: 0px;
  }
}

.wxcode {
  animation: myMou1 0.5s;
  padding: 18px;
  width: 250px;
  background: #ffff;
  border-radius: 18px;
  text-align: center;
  display: none;
  position: absolute;
  left: 330px;
  bottom: 30px;
}

.wxcode img {
  margin-top: 10px;
  width: 150px;
  height: 150px;
}

.wxcode h2 {
  margin-top: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}

.wxcode p {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #747f94;
}

.wechat {
  display: flex;
  justify-content: center;
}

.wechat a {
  position: 50px;
}

.wechat:hover .wxcode {
  display: block;
}
</style>