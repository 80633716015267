<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <!-- <div class="detail-info flex f-ac f-jc"> -->
        <!-- <div class="wrap">
            <div class="tit cursorDefault">{{ $t("down.down") }}</div>
          </div> -->
        <!-- </div> -->
        <!-- <img src="../assets/img/down.jpg" class="img" /> -->
        <img :src="bannerImg" class="img" />
      </div>
    </div>
    <div class="wraps">
      <div class="wrap clearfix" v-for="(item, idx) in list" :key="idx">
        <div class="page-down-list mt100">
          <div class="item flex f-ac">
            <div class="info flex-item">
              <div class="tit flex f-ac cursorDefault">
                {{ item.name }}
                <span v-show="item.type == 0">{{ $t("down.top") }}</span>
              </div>
              <div class="time">{{ item.creationTime }}</div>
            </div>
            <a
              class="btn-down"
              :download="[item.address]"
              :href="[item.address]"
              >{{ $t("down.download") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
      style="margin-bottom:.2rem;"
    >
    </el-pagination>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { download, getBanner } from "@/api/down";
export default {
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //多少页
      total: 10, //多少条
      list: [],
      bannerImg: null,
    };
  },
  components: {
    navigation,
    footers,
  },
  created() {
    this.getList();
    this.getBannerImg();
  },
  methods: {
    // banner图
    getBannerImg() {
      let data = { type: 3 };
      getBanner(data).then((res) => {
        if (res.code == 200) {
          this.bannerImg = res.data;
        }
      });
    },
    getList() {
      let data = {
        page: this.currentPage,
        size: this.pageSize,
      };
      download(data).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.total = res.total;
        } else {
        }
      });
    },

    // 分页事件
    // 每页多少条
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  },
};
</script>

<style>
.tit:hover,
.btn-down:hover {
  cursor: pointer;
}
.wraps {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
