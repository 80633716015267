// zh
export default {
  index: {
    competition_list: "比赛列表",
    news_info: '新闻资讯',
    review: "赛事回顾",
    
  }
};

