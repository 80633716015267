<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <!-- <div class="detail-info flex f-ac f-jc">
          <div class="wrap">
            <div class="tit"  style="cursor:default">{{ $t("faq.question") }}</div>
          </div> -->
        <!-- </div> -->
        <!-- <img src="../assets/img/faq.jpg" class="img" /> -->
        <img :src="bannerImg" class="img" />
      </div>
    </div>

    <div class="wrap page-faq clearfix">
      <div class="sign-tab flex begin">
        <a class="flex-item" :class="{ active: onShow1 }" @click="getList(1)">{{
          $t("faq.competite")
        }}</a>
        <a class="flex-item" :class="{ active: onShow2 }" @click="getList(2)">{{
          $t("faq.registration")
        }}</a>
        <a class="flex-item" :class="{ active: onShow3 }" @click="getList(3)">{{
          $t("faq.score")
        }}</a>
        <a class="flex-item" :class="{ active: onShow4 }" @click="getList(4)">{{
          $t("faq.tech")
        }}</a>
        <a class="flex-item" :class="{ active: onShow5 }" @click="getList(5)">{{
          $t("faq.other")
        }}</a>
        <!-- <a class="flex-item" :class="{ active: onShow1 }" @click="getList(1)"
          >新手入门</a
        >
        <a class="flex-item" :class="{ active: onShow2 }" @click="getList(2)"
          >故障调试</a
        >
        <a class="flex-item" :class="{ active: onShow3 }" @click="getList(3)"
          >开关机与充电</a
        >
        <a class="flex-item" :class="{ active: onShow4 }" @click="getList(4)"
          >如何编程</a
        >
        <a class="flex-item" :class="{ active: onShow5 }" @click="getList(5)"
          >如何重新配对</a
        >
        <a class="flex-item" :class="{ active: onShow6 }" @click="getList(6)"
          >售后与保修</a
        >
        <a class="flex-item" :class="{ active: onShow7 }" @click="getList(7)"
          >其他问题</a
        > -->
      </div>

      <div class="page-faq-list">
        <dl class="item" v-for="(item, index) in question" :key="index">
          <dt v-html="item.question">
            <!-- {{ item.question }} -->
          </dt>
          <dd v-html="item.answer">
            <!-- {{ item.answer }} -->
          </dd>
        </dl>
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import { question, getBanner } from "../api/faq";
export default {
  data() {
    return {
      question: [],
      onShow1: true,
      onShow2: false,
      onShow3: false,
      onShow4: false,
      onShow5: false,
      onShow6: false,
      onShow7: false,
      bannerImg: null,
    };
  },
  components: {
    navigation,
    footers,
  },
  created() {
    this.getList();
    localStorage.setItem("faqNum", 1);
    this.getBannerImg();
  },
  methods: {
    // banner图
    getBannerImg() {
      let data = { type: 4 };
      getBanner(data).then((res) => {
        if (res.code == 200) {
          this.bannerImg = res.data;
        }
      });
    },
    getList(num) {
      if (num == 1) {
        this.onShow1 = true;
        this.onShow2 = false;
        this.onShow3 = false;
        this.onShow4 = false;
        this.onShow5 = false;
        this.onShow6 = false;
        this.onShow7 = false;
        localStorage.setItem("faqNum", 1);
      } else if (num == 2) {
        this.onShow1 = false;
        this.onShow2 = true;
        this.onShow3 = false;
        this.onShow4 = false;
        this.onShow5 = false;
        this.onShow6 = false;
        this.onShow7 = false;
        localStorage.setItem("faqNum", 2);
      } else if (num == 3) {
        this.onShow1 = false;
        this.onShow2 = false;
        this.onShow3 = true;
        this.onShow4 = false;
        this.onShow5 = false;
        this.onShow6 = false;
        this.onShow7 = false;
        localStorage.setItem("faqNum", 3);
      } else if (num == 4) {
        this.onShow1 = false;
        this.onShow2 = false;
        this.onShow3 = false;
        this.onShow4 = true;
        this.onShow5 = false;
        this.onShow6 = false;
        this.onShow7 = false;
        localStorage.setItem("faqNum", 4);
      } else if (num == 5) {
        this.onShow1 = false;
        this.onShow2 = false;
        this.onShow3 = false;
        this.onShow4 = false;
        this.onShow5 = true;
        this.onShow6 = false;
        this.onShow7 = false;
        localStorage.setItem("faqNum", 5);
      }
      //  else if (num == 6) {
      //   this.onShow1 = false;
      //   this.onShow2 = false;
      //   this.onShow3 = false;
      //   this.onShow4 = false;
      //   this.onShow5 = false;
      //   this.onShow6 = true;
      //   this.onShow7 = false;
      //   localStorage.setItem("faqNum",6)
      // } else if (num == 7) {
      //   this.onShow1 = false;
      //   this.onShow2 = false;
      //   this.onShow3 = false;
      //   this.onShow4 = false;
      //   this.onShow5 = false;
      //   this.onShow6 = false;
      //   this.onShow7 = true;
      //   localStorage.setItem("faqNum",7)
      // }
      if (num == undefined) num = 1;
      let data = { type: num };
      question(data).then((res) => {
        this.question = res.data;
      });
    },
  },
};
</script>

<style></style>
