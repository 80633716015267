// zh
export default {
    matchsign: {
        signing_up: "Open For Registration",
        ingame: "In Competition",
        ended: "Registration Expired",
        gameover: "Competition Finished",
        online: "Online Competition",
        offline: "Offline Competition",
        reg_time: "Registration Period:",
        game_time: "Competition Time:",
    },
    matchSigndetail: {
        introduce: "Overview",
        gametime: "Competition Time",
        gametime1: "Competition Time:",
        reg_time: "Registration Period:",
        competi_place: "Competition Venuee",
        online: "Online Competition",
        offline: "Offline Competition",
        fee: "Competition Fee",
        award: "Competition Awards",
        judge: "Competition Judges",
        download: "Downloads",
        down: "Download",
        sign_up: "Sign Up",
        price: " dollars / person",
        userInfoTip: "Complete the user information before registration.",
    },
    reviewDetail: {
        introduce: "OVERVIEW",
        win_list: "WINNER LIST",
        video_works: "EXCELLENT VIDEO",
        more_works: "More Excellent Videos",
        online: "2021 robot programming challenge",
        offline: "2021 robot programming challenge",
        challenge_pro: "2021 robot programming challenge",
        highlights: " Exciting Competition Moment",
        exciting: "More Exciting Competition Moments",
    },
    achievement: {
        name: "name",
        idCard: "idCard",
        score: "score",
        duration: "duration",
        award: "award",
        nameInput: "Please enter your name",
        idCardInput: "Please enter your ID number",
        idCardTip: "Please enter the correct ID number",
        errorTip: "Validation failed",
        query: "query",
        title: "Competition results",
        tips: "Hold down and drag to the far right",
        successTips: "Verification passed",
        creativityScore: "Creativity Score",
        taskScore: "Task Score",
        sumScore: "Total score",
        second: 'second',
        hour: 'hour',
        minute: 'minute',
        searchError: 'The current query personnel have no results. Please check your identity information and re query'
    }
};