<template>
  <div>
    <navigation />
    <!-- <div class="h77"></div> -->
    <div class="bg1 minH flex f-ac f-jc">
      <div class="register">
        <div class="title">{{ $t("password.retrieve") }}</div>
        <div class="register-cont">
          <div class="item flex item-input" v-if="lang =='zh-CN'" >
            <div class="area">+86</div>
            <input
              type="text"
              :placeholder="$t('password.reg_mobile')"
              class="flex-item"
              name=""
              v-model.trim="mobile"
              @blur="animate()"
            />
            <span
              style="
                color: red;
                line-height: 20px;
                margin: 3px;
                white-space: nowrap;
                font-size: 12px;
              "
              v-show="red"
              >{{ $t("password.rcorrect_mobile") }}</span
            >
          </div>
          <!-- 邮件地址 -->
            <div class="item flex item-input" >
            <input
              type="text"
              :placeholder="$t('password.reg_mobile')"
              class="flex-item"
              name=""
              v-model.trim="mobile"
              @blur="animate()"
            />
            <span
              style="
                color: red;
                line-height: 20px;
                margin: 3px;
                white-space: nowrap;
                font-size: 12px;
              "
              v-show="red"
              >{{ $t("password.rcorrect_mobile") }}</span
            >
          </div>
          <div class="item flex item-input">
            <input
              type="text"
              :placeholder="$t('password.ver_code')"
              class="flex-item"
              name=""
              v-model.trim="credentials"
            />
            <div class="btn-again" v-show="show" @click="getCode">
              {{ $t("password.getcode") }}
            </div>
            <div class="btn-again disabled" v-show="!show">
              {{ count }} {{ $t("password.seconds") }}
            </div>
          </div>
          <div class="item flex item-input">
            <input
              :type="pwdType"
              :placeholder="$t('password.enter_new_pass')"
              class="flex-item"
              name=""
              v-model.trim="newpass1"
              @blur="passwordChexk1()"
            />
            <span
              style="
                color: red;
                line-height: 20px;
                margin: 3px;
                white-space: nowrap;
                font-size: 12px;
              "
              v-show="redPWD1"
              >{{ $t("password.digits_pass") }}</span
            >
            <img
              :src="seen ? openeye : nopeneye"
              class="eye"
              alt=""
              @click="changeType()"
            />
          </div>
          <div class="item flex item-input">
            <input
              :type="pwdTypes"
              :placeholder="$t('password.again_password')"
              class="flex-item"
              name=""
              v-model.trim="newpass2"
              @blur="passwordChexk2()"
            />
            <span
              style="
                color: red;
                line-height: 20px;
                margin: 3px;
                white-space: nowrap;
                font-size: 12px;
              "
              v-show="redPWD2"
              >{{ $t("password.digits_pass") }}</span
            >
            <img
              :src="seen02 ? openeye02 : nopeneye02"
              class="eye"
              alt=""
              @click="changeTypes()"
            />
          </div>
          <div class="btn-register flex f-ac f-jc mt30" @click="forgotPwds()">
            {{ $t("password.submit") }}
          </div>
        </div>
        <div class="alerts" v-show="alerts">
          <div class="alert">
            <img src="../assets/img/rg1.png" alt="" />
            <div>{{ $t("password.modifiy_succee") }}</div>
          </div>
        </div>
      </div>
    </div>
    <tipsError :errorText="errorText01" v-show="errorShow01"></tipsError>
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import tipsError from "../components/tips-error.vue"; //失败提示弹框
import { forgotPwd, code } from "../api/login";
import { encryptedData } from "../utils/rsa";
export default {
  data() {
    return {
      errorText01: "",
      errorShow01: false,
      alerts: false,
      show: true,
      count: "",
      timer: null,
      newpass1: "",
      newpass2: "",
      mobile: "",
      credentials: "",
      code: "",
      openeye: require("../assets/img/eye-open.png"), //图片地址
      nopeneye: require("../assets/img/eye-close.png"), //图片地址
      openeye02: require("../assets/img/eye-open.png"), //图片地址
      nopeneye02: require("../assets/img/eye-close.png"), //图片地址
      pwdType: "password",
      pwdTypes: "password",
      seen: null,
      seen02: null,
      red: false,
      redPWD1: false,
      redPWD2: false,
      lang:""
    };
  },
  components: {
    navigation,
    footers,
    tipsError,
  },
    watch: {
    getLang(lang) {
      localStorage.getItem("lang", lang);
      this.lang = lang;
    },
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
  },
  created() {
    // 手机号
    this.mobile = localStorage.getItem("infoMobile01");
  },
  methods: {
    //手机号验证
    animate() {
      var re = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let str = this.mobile;
      if (re.test(str)) {
        this.red = false;
      } else {
        this.red = true;
      }
      // 如果手机号为空，则不出现红字提示
      if (this.mobile == "") {
        this.red = false;
      }
    },
    // 密码校验  做限制6-16位英文加数字
    // 新密码
    passwordChexk1() {
      var rePwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      var pwd = this.newpass1;
      if (rePwd.test(pwd)) {
        this.redPWD1 = false;
      } else {
        this.redPWD1 = true;
      }
    },
    // 再次输入密码
    passwordChexk2() {
      var rePwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      var pwd = this.newpass2;
      if (rePwd.test(pwd)) {
        this.redPWD2 = false;
      } else {
        this.redPWD2 = true;
      }
    },
    // 获取验证码
    getCode() {
      // 如果手机号为空 return
      if (this.mobile == "") {
        this.$message.error("请输入手机号");
        return;
      }
      // 如果手机号不合法 return
      if (this.red) {
        return;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      let data = {
        mobile: this.mobile,
        type: "2",
      };
      code(data).then((res) => {});
    },
    // 眼睛变化
    changeType() {
      this.pwdType = this.pwdType === "password" ? "text" : "password";
      this.seen = !this.seen; //小眼睛的变化
    },
    changeTypes() {
      this.pwdTypes = this.pwdTypes === "password" ? "text" : "password";
      this.seen02 = !this.seen02; //小眼睛的变化
    },
    // 提交
    forgotPwds() {
      // 如果手机号为空 return
      if (this.mobile == "") {
        this.$message.error("请输入手机号");
        return;
      }
      // 如果手机号不合法 return
      if (this.red) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      // 密码不合法 return
      if (this.redPWD1 || this.redPWD2) {
        this.$message.error("请输入6-16位英文加数字密码");
        return;
      }
      if (this.newpass1 != this.newpass2) {
        alert("两次输入的密码不一致");
      } else {
        let data = {
          code: this.credentials,
          mobile: this.mobile,
          // password: this.newpass2,
          password: encryptedData(this.newpass2),
        };
        forgotPwd(data).then((res) => {
          if (res.code === 200) {
            this.alerts = true;
            if (this.alerts) {
              setTimeout(() => {
                this.alerts = false;
                this.$router.push("/login");
              }, 2000);
            }
          } else {
            this.errorText01 = res.msg;
            this.errorShow01 = true;
            setTimeout(() => {
              this.errorShow01 = false;
            }, 2000);
          }
        });
      }
    },
  },
  destroyed() {
    localStorage.setItem("infoMobile01", this.mobile);
  },
};
</script>

<style>
</style>