<template>
  <div class="index">
    <navigation />
    <div class="h77"></div>
    <div class="banner banner-matchReview2">
      <!-- 轮播图 -->
      <el-carousel>
        <el-carousel-item v-for="(ite, idx) in masterMap" :key="idx">
          <img
            :src="ite.path"
            alt=""
            @click="chained_address(ite.chainedAddress)"
          />
          <!-- <div class="cont" style="width:100%;height:100%;">
            <div class="tit cursorDefault">玛塔机器人编程赛</div>
            <div class="stit">match review</div>
            <a class="btn" target="_blank" :href="ite.chained_address"
              >查看更多</a
            >
          </div> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="index-news flex">
      <a class="flex-item" @click="add1()">
        <img src="../assets/img/news1.png" />
        <div class="tit flex f-ac ellipsis">
          {{ $t("homePage.events_2023") }}
          <span class="icon"></span>
        </div>
      </a>
      <a class="flex-item" @click="add2()">
        <img src="../assets/img/news2.png" />
        <div class="tit flex f-ac ellipsis">
          {{ $t("index.competition_list") }}
          <span class="icon"></span>
        </div>
      </a>
      <a class="flex-item" @click="add3()">
        <img src="../assets/img/news3.png" />
        <div class="tit flex f-ac ellipsis">
          {{ $t("index.news_info") }}
          <span class="icon"></span>
        </div>
      </a>
      <a class="flex-item" @click="add4()">
        <img src="../assets/img/news4.png" />
        <div class="tit flex f-ac ellipsis">
          {{ $t("index.review") }}
          <span class="icon"></span>
        </div>
      </a>
    </div>

    <div class="index-video videoStyle03" style="">
      <div class="wrap">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        />
      </div>
    </div>

    <div class="index-partner wrap">
      <div class="title">{{ $t("homePage.cooper_institutions") }}</div>
      <div class="partner-list clearfix">
        <a class="item" v-for="(item, idx) in logo" :key="idx">
          <div class="img">
            <img :src="item.logoAddress" class="img begin"  alt=""/>
          </div>
        </a>
      </div>
    </div>
    <!-- <backTop /> -->
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import backTop from "../components/back-top.vue";
import { addArticle } from "@/api/index";
export default {
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            // src: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4", // url地址
            src: "",
          },
        ],
        poster: "",
        // "https://p1.music.126.net/5zs7IvmLv7KahY3BFzUmrg==/109951163635241613.jpg?param=600y500", // 你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
      navigationPicture: [],
      video: [],
      logo: [],
      masterMap: [],
    };
  },
  components: {
    navigation,
    footers,
    backTop,
  },
  created() {
    this.getList();
  },
  methods: {
    returnLang() {
      const bowerLang = (
          navigator.userLanguage || window.navigator.language
      ).toLowerCase();
      if (bowerLang === "zh-cn") return "zh-CN";
      return "en-US";
    },
    getList() {
      addArticle().then((res) => {
        if (res.code == 200) {
          this.masterMap = res.data.masterMap;
          this.navigationPicture = res.data.navigationPicture;
          this.logo = res.data.logo;
          this.video = res.data.video;
          this.playerOptions.sources[0].src = this.video[0].video; //视频地址
          this.playerOptions.poster = this.video[0].pictureAddress;
          // this.playerOptions.poster =
          //   this.video[0].video +
          //   "?x-oss-process=video/snapshot,t_10000,m_fast"; //封面地址
        }
      });
    },
    add1() {
      this.$router.push("/matchSign");
    },
    add2() {
      //   this.$router.push("/matchSign");
      this.$router.push({
        name: "matchSign",
        params: {
          type: 2,
        },
      });
    },
    add3() {
      this.$router.push("/news");
    },
    add4() {
      // this.$router.push("/matchReview2021");
      this.$router.push("/matchReview?year=2023");
    },
    chained_address(address) {
      // console.log(address)
      // address = "http://www.cmbchina.com/corporate/"
      window.open(address);
    },
  },
};
</script>

<style>
/* 视频样式 */
.videoStyle03 .video-js .vjs-volume-panel .vjs-volume-control {
  opacity: 1 !important;
}
.videoStyle03 .vjs-current-time {
  margin-left: 0.6rem !important;
}
.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: transparent !important;
  font-size: 3.5em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
  margin-left: -1em !important;
  width: 2em !important;
  outline: none;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.index-video {
  background: url("../assets/img/video-bg.png") no-repeat center;
  background-size: cover;
}

.index-partner .partner-list .item:nth-child(4n) {
  margin-right: 0;
}
.index-partner .partner-list .item:nth-child(5n) {
  /* margin-right: 1.2rem; */
  margin-right: 0.24rem;
}
.partner-list {
  width: 80%;
  margin: 0 auto;
}
</style>
