// zh
export default {
    matchsign: {
        signing_up: "报名中",
        ingame: "比赛中",
        ended: "报名结束",
        gameover: "比赛结束",
        online: "线上赛",
        offline: " 线下赛",
        reg_time: " 报名时间：",
        game_time: " 比赛时间：",
    },

    matchSigndetail: {
        introduce: "赛事介绍",
        gametime: "比赛时间",
        gametime1: "比赛时间:",
        reg_time: "报名时间:",
        competi_place: "比赛地点",
        online: "线上赛",
        offline: "线下赛",
        fee: "比赛费用",
        award: "竞赛奖项",
        judge: "比赛评委",
        download: "相关下载",
        down: "下载",
        sign_up: "我要报名",
        price: "元/人",
        userInfoTip: "完善用户信息后，方可报名。",
    },
    reviewDetail: {
        introduce: "赛事介绍",
        win_list: "获奖名单",
        video_works: "优秀视频作品",
        more_works: "更多优秀视频作品",
        online: "2021机器人编程挑战赛",
        offline: "2021机器人编程挑战赛",
        challenge_pro: "2021机器人编程挑战赛",
        highlights: " 赛事精彩瞬间",
        exciting: "更多赛事精彩瞬间",
    },
    achievement: {
        name: "姓名",
        idCard: "证件号码",
        score: "比赛得分",
        duration: "比赛用时",
        award: "奖项",
        nameInput: "请输入姓名",
        idCardInput: "请输入证件号码",
        idCardTip: "请输入正确的证件号码",
        errorTip: "验证失败",
        query: "查询",
        title: "比赛结果查询",
        tips: "请按住滑块，拖动到最右边",
        successTips: "验证通过",
        creativityScore: "创意得分",
        taskScore: "任务得分",
        sumScore: "总分",
        second: '秒',
        hour: '时',
        minute: '分',
        searchError: '当前查询人员暂无成绩,请检查身份信息无误后重新查询'
    }
};