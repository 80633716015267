<template>
  <div>
    <div class="item flex item-input">
      <input
        type="text"
        :placeholder="$t('loginPage.phone')"
        class="flex-item"
        @input="phoneInput"
        v-model.trim="mobile"
        @blur="animate()"
      />
      <span
        style="
          color: red;
          line-height: 20px;
          margin: 3px;
          white-space: nowrap;
          font-size: 12px;
        "
        v-show="red"
        >请输入正确的手机号!
      </span>
    </div>
    <div></div>
    <div class="item flex item-input" v-show="!iszhangHaoPSD">
      <input
        :type="seen ? 'password' : 'text'"
        :placeholder="$t('loginPage.verification')"
        class="flex-item"
        @input="send_credentials"
        autocomplete="new-password"
        v-model.trim="credentials"
      />
      <div class="btn-again" v-show="show" @click="getCode">获取验证码</div>
      <div class="btn-again disabled" v-show="!show">
        {{ count }} {{ $t("loginPage.m") }}
      </div>
    </div>
    <div class="item flex item-input" v-show="iszhangHaoPSD">
      <input
        :type="seen ? 'password' : 'text'"
        :placeholder="$t('loginPage.pwd')"
        class="flex-item"
        @input="send_pwdInput"
        v-model.trim="passwordsValue"
      />
      <img :src="seen ? openeye : nopeneye" class="eye" @click="seen = !seen" />
    </div>
  </div>
</template>
<script>
import { code } from "../api/login";
export default {
  name: "PhoneLogin",
  props: {
    iszhangHaoPSD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobile: "",
      vcodeValue: "",
      passwordsValue: "",
      openeye: require("../assets/img/eye-open.png"), //图片地址
      nopeneye: require("../assets/img/eye-close.png"), //图片地址
      seen: true, //show 命名和实际意义是反的
      count: "",
      credentials: "",
      show: true,
      red:false
    };
  },
  mounted() {},
  methods: {
    phoneInput() {
      this.$emit("updatePhone", this.mobile);
    },
    send_credentials() {
      this.$emit("updatecredentials", this.credentials);
    },
    send_pwdInput() {
      this.$emit("updatePasswords", this.passwordsValue);
    },
   //手机号验证
    animate() {
      var re = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let str = this.mobile;
      if (re.test(str)) {
        this.red = false;
      } else {
        this.red = true;
      };
      // 如果手机号为空，则不出现红字提示
      if(this.mobile == ""){
        this.red = false;
      }
    },
    //获取验证码
    getCode() {
      // 如果手机号为空 return
      if(this.mobile == ""){
        this.$message.error("请输入手机号")
        return;
      };
      // 如果手机号不合法 return
      if(this.red){
        return;
      };
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      let data = {
        mobile: this.mobile,
        type: "1",
      };
      code(data).then((res) => {});
    },
  }
};
</script>
<style lang="scss" scoped>
</style>
