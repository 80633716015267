<template>
    <div>
    <!-- 返回顶部 -->
        <div v-show="arrowShow" class="arrow" @click="backTop()" style="cursor:pointer;">
          <img src="../assets/img/arrow.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            arrowShow: false,
        }
    },
    mounted(){
        // 监听滚动条
        window.addEventListener("scroll",this.scrollBackTop);
    },
    methods: {
        // 点击图片回到顶部，计时器时为了过度顺滑
        backTop(){
          let that = this;
          let timer = setInterval(() => {
            let ispeed = Math.floor(-that.scrollTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
            if(that.scrollTop === 0){
              clearInterval(timer);
            }
          },16)
        },
        // 为了计算距离顶部的高度，当高度大于XX显示回到顶部的图标，小于则隐藏
        scrollBackTop(){
          let that = this;
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          that.scrollTop = scrollTop;
          // console.log(that.scrollTop);
          if(that.scrollTop > 1000){
            that.arrowShow = true;
          }else {
            that.arrowShow = false;
          }
        }
    },
    destroyed(){
        // 移除监听事件
        window.removeEventListener("scroll", this.scrollBackTop)
    }
}
</script>
<style>
  .arrow {
    width: .8rem;
    /* width: .6rem; */
    height: .8rem;
    /* background-color: #999; */
    background-color: #ccc;
    border-radius: .04rem;
    position: fixed;
    bottom: 19%;
    right: 4%;
    z-index: 1000;
  }
  .arrow  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
</style>