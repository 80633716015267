<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail-sign">
        <img :src="list.detailPage" class="img" />
      </div>
    </div>

    <div class="page-matchReview-detail">
      <div class="mactchInfo">
        <div class="title">{{ $t("reviewDetail.introduce") }}</div>
        <div class="flex f-ac f-jc wrap">
          <div class="img">
            <!-- <img src="../assets/img/d1.png" class="img" /> -->
            <img :src="list.introducePicture" class="img" />
          </div>
          <div class="text flex-item">
            {{ list.eventIntroduction }}
          </div>
        </div>
      </div>
      <div class="winners">
        <div class="title">{{ $t("reviewDetail.win_list") }}</div>
        <div class="main wrap">
          <img :src="list.winner" alt="" class="img" />
          <!-- <img src="../assets/img/d2.png" alt="" class="img" /> -->
          <!-- <div class="tit">
            {{ list.winner }}
          </div> -->
        </div>
      </div>
      <div class="videoList">
        <div class="wrap">
          <div class="title">
            {{ $t("reviewDetail.video_works") }}
            <a class="more" @click="matchVideosBtn()">
              {{ $t("reviewDetail.more_works") }}</a
            >
          </div>
          <div class="video videoStyle01">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            />
          </div>
        </div>
      </div>
      <!-- 改动处 开始（hsj:2021.11.13） -->
      <div
        class="videoList2 clearfix"
        v-if="list.videoList && list.videoList.length > 1"
      >
        <a class="item" @click="matchVideosBtn()">
          <div class="img" v-if="list.videoList.length > 1">
            <img :src="list.videoList[1].cover" class="begin img" />
            <div class="tit flex f-ac f-jc begin">
              {{ list.videoList[1].title }}
            </div>
          </div>
        </a>
        <a class="item" @click="matchVideosBtn()">
          <div class="img" v-if="list.videoList.length > 2">
            <img :src="list.videoList[2].cover" class="begin img" />
            <div class="tit flex f-ac f-jc begin">
              {{ list.videoList[2].title }}
            </div>
          </div>
        </a>
        <a class="item" @click="matchVideosBtn()">
          <div class="img" v-if="list.videoList.length > 3">
            <img :src="list.videoList[3].cover" class="begin img" />
            <div class="tit flex f-ac f-jc begin">
              {{ list.videoList[3].title }}
            </div>
          </div>
        </a>
      </div>

      <div class="matchPhoto wrap">
        <div class="title">
          {{ $t("reviewDetail.highlights") }}
          <a class="more" @click="matchPhotosBtn()">{{
            $t("reviewDetail.exciting")
          }}</a>
        </div>
        <!-- 轮播图 -->
        <div class="carouselPo">
          <el-carousel :interval="3000" type="card" indicator-position="none">
            <el-carousel-item v-for="(item, index) in this.imgs" :key="index">
              <img :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <backTop />
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import backTop from "../components/back-top.vue";
import { getHistory } from "../api/matchReview";
export default {
  data() {
    return {
      list: "",
      imgs: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            // src: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4", // url地址
            src: "",
          },
        ],
        poster: "",
        // "https://p1.music.126.net/5zs7IvmLv7KahY3BFzUmrg==/109951163635241613.jpg?param=600y500", // 你的封面地址(目前还是静态地址)
        // "https://matatalab.oss-cn-shenzhen.aliyuncs.com/service/png/15989361274/2021-09-17/bYkyZs_1631875956228.mp4?x-oss-process=video/snapshot,t_10000,m_fast",
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
        id: "",
      },
    };
  },
  components: {
    navigation,
    footers,
    backTop,
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        id: this.$route.query.id,
      };
      if (this.$route.query.id) {
        data.id = this.$route.query.id;
      } else {
        data.id = localStorage.getItem("matchReviewDetailId");
      }
      this.id = data.id;
      getHistory(data).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.imgs = res.data.eventPhotos;
          this.playerOptions.sources[0].src = this.list.videoList[0].video; //视频地址
          // OSS 生成视频封面图片
          this.playerOptions.poster = this.list.videoList[0].cover;
          // this.playerOptions.poster =
          //   this.list.videoList[0].video +
          //   "?x-oss-process=video/snapshot,t_10000,m_fast"; //封面地址
        }
      });
    },
    matchVideosBtn() {
      let that = this;
      this.$router.push({ name: "matchVideos", query: { id: that.id } });
    },
    matchPhotosBtn() {
      let that = this;
      // console.log(that.id);
      this.$router.push({
        name: "matchPhotos",
        query: {
          id: that.id,
        },
      });
    },
  },
};
</script>

<style>
/* 轮播图样式 */
/* 轮播图样式 */
.banner-matchReview2 {
  height: 6.73rem;
  position: relative;
  margin-top: 0.6rem;
}
.banner-matchReview2 .el-carousel,
.banner-matchReview2 .el-carousel__container {
  height: 100%;
}
.banner-matchReview2 .el-carousel__item img {
  width: 100%;
  height: 100%;
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel__button {
  width: 0.4rem !important;
  height: 0.02rem !important;
}

/* 视频样式 */
.videoStyle01 .video-js .vjs-volume-panel .vjs-volume-control {
  opacity: 1 !important;
}
.videoStyle01 .vjs-current-time {
  margin-left: 0.6rem !important;
}
/* 箭头点击样式 */
.page-matchReview-detail .matchPhoto {
  position: relative;
}
.page-matchReview-detail .matchPhoto .carouselPo .el-carousel--horizontal {
  height: 5rem;
}
.page-matchReview-detail
  .matchPhoto
  .carouselPo
  .el-carousel--horizontal
  .el-carousel__container {
  height: 4rem;
}
.page-matchReview-detail .matchPhoto .el-carousel__arrow--left,
.page-matchReview-detail .matchPhoto .el-carousel__arrow--right {
  position: absolute !important;
  top: 110% !important;
  left: 43%;
  display: block !important;
  z-index: 20 !important;
  background: url("../assets/img/icon-more4.png") no-repeat center;
}
.page-matchReview-detail .matchPhoto .el-carousel__arrow--right {
  background: url("../assets/img/icon-more-h.png") no-repeat center;
  left: 54%;
}
.page-matchReview-detail .matchPhoto .el-carousel__arrow--left i,
.page-matchReview-detail .matchPhoto .el-carousel__arrow--right i {
  display: none !important;
}
/* 轮播图改为圆角 */
.matchPhoto .carouselPo .el-carousel__container .el-carousel__item {
  border-radius: 0.1rem;
}
</style>
