<template>
  <div>
    <navigation />

    <div class="h77"></div>
    <div class="banner">
      <div class="banner-detail">
        <!-- <img src="../assets/img/sign.jpg" class="img" /> -->
        <img :src="bannerImg" class="img" />
      </div>
    </div>
    <div class="page-sign">
      <div class="main">
        <div class="wrap">
          <div class="sign-tab flex begin">
            <a
              class="flex-item"
              :class="{ active: onShow1 }"
              @click="getList(1)"
              >{{ $t("matchsign.signing_up") }}</a
            >
            <a
              class="flex-item"
              :class="{ active: onShow2 }"
              @click="getList(2)"
              >{{ $t("matchsign.ingame") }}</a
            >
            <a
              class="flex-item"
              :class="{ active: onShow3 }"
              @click="getList(3)"
              >{{ $t("matchsign.ended") }}</a
            >
            <a
              class="flex-item"
              :class="{ active: onShow4 }"
              @click="getList(4)"
              >{{ $t("matchsign.gameover") }}</a
            >
          </div>
          <div class="sign-list clearfix">
            <a
              v-for="(item, index) in list"
              :key="index"
              class="item cursor01"
              @click="details(item.id)"
            >
              <div class="img">
                <img :src="item.smallPicture" class="img begin" />
                <div class="span" v-if="item.competitionType == 1">
                  {{ $t("matchsign.online") }}
                </div>
                <div class="span" v-else-if="item.competitionType == 2">
                  {{ $t("matchsign.offline") }}
                </div>
              </div>
              <div class="info">
                <div class="tit ellipsis">{{ item.title }}</div>
                <div class="time">
                  {{ $t("matchsign.reg_time") }}{{ item.applyStartime }}~{{
                    item.applyEndtime
                  }}
                </div>
                <div class="time">
                  {{ $t("matchsign.game_time") }}{{ item.gameStartime }}~{{
                    item.gameEndtime
                  }}
                </div>
              </div>
            </a>
          </div>
        </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- <backTop /> -->
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import backTop from "../components/back-top.vue";
import { all, getBanner } from "../api/matchSign";
export default {
  data() {
    return {
      list: [],
      onShow1: true,
      onShow2: false,
      onShow3: false,
      onShow4: false,
      currentPage: 1, //当前页
      pageSize: 9, //多少页
      total: 10, //多少条
      signUpType: null,
      bannerImg: null,
    };
  },
  components: {
    navigation,
    footers,
    backTop,
  },
  created() {
    if (this.$route.params.type) {
      localStorage.setItem("signUpType", this.$route.params.type);
    } else {
      localStorage.setItem("signUpType", 1); //默认为报名中
    }
    this.signUpType = localStorage.getItem("signUpType");
    this.getList(this.signUpType);
    this.getBannerImg();
  },
  methods: {
    // banner图
    getBannerImg() {
      let data = { type: 1 };
      getBanner(data).then((res) => {
        if (res.code == 200) {
          this.bannerImg = res.data;
        }
      });
    },
    getList(type) {
      // tab栏切换样式
      if (type == 1) {
        this.onShow1 = true;
        this.onShow2 = false;
        this.onShow3 = false;
        this.onShow4 = false;
        localStorage.setItem("signUpType", 1);
      } else if (type == 2) {
        this.onShow1 = false;
        this.onShow2 = true;
        this.onShow3 = false;
        this.onShow4 = false;
        localStorage.setItem("signUpType", 2);
      } else if (type == 3) {
        this.onShow1 = false;
        this.onShow2 = false;
        this.onShow3 = true;
        this.onShow4 = false;
        localStorage.setItem("signUpType", 3);
      } else if (type == 4) {
        this.onShow1 = false;
        this.onShow2 = false;
        this.onShow3 = false;
        this.onShow4 = true;
        localStorage.setItem("signUpType", 4);
      }
      if (type == undefined) type = 1;
      let data = {
        type: type,
        page: this.currentPage,
        size: this.pageSize,
      };
      all(data).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.total = res.total;
        } else {
        }
      });
    },
    details(e) {
      // 将id存储到localStorage,解决子页面刷新时数据丢失问题
      localStorage.setItem("getCompetitionId", e);
      this.$router.push({
        name: "matchSignDetail",
        params: {
          id: e,
        },
        query: {
          id: e,
        },
      });
    },
    // 分页事件
    // 每页多少条
    handleSizeChange(val) {
      this.pageSize = val;
      this.signUpType = localStorage.getItem("signUpType");
      this.getList(this.signUpType);
    },
    // 当前页
    handleCurrentChange(val) {
      // 页面置顶
      document.documentElement.scrollTop = 0; //跳回顶部
      this.currentPage = val;
      this.signUpType = localStorage.getItem("signUpType");
      this.getList(this.signUpType);
    },
  },
};
</script>

<style>
.page-sign .item:hover {
  cursor: pointer;
}
.cursor01:hover {
  cursor: pointer !important;
}
/* 分页背景色 */
.el-pagination .active {
  background-color: #fd6c0b !important;
}
</style>
