import request from '../utils/request';
let Lang = localStorage.getItem('lang');
// 注册
export const register = data => {
    return request({
        url: 'v1/user/register',//接口地址
        method: 'post',
        data: data
    });
};
// 获取验证码
export const code = data => {
    return request({
        url: 'v1/user/sms/code',//接口地址
        method: 'post',
        data: data
    });
};
// 登录
export const login = data => {
    return request({
        url: 'v1/user/login',//接口地址
        method: 'post',
        data: data
    });
};
// 忘记密码
export const forgotPwd = data => {
    return request({
        url: 'v1/user/forget',//接口地址
        method: 'post',
        data: data
    });
};
export const loginByEmail = data => {
    return request({
        url: 'v1/user/loginByEmail',//接口地址
        method: 'post',
        data: data
    });
};
// 邮箱注册
export const registerByEmail = data => {
    return request({
        url: 'v1/user/registerByEmail',//接口地址
        method: 'post',
        data: data
    });
};
// 邮箱获取验证码
export const emailCode = data => {
    return request({
        url: '/v1/user/email/code',//接口地址
        method: 'post',
        data: data
    });
};