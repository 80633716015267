<template>
  <div>
    <navigation />
    <div class="h77"></div>
    <div class="banner banner-matchReview">
      <!-- 轮播图 -->
      <el-carousel>
        <el-carousel-item v-for="(ite, idx) in historyHeardDTOS" :key="idx">
          <img :src="ite.cover" alt="" />
        </el-carousel-item>
      </el-carousel>

      <div class="index-banner" v-if="false">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="../assets/img/match-review.jpg" />
              <div class="cont">
                <div class="tit">{{ $t("review2020.review") }}</div>
                <div class="stit">match review</div>
                <a class="btn" @click="go()">{{ $t("review2020.more") }}</a>
              </div>
            </div>
            <div class="swiper-slide">
              <img src="../assets/img/match-review.jpg" />
              <div class="cont">
                <div class="tit">{{ $t("review2020.review") }}</div>
                <div class="stit">match review</div>
                <a class="btn">{{ $t("review2020.more") }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination"></div>
      </div>
    </div>
    <div class="page-matchReview">
      <div class="wrap">
        <div class="flex f-ac top begin">
          <div class="dots"></div>
          <div class="flex-item line"></div>
          <a class="item" :class="{ active: flag104 }" @click="getList01(2023, 1, 104)">2023</a>
          <div class="flex-item line"></div>
          <a class="item" :class="{ active: flag103 }" @click="getList01(2022, 1, 103)">2022</a>
          <div class="flex-item line"></div>
          <a class="item" :class="{ active: flag101 }" @click="getList01(2021, 1, 101)">2021</a>
          <div class="flex-item line"></div>
          <a class="item" :class="{ active: flag102 }" @click="getList01(2020, 1, 102)">2020</a>
          <div class="flex-item line"></div>
          <div class="dots"></div>
        </div>
        <div class="bottom flex f-ac f-jc">
          <a class="item" :class="{ active: flag201 }" @click="getList(obj.year, 1, 201)">{{ $t("review2020.online")
            }}</a>
          <a class="item" :class="{ active: flag202 }" @click="getList(obj.year, 2, 202)">{{ $t("review2020.offline")
            }}</a>
        </div>
      </div>
    </div>
    <div class="page-matchReview-list">
      <div class="wrap clearfix">
        <a class="item" v-for="(item, index) in list" :key="index" @click="getHistory(item.id)">
          <div class="img">
            <img :src="item.listPage" alt="" class="img begin" />
          </div>
          <div class="tit review-title">{{ item.title }}</div>
        </a>
      </div>
    </div>

    <backTop />
    <footers />
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import "../assets/js/action";
import navigation from "../components/navigation.vue";
import footers from "../components/footers.vue";
import backTop from "../components/back-top.vue";
import { history } from "../api/matchReview";
export default {
  data() {
    return {
      obj: {
        type: 1,
        year: this.$route.query.year,
      },
      list: [],
      flag101: false,
      flag102: false,
      flag103: false,
      flag104: true,
      flag201: true,
      flag202: false,
      historyHeardDTOS: [], //轮播图数据
    };
  },
  components: {
    navigation,
    footers,
    backTop,
  },
  created() {
    console.log('$route.params.year', this.$route.params.year);
    this.getList(2023, 1);
    this.getList01(2023, 1);
  },
  beforeRouteUpdate(to, from, next) {
    console.log('to', to);
    const year = parseInt(to.query.year);
    if (year === 2023) {
      this.flag104 = true;
      this.flag103 = false;
      this.flag101 = false;
      this.flag102 = false;
    } else if (year === 2022) {
      this.flag104 = false;
      this.flag103 = true;
      this.flag101 = false;
      this.flag102 = false;
    } else if (year === 2021) {
      this.flag104 = false;
      this.flag103 = false;
      this.flag101 = true;
      this.flag102 = false;
    } else if (year === 2020) {
      this.flag104 = false;
      this.flag103 = false;
      this.flag101 = false;
      this.flag102 = true;
    }
    this.getList(year, 1);
    this.getList01(year, 1);
    next()
  },
  methods: {
    // 为了让年份和线上线下的点击事件不冲突，拆分一下
    getList01(year, type, num) {
      // tab栏切换样式
      if (num == 101) {
        this.flag101 = true;
        this.flag102 = false;
        this.flag103 = false;
        this.flag104 = false;
      } else if (num == 102) {
        this.flag101 = false;
        this.flag102 = true;
        this.flag103 = false;
        this.flag104 = false;
      } else if (num === 103) {
        this.flag101 = false;
        this.flag102 = false;
        this.flag103 = true;
        this.flag104 = false;
      } else if (num === 104) {
        this.flag101 = false;
        this.flag102 = false;
        this.flag103 = false;
        this.flag104 = true;
      }
      this.flag201 = true;
      this.flag202 = false;
      this.obj.year = year;
      this.obj.type = type;
      history(this.obj).then((res) => {
        if (res.code == 200) {
          this.list = res.data.historyDTOS;
          this.historyHeardDTOS = res.data.historyHeardDTOS;
        } else {
        }
      });
    },
    getList(year, type, num) {
      console.log(year, type, num);
      // tab栏切换样式
      if (num == 101) {
        this.flag101 = true;
        this.flag102 = false;
        this.flag103 = false;
      } else if (num == 102) {
        this.flag101 = false;
        this.flag102 = true;
        this.flag103 = false;
      } else if (num == 103) {
        this.flag101 = false;
        this.flag102 = false;
        this.flag103 = true;
      }
      if (num == 201) {
        this.flag201 = true;
        this.flag202 = false;
      } else if (num == 202) {
        this.flag201 = false;
        this.flag202 = true;
      }
      this.obj.year = year;
      this.obj.type = type;
      history(this.obj).then((res) => {
        console.log('wanwan', res)
        if (res.code == 200) {
          this.list = res.data.historyDTOS;
          this.historyHeardDTOS = res.data.historyHeardDTOS;
        } else {
        }
      });
    },
    getHistory(id) {
      console.log(id)
      localStorage.setItem("matchReviewDetailId", id);
      this.$router.push({
        name: "matchReviewDetail",
        query: {
          id: id,
        },
      });
    },
    go(url) {
      // this.$router.push("/matchReview")
    },
  },
};
</script>

<style>
/* 光标样式 小手 */
.page-matchReview .cursorPointer {
  cursor: pointer !important;
}

.page-matchReview-list .cursorPointer {
  cursor: pointer !important;
}

/* 轮播图样式 */
.banner-matchReview {
  height: 5.47rem;
  position: relative;
  margin-top: 0.6rem;
}

.banner-matchReview .el-carousel,
.banner-matchReview .el-carousel__container {
  height: 100%;
}

.banner-matchReview .el-carousel__item img {
  width: 100%;
  height: 100%;
}

.banner-matchReview .cont {
  width: 14rem;
  position: absolute;
  left: 50%;
  line-height: 1;
  margin-left: -7rem;
  top: 0;
}

.banner-matchReview .cont .tit {
  font-size: 0.6rem;
  margin-top: 1.88rem;
  color: rgba(255, 105, 0, 1);
}

.banner-matchReview .cont .stit {
  font-size: 0.3rem;
  margin-top: 0.22rem;
  color: rgba(255, 105, 0, 1);
}

.banner-matchReview .cont .btn {
  display: block;
  margin-top: 0.5rem;
  color: #fff;
  background: rgba(255, 105, 0, 1);
  border-radius: 0.44rem;
  line-height: 0.44rem;
  width: 1.34rem;
  text-align: center;
  font-size: 0.18rem;
}

.review-title {
  height: 1rem;
  overflow: hidden;
  /* 隐藏超出部分 */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
