<template>
    <div>
      <!-- <navigation /> -->
      <div class="h77"></div>
      <div class="page-sign-detail" v-if="list">
        <!-- 轮播图 -->
        <div class="banner">
          <div class="banner-detail-sign">
            <div class="detail-info flex f-ac f-jc">
              <!-- 轮播图 -->
              <div class="block">
                <el-carousel>
                  <el-carousel-item
                    v-for="(ite, idx) in list.bigPicture"
                    :key="idx"
                  >
                    <img :src="ite" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
  
              <div class="wrap" v-if="false">
                <div class="tit tit2">
                  {{ list.title }}
                </div>
              </div>
            </div>
  
            <img :src="list.bigPicture" class="img" />
          </div>
        </div>
        <div class="signInfo">
          <div class="l"></div>
          <div class="r"><img src="../assets/img/img4.png" class="img" /></div>
          <div class="wrap flex f-ac f-jb">
            <div class="left">
              <div class="tit" style="cursor: default">
                {{ $t("matchSigndetail.introduce") }}
              </div>
              <div class="txt" v-html="list.introduce">
                <!-- {{ list.introduce }} -->
              </div>
            </div>
  
            <div class="right">
              <!-- <img src="../assets/img/img5.png" class="img" /> -->
              <img :src="list.introducePicture" class="img" />
            </div>
          </div>
        </div>
  
        <div class="signVideo">
          <div class="banner">
            <div class="banner-detail">
              <img src="../assets/img/img6.png" class="img" />
            </div>
          </div>
          <!-- 视频 -->
          <div class="video videoStyle">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            />
          </div>
        </div>
        <div class="matchInfo flex f-jb wrap">
          <div class="item">
            <img src="../assets/img/img9.png" />
            <div class="tit" style="cursor: default">
              {{ $t("matchSigndetail.gametime") }}
            </div>
            <div class="txt">
              {{ $t("matchSigndetail.reg_time") }}{{ list.applyStartime }}~{{
                list.applyEndtime
              }}
            </div>
            <div class="txt">
              {{ $t("matchSigndetail.gametime1") }}{{ list.gameStartime }}~{{
                list.gameEndtime
              }}
            </div>
          </div>
          <div class="item">
            <img src="../assets/img/img8.png" />
            <div class="tit" style="cursor: default">
              {{ $t("matchSigndetail.competi_place") }}
            </div>
            <div class="txt" v-if="list.competitionType == 1">
              {{ $t("matchSigndetail.online") }}
            </div>
            <div class="txt" v-if="list.competitionType == 2">
              {{ $t("matchSigndetail.offline") }}
            </div>
            <div class="txt">{{ list.address }}</div>
          </div>
          <div class="item">
            <img src="../assets/img/img10.png" />
            <div class="tit" style="cursor: default">
              {{ $t("matchSigndetail.fee") }}
            </div>
            <div class="txt">
              {{ list.money }}{{ $t("matchSigndetail.price") }}
            </div>
          </div>
        </div>
  
        <div class="matchAwards">
          <div class="wrap">
            <div class="title">{{ $t("matchSigndetail.award") }}</div>
            <div class="desc line-feed">
              {{ list.awards }}
            </div>
            <div class="info flex f-jb f-jbs">
              <div
                class="item item-margin-right"
                v-for="(item, idx) in getAwardData"
                :key="idx"
              >
                <div class="tit flex f-ac f-jc" style="cursor: default">
                  <img src="../assets/img/img14.png" class="icon" />
                  {{ item.awardName }}
                  <span
                    v-if="item.proportionAwards && item.proportionAwards != null"
                    >({{ item.proportionAwards }})</span
                  >
                </div>
                <div class="txt" v-html="item.awardSub"></div>
              </div>
              <!-- <div class="item">
                <div class="tit flex f-ac f-jc" style="cursor:default">
                  <img src="../assets/img/img11.png" class="icon" />
                  玛塔智慧之星(3%)
                </div>
                <div class="txt">奖杯、奖牌、荣誉证书、赛事纪念品</div>
              </div>
              <div class="item">
                <div class="tit flex f-ac f-jc" style="cursor:default">
                  <img src="../assets/img/img12.png" class="icon" />
                  玛塔闪耀之星(3%)
                </div>
                <div class="txt">奖杯、奖牌、荣誉证书、赛事纪念品</div>
              </div>
              <div class="item">
                <div class="tit flex f-ac f-jc" style="cursor:default">
                  <img src="../assets/img/img13.png" class="icon" />
                  玛塔未来之星(85%)
                </div>
                <div class="txt">奖杯、奖牌、荣誉证书、赛事纪念品</div>
              </div>
              <div class="item">
                <div class="tit flex f-ac f-jc" style="cursor:default">
                  <img src="../assets/img/img14.png" class="icon" />
                  玛塔勇气之星(7%)
                </div>
                <div class="txt">奖杯、奖牌、荣誉证书、赛事纪念品</div>
              </div> -->
            </div>
          </div>
        </div>
  
        <div class="matchJudges">
          <div class="wrap">
            <div class="title">比赛赛项</div>
           <div style="display: flex;justify-content: space-around;align-items: center;margin-bottom: 78.75px;">
            <img style="width:400px" mode="aspectFit" src="../assets/img/logo_duobaoqibing.png" class="img" />
            <img style="width:400px;" mode="aspectFit" src="../assets/img/logo_yulintanxian.png" class="img" />
           </div>
            <!-- <div class="judgesInfo clearfix">
               <div style="display: flex;justify-content:space-around;align-items: center;">

                <img width="200" height="200" src="../assets/img/logo_duobaoqibing.png" class="img" />
                <img width="200" height="200" src="../assets/img/logo_yulintanxian.png" class="img" />
               </div>
              <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide
                  v-for="(item, index) in judgesList"
                  :key="index"
                  :data-swiper-slide-index="index"
                >
                  <div class="swiper-slide">
                    <div class="img">
                      <img :src="item.img" alt="" class="img" />
                    </div>
                    <div class="tit">{{ item.name }}</div>
                    <div class="txt ellipsis-2" v-html="item.introduction">
                    
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="btn-prev" slot="button-prev"></div>
              <div class="btn-next" slot="button-next"></div>
            </div> -->
          </div>
        </div>
  
        <div class="matchDown">
          <div class="wrap">
            <div class="title">{{ $t("matchSigndetail.download") }}</div>
            <!-- <div class="txt">2021 Makex Spark线上创意赛规则手册V1.3</div> -->
            <div class="txt">{{ list.fileName }}</div>
            <a :href="file" class="btn-down">{{ $t("matchSigndetail.down") }}</a>
          </div>
        </div>
        <div class="matchSign" @click="apply(list.title)" v-if="signUpType == 1">
          <a v-show="matchSignNoFixed" class="btn-sign">{{
            $t("matchSigndetail.sign_up")
          }}</a>
          <a v-show="matchSignFixed" class="btn-sign btn-fixed">{{
            $t("matchSigndetail.sign_up")
          }}</a>
        </div>
        <!-- 将报名按钮固定在页面底部，当用户拖动到底部时，再取消固定 -->
        <!-- <div v-show="matchSignFixed" class="matchSign matchSign-fixed" @click="apply(list.title)" v-if="signUpType == 1">
          <a class="btn-sign">我要报名</a>
        </div> -->
      </div>
      <tipsError :errorText="errorText01" v-show="errorShow01"></tipsError>
      <backTop />
      <!-- <footers /> -->
    </div>
  </template>
  
  <script>
  import "../assets/css/index.css";
  import "../assets/css/common.css";
  import "../assets/js/action";
  import navigation from "../components/navigation.vue";
  import footers from "../components/footers.vue";
  import backTop from "../components/back-top.vue";
  import tipsError from "../components/tips-error.vue"; //失败提示弹框
  import { getCompetition, getJudge, getAward } from "../api/matchSign";
  import { getUserInfo, message } from "../api/account";
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
  
  export default {
    data() {
      return {
        errorText01: "",
        errorShow01: false,
        matchSignFixed: false,
        matchSignNoFixed: true,
        swiperOption: {
          // loop: true,
          slidesPerView: "auto",
          // 显示分页
          pagination: {
            el: ".swiper-pagination",
            clickable: true, //允许分页点击跳转
          },
          // 设置点击箭头
          navigation: {
            nextEl: ".btn-next",
            prevEl: ".btn-prev",
          },
        },
        judgesPrevBtn: false,
        judgesNextBtn: true,
        judgesIndex: 0,
        // judgesStartIndex:3,
        // 评委数据
        judgesList: [
          // {"img":require("../assets/img/d3.png"),"name":"高峰1","introduction":"清华大学未来实验室博士后<br/>北京大学计算机科学博士"}
          // ,{"img":require("../assets/img/d4.png"),"name":"高峰2","introduction":"清华大学未来实验室博士后<br />北京大学计算机科学博士"}
          // ,{"img":require("../assets/img/d5.png"),"name":"高峰3","introduction":"清华大学未来实验室博士后<br />北京大学计算机科学博士"}
          // ,{"img":require("../assets/img/d6.png"),"name":"高峰4","introduction":"清华大学未来实验室博士后<br />北京大学计算机科学博士"}
          // ,{"img":require("../assets/img/d6.png"),"name":"高峰5","introduction":"清华大学未来实验室博士后<br />北京大学计算机科学博士"}
        ],
        list: null,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
              // src: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4", // url地址
              // src: "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4"
              src: "",
            },
          ],
          poster: "",
          // "https://p1.music.126.net/5zs7IvmLv7KahY3BFzUmrg==/109951163635241613.jpg?param=600y500", // 你的封面地址(目前还是静态地址)
          // "https://matatalab.oss-cn-shenzhen.aliyuncs.com/service/png/15989361274/2021-09-17/bYkyZs_1631875956228.mp4?x-oss-process=video/snapshot,t_10000,m_fast",
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, // 全屏按钮
          },
        },
        file: null, //下载跳转的路径
        userInfo_type: null, //参赛类型
        signUpType: null, //是否可报名
        getAwardData: [], // 竞赛奖项
      };
    },
  
    components: {
      navigation,
      footers,
      backTop,
      swiper,
      swiperSlide,
      tipsError,
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper;
      },
    },
    mounted() {
      // current swiper instance
      // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
      // console.log("this is current swiper instance object", this.swiper);
      // this.swiper.slideTo(3, 1000, false);
  
      // 监听滚动条
      window.addEventListener("scroll", this.scrollBackBottom);
    },
    created() {
      this.getList();
      this.getJudges(); //赛事评委
      this.getAwards(); //竞赛奖项
      this.signUpType = 1; //是否可报名
      // // 监听滚动条滚动到底部
      // window.onscroll = function(){
      //   //变量scrollTop是滚动条滚动时，距离顶部的距离
      //   var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      //   //变量windowHeight是可视区的高度
      //   var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //   //变量scrollHeight是滚动条的总高度
      //   var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
      //   //滚动条到底部的条件
      //   if(scrollTop+windowHeight==scrollHeight){
      //    //写后台加载数据的函数
      //     console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
      //   }
      // }
    },
    methods: {
      // 获取赛事评委
      getJudges() {
        let data = {
          // id: this.$route.params.id,
        };
        if (this.$route.params.id) {
          data.id = this.$route.params.id;
        } else {
          data.id = localStorage.getItem("getCompetitionId");
        }
        getJudge(data).then((res) => {
          if (res.code == 200) {
            this.judgesList = [];
            res.data.forEach((val) => {
              this.judgesList.push({
                // img: val.heard_photo,
                img: val.heardPhoto,
                name: val.name,
                introduction: val.title,
              });
            });
          } else {
          }
        });
      },
      // 竞赛奖项
      getAwards() {
        let data = {
          // id: this.$route.params.id,
        };
        if (this.$route.params.id) {
          data.id = this.$route.params.id;
        } else {
          data.id = localStorage.getItem("getCompetitionId");
        }
        getAward(data).then((res) => {
          if (res.code == 200) {
            this.getAwardData = res.data;
          }
        });
      },
      judgesPrev() {
        this.judgesNextBtn = true;
        this.judgesIndex--;
        if (this.judgesIndex == 0) {
          this.judgesPrevBtn == false;
        }
      },
      judgesNext() {
        this.judgesPrevBtn = true;
        this.judgesIndex++;
        if (this.judgesIndex + 4 == this.judgesList.length) {
          this.judgesNextBtn = false;
        }
      },
      // 获取页面详情
      getList() {
        let data = {
          // id: this.$route.params.id,
          id:33
        };

        // if (this.$route.params.id) {
        //   data.id = this.$route.params.id;
        // } else {
        //   data.id = localStorage.getItem("getCompetitionId");
        // }
        data.id = 33;
        getCompetition(data).then((res) => {
          if (res.code == 200) {
            this.list = res.data;
            // 存储报名页面需要用到的参数（解决刷新拿不到数据问题）
            this.list.bigPicture = this.list.bigPicture.split(",");
            this.file = this.list.file; //下载跳转的路径
            this.playerOptions.sources[0].src = this.list.introduceVideo; //视频地址
            // OSS 生成视频封面图片
            this.playerOptions.poster = this.list.videoCover;
            // this.playerOptions.poster = "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic3.16pic.com%2F00%2F34%2F99%2F16pic_3499409_b.jpg&refer=http%3A%2F%2Fpic3.16pic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1632538892&t=2eaaa39d55093204080bdf0870d78218"
            // this.playerOptions.poster =
            //   this.list.introduceVideo +
            //   "?x-oss-process=video/snapshot,t_10000,m_fast"; //封面地址
          } else {
            // this.$router.push("/");
          }
        });
      },
      apply(e) {
        //不是登陆状态就跳回登录页面
        if (
          localStorage.getItem("token") == null ||
          !localStorage.getItem("token")
        ) {
          this.$router.push("/login");
          localStorage.setItem("jinjiangcitySignDetail", "jinjiangcitySignDetail");
          // this.$router.push({
          //   path: '/login',
          // })
          return;
        }
        // 根据用户信息，判断是否符合报名条件 "0,家庭用户 1,机构用户 2,幼儿园 3,小学",只有0和1可以报名
        getUserInfo().then((res) => {
          this.userInfo_type = res.data.type;
          let signData = {
            userInfo_type: this.userInfo_type, //参赛类型
            title: this.list.title, //赛事名称
            id: this.list.id, //id
            maxnum: this.list.maxnum, // 存储num限定成员数量
            minnum: this.list.minnum,
            age_startime: this.list.ageStartime, //// 年龄限制起始时间
            age_endtime: this.list.ageEndtime, // 年龄限制结束时间
          };
          localStorage.setItem("signData", JSON.stringify(signData));
  
          let flag = true;
          // if (res.data.type == 2 || res.data.type == 3) {
          //   this.errorText01 = "此比赛只有家庭用户、机构用户类型的用户才可报名";
          //   this.errorShow01 = true;
          //   setTimeout(() => {
          //     this.errorShow01 = false;
          //   }, 2000);
          //   return;
          // } else
          if (res.data.type != 0) {
            // 非家庭用户，需要全部的用户信息都完整
            // 遍历对象
            delete res.data.avatar; //删除用户头像字段,目前不需要
            for (var key in res.data) {
              if (res.data[key] == null) {
                flag = false;
                this.$alert("完善用户信息后，方可报名。", "提示", {
                  confirmButtonText: "确定",
                  type: "warning",
                  callback: (action) => {
                    this.$router.push("/account");
                  },
                });
                return flag;
              } else {
                flag = true;
              }
            }
            if (flag == true) {
              //获取url的query id参数
                this.$router.push({
                  path: "/signSingle",
                });
                return;
              if (this.list.competitionType == 1) {
                //线上
                this.$router.push({
                  name: "sign",
                  params: {
                    title: e,
                    userInfoType: this.userInfo_type, //传值0家庭1机构...
                    list: this.list,
                  },
                });
              } else if (this.list.competitionType == 2) {
                //线下
                this.$router.push({
                  name: "signOffline",
                  params: {
                    title: e,
                  },
                });
              }
            }
          } else if (res.data.type == 0) {
            if (this.list.competitionType == 1) {
              //线上
              this.$router.push({
                name: "sign",
                params: {
                  title: e,
                  userInfoType: this.userInfo_type,
                  list: this.list,
                },
              });
            } else if (this.list.competitionType == 2) {
              //线下
              this.$router.push({
                name: "signSingle",
                params: {
                  title: e,
                },
              });
            }
          }
        });
      },
  
      // 为了计算距离顶部的高度，当高度大于XX显示回到顶部的图标，小于则隐藏
      scrollBackBottom() {
        let that = this;
        var a;
        // 监听滚动条滚动到底部
        window.onscroll = function () {
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          var scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          var windowHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          var scrollHeight =
            document.documentElement.scrollHeight || document.body.scrollHeight;
          //滚动条到底部的条件
          // if(scrollTop+windowHeight==scrollHeight){
          //  //写后台加载数据的函数
          //   console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
          // }else {
          //   console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
          // }
          // 当滚动条满足条件时，显示或隐藏
          // if(scrollTop + windowHeight >= (scrollHeight-200)){
          //   that.matchSignFixed = false;
          //   that.matchSignNoFixed = true;
          // }else {
          //   that.matchSignFixed = true;
          //   that.matchSignNoFixed = false;
          // }
        };
      },
    },
    destroyed() {
      // 移除监听事件
      window.removeEventListener("scroll", this.scrollBackBottom);
    },
  };
  </script>
  
  <style>
  .block {
    width: 100%;
    height: 100%;
  }
  /* 视频样式 */
  .videoStyle .video-player {
    height: 100% !important;
  }
  .videoStyle .video-player > div:first-child {
    height: 100% !important;
    padding-top: 0 !important;
  }
  .videoStyle .video-js .vjs-volume-panel .vjs-volume-control {
    opacity: 1 !important;
  }
  .videoStyle .vjs-current-time {
    margin-left: 0.6rem !important;
  }
  /*  */
  .banner-detail {
    margin-top: 0.6rem;
  }
  /* 轮播图样式 */
  .page-sign-detail .el-carousel--horizontal,
  .page-sign-detail .el-carousel__container {
    width: 100%;
    height: 100% !important;
    /* height: 5.45rem !important; */
  }
  .page-sign-detail .el-carousel__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .page-sign-detail .el-carousel__button {
    width: 10px !important;
    height: 10px !important;
    margin-bottom: 20px !important;
  }
  .page-sign-detail .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
  .page-sign-detail .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .page-sign-detail .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  /* 赛事评委样式 */
  .matchJudges .judgesInfo div.img {
    height: 2.7rem;
  }
  .matchJudges .judgesInfo img.img {
    border-radius: 100%;
  }
  /* 报名按钮固定 */
  .btn-fixed {
    position: fixed;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }
  /*  */
  .item-margin-right {
    margin-right: 30px;
  }
  .item-margin-right:last-child {
    margin-right: 0;
  }
  .matchAwards .wrap .f-jbs {
    justify-content: center;
  }
  </style>
  