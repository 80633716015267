// zh
export default {
  signoffline: {
    welcome: "欢迎参加玛塔编程赛 ",
    initregistration: "提交报名",
    reviewapplication: "报名审核",
    approved: "审核通过",
    announcewinners: "评审结果",
    submitworks: "上传作品",
    event_name: "赛事名称",
    type: "参加类型",
    groupType: "参加组别",
    kindergartenType: "幼儿组",
    primaryType: "小学组",
    family: "家庭用户",
    institution: "机构用户",
    kindergarten: "幼儿园",
    primary: "小学",
    code: "输入参赛邀请码",
    ent_code: "请输入参赛邀请码",
    correct_code: "请输入正确的邀请码",
    entrycode: "请输入邀请码!",
    teamname: "队伍名称",
    ent_teamname: "请输入队伍名称!",
    teammembers: "队伍成员",
    name: "姓名",
    ent_name: "请输入姓名",
    sex: "性别",
    ent_sex: "请输入性别",
    id_number: "身份证号",
    ent_idnumber: "请输入身份证号码",
    ent_country: "请输入国家",
    country:'国家(地区)',
    corret_idnumber: "请输入正确的身份证号!",
    parent_name: "家长姓名",
    please_parent_name: "请输入家长姓名",
    parent_phone: "家长电话",
    please_parent_phone: "请输入家长电话",
    illege: "抱歉手机号不合法!",
    org: "机构名称",
    ent_org: "请输入机构名称",
    kindergarten_name: "幼儿园名称",
    please_kid_name: "请输入幼儿园名称",
    primary_school: "小学名称",
    please_primary_name: "请输入小学名称",
    instructor_parent: "指导老师",
    instructor_phone: "指导老师电话",
    ent_instr_name: "请输入老师姓名",
    ent_instru_phone: "请输入指导老师联系电话",
    prize_addr: "奖品邮寄地址",
    address: "请输入地址",
    ent_email: "请输入邮寄地址!",
    detailed_addr: "详细地址",
    ent_detail_addr: "请输入详细地址!",
    submit: "提交",
    information: "  请完善报名信息（注：所有信息都为必填）",
    notice: "您已报名成功，审核结果我们将会以短信的形式通知您",
    cancel: "取消",
    ent_org_name: "请输入机构名称!",
    ent_kid_name: "请输入幼儿园名称!",
    ent_pri_school: "请输入小学名称!",
    ent_contacnt_phone: "请输入联系电话!",
    ent_parent_name: "请输入家长姓名!",
    ent_structor_name: "请输入指导老师姓名!",
    ent_structor_phone: "请输入指导老师电话!",
    ent_parent_phone: "请输入家长电话!",
    enter_name: "请输入队伍名字",
    enter_you_name: "请输入成员名字",
    enter_you_sex: "请输入成员性别",
    enter_you_idnumber: "请输入身份证号",
    submit_success: "您已报名成功，审核结果我们将会以短信的形式通知您",
    // this.$t("siginfo.ent_org_name");
    // this.$t("siginfo.ent_kid_name");
    // this.$t("siginfo.ent_pri_school");
    // this.$t("siginfo.ent_contacnt_phone");
    // this.$t("siginfo.ent_parent_name");
    // this.$t("siginfo.ent_structor_name");
    // this.$t("siginfo.ent_name");
    // this.$t("siginfo.ent_parent_phone");
    // this.$t("siginfo.ent_structor_phone");
  },
};
