<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
import "./assets/css/swiper.min.css";
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    var _pageResponse = (window.onresize = function () {
      (function (doc, win) {
        var docEl = doc.documentElement,
          resizeEvt =
            "orientationchange" in window ? "orientationchange" : "resize",
          recalc = function () {
            var clientWidth = docEl.clientWidth;

            if (clientWidth < 1360) {
              docEl.style.fontSize = 100 * (1360 / 1920) + "px";
            } else {
              docEl.style.fontSize = 100 * (clientWidth / 1920) + "px";
            }
          };
        recalc();
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
      })(document, window);
    });
    _pageResponse();
  },
  watch: {
    $route: {
      handler: function (val) {},
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

