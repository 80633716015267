export default {
  faq: {
    question: "常见问题",
    registration: "竞赛相关",
    competite: "参赛相关",
    score: "成绩相关",
    tech: "技术相关",
    other: "其他",
  },
};
